import { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationFieldsFragment, GqlEvaluationScoreFieldsFragment, GqlPagination } from '@gql'
import { find, map } from 'lodash'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationScore } from '@cotiss/evaluation-event/hooks/use-evaluation-score.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export const EvaluationEventCriteriaScoreComparisonList = memo(({ evaluation, evaluationCriteriaItem }: Props) => {
  const { openToast } = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { queryEvaluationScoreList } = useEvaluationScore()
  const { evaluations, queryEvaluationList } = useEvaluation()
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const [evaluationScores, setEvaluationScores] = useState<GqlEvaluationScoreFieldsFragment[]>([])
  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  useAsyncEffect(async () => {
    try {
      const { evaluationSubmissions } = await queryEvaluationSubmissionList({
        filter: { evaluationEventId: evaluation.evaluationEventId },
        pagination: { page: currentPage, pageSize: 10 },
      })
      const { evaluations } = await queryEvaluationList({
        filter: {
          evaluationEventId: evaluation.evaluationEventId,
          evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          evaluationSubmissionIds: map(evaluationSubmissions, 'id'),
          evaluationUserId: evaluation.evaluationUserId,
        },
      })
      const { evaluationScores, pagination } = await queryEvaluationScoreList({
        filter: {
          evaluationEventId: evaluation.evaluationEventId,
          evaluationIds: map(evaluations, 'id'),
          evaluationCriteriaId: evaluationCriteriaItem.id,
        },
      })

      setEvaluationScores(evaluationScores)
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [currentPage])

  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Contact name',
        rows: map(evaluationSubmissions, ({ name }) => ({
          content: () => <Text className="truncate">{name}</Text>,
        })),
      },
      ...(isWeightedEnvelope
        ? [
            {
              heading: 'Score',
              thClassName: 'w-20',
              rows: map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
                const evaluationToCompare = find(evaluations, {
                  evaluationSubmissionId,
                  evaluationUserId: evaluation.evaluationUserId,
                  evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
                })
                const score =
                  evaluationToCompare &&
                  find(evaluationScores, { evaluationId: evaluationToCompare.id, evaluationCriteriaId: evaluationCriteriaItem.id })

                return {
                  content: () => <Text>{score?.value || '--'}</Text>,
                }
              }),
            },
          ]
        : []),
      {
        heading: 'Comment',
        rows: map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
          const evaluationToCompare = find(evaluations, {
            evaluationSubmissionId,
            evaluationUserId: evaluation.evaluationUserId,
            evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          })
          const score =
            evaluationToCompare && find(evaluationScores, { evaluationId: evaluationToCompare.id, evaluationCriteriaId: evaluationCriteriaItem.id })

          return {
            content: () => <Text>{score?.comment || '--'}</Text>,
          }
        }),
      },
    ]

    return columns
  }, [evaluationScores, evaluationSubmissions])

  const nodeLabel = evaluationCriteriaItem?.parentEvaluationCriteriaId ? 'sub-criteria' : 'criteria'

  return (
    <>
      <TableHeader>
        <Text className="font-semibold">{evaluationCriteriaItem.content}</Text>
        <Text variant="light" size="sm">
          Below is the commentary {isWeightedEnvelope ? 'and scores' : ''} you have given to this {nodeLabel} across all submissions.
        </Text>
      </TableHeader>
      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
