import { memo } from 'react'
import FiveHundredComputerSvg from '@assets/svg/500-computer.svg'
import classNames from 'classnames'
import { Badge } from '@cotiss/common/components/badge.component'
import { Section } from '@cotiss/common/components/section.component'
import { Text } from '@cotiss/common/components/text.component'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  className?: string
  errorCode?: number
  onRetry?: () => void
}

export const ErrorPanel = memo(({ className, errorCode, onRetry }: Props) => (
  <Section className={classNames(className, 'text-center')} isCentered>
    <FiveHundredComputerSvg className="mx-auto" />
    {errorCode ? (
      <Badge state="translucent" variant="secondary" shape="square">
        {errorCode} Error
      </Badge>
    ) : null}
    <Text className="font-semibold text-center mt-6" size="h2" variant="heading">
      Oops, something went wrong
    </Text>
    <Text className="mt-2" variant="light">
      We apologise and are fixing the problem, please try again later
    </Text>
    {onRetry ? (
      <Button className="mt-6" onClick={onRetry}>
        Try again
      </Button>
    ) : null}
  </Section>
))
