import { memo, useEffect, useMemo, useState } from 'react'
import { filter, map, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventSubCriteriaWeightList } from '@cotiss/evaluation-event/components/evaluation-event-sub-criteria-weight-list.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { EvaluationEventEnvelopeWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weighted-title'
import { EvaluationEventWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-weighted-title'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardSubWeightingStep = memo(({ onNext, onBack }: Props) => {
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent } = useEvaluationEvent()
  const [criteriaWeightById, setCriteriaWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationCriteria })
  )

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'

  const { weightedPercentageById, overallWeightedPercentageById } = useMemo(() => {
    return evaluationEventService.getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria })
  }, [evaluationEnvelopes, evaluationCriteria])

  useEffect(() => {
    track('evaluation_event_wizard_sub_weight_view')
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  const handleClickContinue = () => {
    onNext()
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Sub-weight
        </Text>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>
            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>

      {map(evaluationEnvelopes, (envelope) => {
        const parentCriteria = sortBy(filter(evaluationCriteria, { evaluationEnvelopeId: envelope.id, parentEvaluationCriteriaId: null }), 'index')
        const isWeightedEnvelope = envelope.weight !== 0

        return (
          <div className="mt-8 first:mt-0" key={envelope.id}>
            <div className="flex items-center justify-between">
              <Text size="lg" className="font-medium">
                <EvaluationEventEnvelopeWeightedTitle
                  relativeWeight={weightedPercentageById[envelope.id]}
                  order={envelope.order}
                  name={envelope.name}
                  shouldShowWeight={isWeightedMethodology}
                />
              </Text>
            </div>
            <Hr className="my-3" />
            <div className="flex flex-col gap-y-8 pt-3">
              {!parentCriteria.length ? (
                <div className="py-5 px-4 border">
                  <Text variant="light">This envelope does not have any assigned criteria. Panel members will evaluate the envelope as a whole.</Text>
                </div>
              ) : (
                map(parentCriteria, (parentCriteriaItem) => {
                  const evaluationSubCriteria = filter(evaluationCriteria, { parentEvaluationCriteriaId: parentCriteriaItem.id })

                  return (
                    <div key={parentCriteriaItem.id}>
                      <TableHeader>
                        <div className="p-1">
                          <Text size="sm" variant="light" className="mb-1">
                            Criteria {parentCriteriaItem.index}
                          </Text>
                          <Text size="md" className="font-medium">
                            <EvaluationEventWeightedTitle
                              relativeWeight={weightedPercentageById[parentCriteriaItem.id]}
                              title={parentCriteriaItem.content}
                              shouldShowWeight={isWeightedMethodology}
                            />
                          </Text>
                        </div>
                      </TableHeader>
                      {evaluationSubCriteria?.length ? (
                        <EvaluationEventSubCriteriaWeightList
                          evaluationEnvelope={envelope}
                          parentEvaluationCriteriaItem={parentCriteriaItem}
                          evaluationSubCriteria={evaluationSubCriteria}
                          criteriaWeightById={criteriaWeightById}
                          setCriteriaWeightById={setCriteriaWeightById}
                          weightedPercentageById={weightedPercentageById}
                          overallWeightedPercentageById={overallWeightedPercentageById}
                          isEditable
                        />
                      ) : (
                        <div className="py-5 px-4 border">
                          <Text variant="light">
                            {isWeightedEnvelope
                              ? 'This weighted criteria has no sub-criteria'
                              : 'Evaluators can provide commentary against this unweighted criteria.'}
                          </Text>
                        </div>
                      )}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        )
      })}
    </Card>
  )
})
