import { FormEvent, useState, memo, useEffect } from 'react'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { authService } from '@cotiss/auth/auth.service'
import { useMutateAuth } from '@cotiss/auth/resources/use-mutate-auth.resource'
import { useGetValidateResetPasswordToken } from '@cotiss/auth/resources/use-get-validate-reset-password-token.resource'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'

const EXPIRED_LINK_ERROR = 'Your password reset link has expired.'
const GENERIC_API_ERROR = 'Unable to process reset password request. Please contact support.'

export const ResetPasswordPage = memo(() => {
  const { logout } = useAuth()
  const { push } = useHistory()
  const { openToast } = useToast()
  const { resetPassword } = useMutateAuth()
  const [formError, setFormError] = useState<string | null>(null)
  const [apiError, setApiError] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const { token } = useParams<{ token: string }>()
  const [formData, setFormData] = useState({ newPassword: '', confirmNewPassword: '' })

  const { isValid, isLoading, isError: hasValidateTokenError } = useGetValidateResetPasswordToken({ token })

  const validateTokenError = hasValidateTokenError && GENERIC_API_ERROR

  useEffect(() => {
    logout()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError(null)
    setApiError(null)

    if (formData.newPassword !== formData.confirmNewPassword) {
      setFormError('Passwords do not match')
      return
    }

    if (!authService.isPasswordValid(formData.newPassword)) {
      setFormError('Password must be at least 8 characters long and contain at least one letter and one number')
      return
    }

    setIsSaving(true)

    try {
      await resetPassword({ token, newPassword: formData.newPassword })
      openToast('Password successfully reset.')
      push(routerService.getHref('/login'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      error?.message === 'Invalid token' ? setApiError(EXPIRED_LINK_ERROR) : setFormError(GENERIC_API_ERROR)
      setIsSaving(false)
    }
  }

  const loadingTemplate = isLoading && (
    <div className="flex items-center justify-center">
      <Spinner colour={COLOUR.primary[500]} className="my-4" />
    </div>
  )

  const resetPasswordTemplate = Boolean(isValid && !apiError) && (
    <>
      <Text className="mt-2" variant="light">
        Enter and confirm your new password.
      </Text>
      {formError && (
        <Text className="text-sm my-4" variant="danger">
          {formError}
        </Text>
      )}
      <Form className="mt-8" onSubmit={handleSubmit}>
        <Label>New password</Label>
        <Input
          className="mb-4"
          value={formData.newPassword}
          onChange={({ target }) => setFormData({ ...formData, newPassword: target.value })}
          type="password"
          placeholder="Enter new password"
          isDisabled={isSaving}
          isError={Boolean(formError)}
          isRequired
        />
        <Label>Confirm new password</Label>
        <div className="mb-4">
          <Input
            value={formData.confirmNewPassword}
            onChange={({ target }) => setFormData({ ...formData, confirmNewPassword: target.value })}
            type="password"
            placeholder="Confirm new password"
            isDisabled={isSaving}
            isError={Boolean(formError)}
            isRequired
          />
        </div>
        <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
          Update password
        </Button>
      </Form>
      <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
        <Text className="mr-1" isInline>
          Or go back to
        </Text>
        <Button className="text-sm" state="text" variant="link" href={routerService.getHref('/login')} isDisabled={isSaving} isLink>
          Log in
        </Button>
      </div>
    </>
  )

  const resendEmailTemplate = (
    <>
      <Text className="text-sm my-4" variant="danger">
        {validateTokenError || apiError || EXPIRED_LINK_ERROR}
      </Text>
      <Text className="text-sm mb-2">Click the button below to resend the email. Once sent, you will have 20 minutes to reset your password.</Text>
      <Button className="mt-2 w-full" href={routerService.getHref('/forgot-password')} isLink>
        Resend email
      </Button>
    </>
  )

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="bg-white w-1/2 h-full p-12">
          <div className="flex items-center">
            <CotissLogoDarkSvg className="h-3 mr-1" />
          </div>
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-[350px]">
              <Text className="font-semibold" size="h3">
                Update password
              </Text>
              {loadingTemplate || resetPasswordTemplate || resendEmailTemplate}
            </div>
          </div>
        </div>
        <div className="bg-white w-1/2 h-full p-4">
          <div className="flex flex-col items-start justify-between bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight">
              Better decisions.
              <br />
              Simplified process.
            </h1>
          </div>
        </div>
      </div>
    </Page>
  )
})
