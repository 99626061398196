import { memo, useEffect, useMemo, useState } from 'react'
import { map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { DocumentViewer } from '@cotiss/document/components/document-viewer.component'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { EvaluationEventEvaluationCommentDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-evaluation-comment.drawer'
import { EvaluationEventEvaluationStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-status-badge.component'

import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useEvaluationSubmissionDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-submission-document.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { EvaluationEventEvaluateDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-evaluate.drawer'

export const EvaluationEventEvaluateViewPage = memo(() => {
  const { replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const [documentIdToPreview, setDocumentIdToPreview] = useState('')
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { document: documentToPreview } = useGetDocument(documentIdToPreview)
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationEnvelope, queryEvaluationEnvelopeView } = useEvaluationEnvelope()
  const { evaluation, queryEvaluationView, queryEvaluationOverviewView } = useEvaluation()
  const { evaluationSubmission, queryEvaluationSubmissionView } = useEvaluationSubmission()
  const { evaluationEventId, evaluationId } = useParams<{ evaluationEventId: string; evaluationId: string }>()
  const { evaluationSubmissionDocuments, queryEvaluationSubmissionDocumentList } = useEvaluationSubmissionDocument()
  const { evaluationUser, evaluationUserInSession, queryEvaluationUserView, queryEvaluationUserInSessionView } = useEvaluationUser()
  const isEvaluationEnvelopeLevelCommentEnabled = useFeature('enable-evaluation-envelope-level-comment')

  const backHref = routerService.getHref(
    '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
    {
      evaluationEventId: evaluationEvent?.id || '',
      evaluationEnvelopeId: evaluationEnvelope?.id || '',
      evaluationUserId: evaluationUser?.id || '',
    }
  )

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: routerService.getHref('/evaluation-event/list/:tab?'),
    },
    {
      label: evaluationEvent?.name || '',
      href: routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId }),
      isLoading,
    },
    {
      label: evaluationEnvelope ? `Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}` : '',
      href: backHref,
      isLoading,
    },
    {
      label: evaluationSubmission?.name || '',
      isLoading,
    },
  ]

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  useAsyncEffect(async () => {
    try {
      const [evaluationEvent, evaluationUserInSession] = await Promise.all([
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
      ])

      if (evaluationEvent?.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        replace(backHref)
        return
      }

      const [evaluation] = await Promise.all([queryEvaluationView({ evaluationId }), queryEvaluationOverviewView({ evaluationId })])

      const [{ evaluationSubmissionDocuments }] = await Promise.all([
        queryEvaluationSubmissionDocumentList({
          filter: {
            evaluationEventId,
            evaluationSubmissionId: evaluation.evaluationSubmissionId,
            evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          },
        }),
        queryEvaluationEnvelopeView({ evaluationEnvelopeId: evaluation.evaluationEnvelopeId }),
        queryEvaluationUserView({ evaluationUserId: evaluation.evaluationUserId }),
        queryEvaluationSubmissionView({ evaluationSubmissionId: evaluation.evaluationSubmissionId }),
      ])
      setDocumentIdToPreview(evaluationSubmissionDocuments[0]?.documentId || '')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Document',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileName }) => ({
          content: () => (
            <Text title={fileName} className="truncate" variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>
              {fileName}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{ label: 'Preview', onClick: () => setDocumentIdToPreview(documentId) }}
              actions={[{ label: 'Download', onClick: () => setDocumentIdToDownload(documentId) }]}
            />
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileSize }) => ({
          content: () => <Text variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>{documentService.formatSize(fileSize)}</Text>,
        })),
      },
    ]

    return { columns }
  }, [evaluationSubmissionDocuments, documentIdToPreview])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationSubmission && (
                <div className="flex items-center truncate">
                  <Text className="font-medium" size="h7" variant="light">
                    Evaluate
                  </Text>
                  <Text className="font-medium truncate ml-1" size="h7" variant="heading">
                    {evaluationSubmission.name || evaluationSubmission.organisation?.name}
                  </Text>
                  {evaluation && <EvaluationEventEvaluationStatusBadge className="ml-2" status={evaluation.status} />}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center ml-4">
            {!isEvaluationEnvelopeLevelCommentEnabled &&
              evaluationUserInSession &&
              evaluation &&
              evaluationUserInSession.id === evaluation.evaluationUserId && (
                <Tooltip
                  tooltip="Notes cannot be edited once moderation has begun."
                  side="bottom"
                  isEnabled={evaluationEnvelope?.status !== 'evaluate'}
                >
                  <Button
                    className="mr-2"
                    onClick={() => openNarrowDrawer(<EvaluationEventEvaluationCommentDrawer evaluation={evaluation} />)}
                    size="sm"
                    variant="secondary"
                    state="translucent"
                    isDisabled={evaluationEnvelope?.status !== 'evaluate'}
                  >
                    Add note
                  </Button>
                </Tooltip>
              )}
            <Button onClick={() => openDrawer(<EvaluationEventEvaluateDrawer />)} size="sm" variant="secondary">
              {evaluationEnvelope?.status === 'evaluate' ? 'Evaluate submission' : 'View evaluation'}
            </Button>
          </div>
        </div>
      </Header>

      <div className="flex justify-between shrink-0 h-[calc(100%-84px)] w-full">
        <PageContent className="flex-1 h-full overflow-hidden">
          {documentToPreview && (
            <div className="bg-gray-100 rounded-lg p-6 max-w-[800px] w-full h-full">
              <DocumentViewer
                document={documentToPreview}
                isDownloadable
                onClickDownload={() => track('evaluation_event_evaluate_view_document_download_view')}
                onClickOpenInNewTab={() => track('evaluation_event_evaluate_view_document_new_tab_view')}
              />
            </div>
          )}
        </PageContent>
        <div className="shrink-0 bg-white overflow-y-scroll w-[400px] min-h-full">
          <Table columns={columns} isLoading={isLoading} />
        </div>
      </div>
    </Page>
  )
})
