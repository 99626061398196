import { useEffect, ReactNode } from 'react'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { AdvancedFiltersProvider, useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { pickBy, map } from 'lodash'
import { Filter } from '@cotiss/common/models/filter.model'
import {
  ContractSearchAndFiltersContextProvider,
  ContractSearchAndFiltersContextState,
  useContractSearchAndFiltersContext,
} from '@cotiss/contract/components/contract-search-and-filters-context.component'
import { FilterContractShellSortKey } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { CONTRACT_SEARCH_AND_FILTERS_FILTER_FIELDS_WHITELIST } from '@cotiss/contract/contract.constants'
import { useFilterContractShells } from '@cotiss/contract/resources/use-filter-contract-shells.resource'

export const useContractSearchAndFiltersQuery = () => {
  const { queryState, processedFilters, queryStateDispatch } = useContractSearchAndFiltersContext()
  const { searchQuery, currentPage } = queryState

  const { advancedFiltersDispatch, validAdvancedFilters } = useAdvancedFiltersContext()

  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })

  const handlePageChange = (page: number) => {
    queryStateDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })
  }

  useEffect(() => {
    queryStateDispatch({
      type: 'UPDATE_ADVANCED_FILTERS',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      payload: map(validAdvancedFilters, ({ id, ...rest }) => ({ ...rest } as Filter)),
    })
  }, [validAdvancedFilters])

  const {
    contractShells,
    pagination,
    meta,
    isLoading: isLoadingContractShells,
  } = useFilterContractShells({
    filters: processedFilters,
    searchQuery,
    currentPage: currentPage,
    pageSize: 20,
    sort: sortKey,
    order: sortDirection,
  })

  useEffect(() => {
    if (!meta) return
    const filterFields = pickBy(meta, (_, key) => CONTRACT_SEARCH_AND_FILTERS_FILTER_FIELDS_WHITELIST.includes(key) || key.startsWith('metafield'))
    advancedFiltersDispatch({ type: 'SET_FILTER_FIELDS', payload: filterFields })
  }, [meta])

  return {
    queryStateDispatch,
    contractShells,
    isLoadingContractShells,
    pagination,
    handlePageChange,
    onSort,
  }
}

export const ContractSearchAndFiltersProvider = ({
  children,
  initialState,
}: {
  children: ReactNode
  initialState: Partial<ContractSearchAndFiltersContextState>
}) => {
  const initialAdvancedFilters = initialState?.advancedFilters

  return (
    <ContractSearchAndFiltersContextProvider initialState={initialState}>
      <AdvancedFiltersProvider initialFilters={initialAdvancedFilters}>{children}</AdvancedFiltersProvider>
    </ContractSearchAndFiltersContextProvider>
  )
}
