import { memo } from 'react'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'

type Props = {
  className?: string
  title: string
  relativeWeight: number
  shouldShowWeight?: boolean
}

export const EvaluationEventWeightedTitle = memo(({ title, relativeWeight, shouldShowWeight = true }: Props) => (
  <>
    {title}{' '}
    {!isNaN(Number(relativeWeight)) && shouldShowWeight ? (
      <Text variant="secondary" isInline size="inherit">
        {utilService.formatAsPercentage(Number(relativeWeight) * 100)}
      </Text>
    ) : null}
  </>
))
