import { FormEvent, useState, memo, useEffect } from 'react'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { toLower } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { authResource } from '@cotiss/auth/auth.resource'

export const ForgotPasswordPage = memo(() => {
  const { logout } = useAuth()
  const [isSent, setIsSent] = useState(false)
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({ email: '', password: '' })

  useEffect(() => {
    logout()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')
    setIsSaving(true)

    try {
      await authResource.forgotPassword(formData.email)

      setIsSent(true)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setFormError(error.message)
      setIsSaving(false)
    }
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="bg-white w-1/2 h-full p-12">
          <div className="flex items-center">
            <CotissLogoDarkSvg className="h-3 mr-1" />
          </div>
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-[350px]">
              <Text className="font-semibold" size="h3">
                Forgot password
              </Text>
              <Text className="mt-2" variant="light">
                Enter your email address below and we will send you a link to reset your password. This reset link will be valid for 20 minutes.
              </Text>
              {Boolean(formError) && (
                <Text className="text-sm my-4" variant="danger">
                  {formError}
                </Text>
              )}
              {isSent && (
                <div className="mt-8">
                  <Text>We have sent you a reset password email</Text>
                  <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
                    <Text className="mr-1" isInline>
                      Back to
                    </Text>
                    <Button className="text-sm" state="text" variant="link" href={routerService.getHref('/login')} isDisabled={isSaving} isLink>
                      Log in
                    </Button>
                  </div>
                </div>
              )}
              {!isSent && (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <Label>Email address</Label>
                  <Input
                    value={formData.email}
                    onChange={({ target }) => setFormData({ ...formData, email: toLower(target.value) })}
                    placeholder="Enter your email address"
                    type="email"
                    autoComplete="username"
                    maxLength={320}
                    isDisabled={isSaving}
                    isRequired
                  />
                  <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
                    Send email
                  </Button>

                  <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
                    <Text className="mr-1" isInline>
                      Or go back to
                    </Text>
                    <Button
                      className="inline-block text-sm"
                      state="text"
                      variant="link"
                      href={routerService.getHref('/login')}
                      isDisabled={isSaving}
                      isLink
                    >
                      Log in
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white w-1/2 h-full p-4">
          <div className="flex flex-col items-start justify-between bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight">
              Better decisions.
              <br />
              Simplified process.
            </h1>
          </div>
        </div>
      </div>
    </Page>
  )
})
