import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { usePerformanceScorecardAnalytics } from '@cotiss/performance/hooks/use-performance-scorecard-analytics.hook'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { GqlPerformanceScorecardFieldsFragment } from '@gql'
import { FormEvent, memo, useState } from 'react'

type Props = {
  performanceScorecard: GqlPerformanceScorecardFieldsFragment
}

export const PerformanceScorecardUpdateModal = memo(({ performanceScorecard }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = usePerformanceScorecardAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateUpdatePerformanceScorecard } = usePerformanceScorecard()

  const [name, setName] = useState(performanceScorecard?.name || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!performanceScorecard) {
      return
    }

    if (performanceScorecard.isArchived) {
      openToast('You cannot update an archived performance scorecard.', 'danger')
      return
    }

    track('performance_scorecard_update_modal_submit')

    try {
      setIsSaving(true)
      await mutateUpdatePerformanceScorecard({ performanceScorecardId: performanceScorecard.id, name })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update scorecard" isDisabled={isSaving} />
      <ModalContent>
        <Label>Scorecard name</Label>
        <Input value={name} onChange={({ target }) => setName(target.value)} placeholder="Enter scorecard name" isDisabled={isSaving} isRequired />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
