import { memo, useMemo } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Badge } from '@cotiss/common/components/badge.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { EvaluationEventCriteriaRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-criteria-rating-scale.drawer'
import { EVALUATION_EVENT_RATING_SCALE_NAME_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  weightedPercentageById?: Record<string, number>
  overallWeightedPercentageById?: Record<string, number>
  isEditable?: boolean
}

export const EvaluationEventParentTerminalCriteria = memo((props: Props) => {
  const { evaluationEnvelope, evaluationCriteriaItem, weightedPercentageById, overallWeightedPercentageById, isEditable } = props
  const { openDrawer } = useCallout()
  const { evaluationCriteria } = useEvaluationCriteria()

  const isWeightedCriteria = evaluationCriteriaItem.weight !== 0

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Rating scale',
        thClassName: 'w-[340px]',
        rows: [
          {
            content: () =>
              isWeightedCriteria ? (
                <div className="self-start">
                  <Text>{EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationCriteriaItem.ratingScale || evaluationEnvelope.defaultRatingScale]}</Text>
                  {evaluationCriteriaItem.ratingScale !== evaluationEnvelope.defaultRatingScale && (
                    <Badge className="mt-2" variant="info" state="outline" size="sm">
                      Updated
                    </Badge>
                  )}
                </div>
              ) : (
                <Text>
                  NA -{' '}
                  <Text variant="light" isInline size="inherit">
                    Evaluators can provide commentary against this unweighted criteria.
                  </Text>
                </Text>
              ),
            cta: (
              <TableRowCta
                cta={{
                  label: isEditable ? 'Edit' : 'View',
                  onClick: () =>
                    openDrawer(
                      <EvaluationEventCriteriaRatingScaleDrawer
                        evaluationEnvelope={evaluationEnvelope}
                        evaluationCriteriaItem={evaluationCriteriaItem}
                        isEditable={isEditable}
                      />
                    ),
                }}
              />
            ),
          },
          ...(weightedPercentageById && isWeightedCriteria
            ? [
                {
                  content: () => <Text className="font-medium">Totals</Text>,
                },
              ]
            : []),
        ],
      },
      {
        heading: 'Evaluation requirements',
        rows: [
          {
            tdClassName: 'align-top',
            content: () => <Text className="whitespace-pre-wrap line-clamp-3">{evaluationCriteriaItem.supplementary || '--'}</Text>,
          },
          ...(weightedPercentageById && isWeightedCriteria
            ? [
                {
                  content: () => <></>,
                },
              ]
            : []),
        ],
      },
      ...(weightedPercentageById && overallWeightedPercentageById
        ? [
            {
              heading: 'Envelope weight',
              thClassName: 'text-right w-32',
              rows: [
                {
                  tdClassName: 'text-right align-top',
                  content: () => (
                    <>
                      <Text>{utilService.formatAsPercentage(Number(weightedPercentageById[evaluationCriteriaItem.id]) * 100) || '--'}</Text>
                    </>
                  ),
                },
                {
                  tdClassName: 'text-right',
                  content: () => (
                    <Text className="font-medium" variant="secondary">
                      {utilService.formatAsPercentage(Number(weightedPercentageById[evaluationCriteriaItem.id]) * 100)}
                    </Text>
                  ),
                },
              ],
            },
            {
              heading: 'Overall weight',
              thClassName: 'text-right w-40',
              rows: [
                {
                  tdClassName: 'text-right align-top',
                  content: () => (
                    <>
                      <Text>
                        {utilService.formatAsPercentage(
                          Number(weightedPercentageById[evaluationCriteriaItem.id]) * Number(weightedPercentageById[evaluationEnvelope.id]) * 100
                        ) || '--'}
                      </Text>
                      <Text className="mt-1" variant="light" size="sm">
                        ({utilService.formatAsPercentage(Number(weightedPercentageById[evaluationCriteriaItem.id]) * 100, 0)} x{' '}
                        {utilService.formatAsPercentage(Number(weightedPercentageById[evaluationEnvelope.id]) * 100, 0)})
                      </Text>
                    </>
                  ),
                },
                {
                  tdClassName: 'text-right',
                  content: () => (
                    <Text className="font-medium" variant="secondary">
                      {utilService.formatAsPercentage(Number(overallWeightedPercentageById[evaluationCriteriaItem.id]) * 100)}
                    </Text>
                  ),
                },
              ],
            },
          ]
        : []),
    ]

    return { columns }
  }, [evaluationCriteria, isEditable])

  return <Table columns={columns} />
})
