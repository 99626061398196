import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { EvaluationEventEnvelopeCommentaryComparison } from '@cotiss/evaluation-event/components/evaluation-event-envelope-commentary-comparison.component'
import { GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationFieldsFragment } from '@gql'
import { EvaluationEventEnvelopeCommentaryEvaluation } from '@cotiss/evaluation-event/components/evaluation-event-envelope-commentary-evaluation.component'
import { EvaluationEventEvaluationRequirements } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-requirements'

type EvaluationEventEnvelopeTab = 'envelope-evaluation' | 'compare'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  hasCriteria: boolean
}

export const EvaluationEventEnvelopeCommentary = ({ evaluation, evaluationEnvelope, hasCriteria }: Props) => {
  const { step, transition, onTransition } = useTransition()

  const EVALUATION_EVENT_COMMENTARY_TABS: TabModel<EvaluationEventEnvelopeTab>[] = [
    { id: 'envelope-evaluation', label: hasCriteria ? 'Commentary' : 'Envelope evaluation' },
    { id: 'compare', label: 'Compare' },
  ]

  const handleTabChange = (tab: EvaluationEventEnvelopeTab) => {
    const newStep = findIndex(EVALUATION_EVENT_COMMENTARY_TABS, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <div className="flex flex-col gap-y-6">
      {evaluationEnvelope?.supplementary && (
        <div className="bg-primary-50 rounded-xl px-4 py-4">
          <EvaluationEventEvaluationRequirements evaluationRequirements={evaluationEnvelope.supplementary} />
        </div>
      )}
      <Tabs<EvaluationEventEnvelopeTab>
        tabs={EVALUATION_EVENT_COMMENTARY_TABS}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
        className="border-b w-full"
      />
      <div className="flex-1 overflow-hidden">
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition} className="h-full">
            {step === 1 && <EvaluationEventEnvelopeCommentaryEvaluation hasCriteria={hasCriteria} evaluation={evaluation} />}
            {step === 2 && <EvaluationEventEnvelopeCommentaryComparison hasCriteria={hasCriteria} evaluationEnvelope={evaluationEnvelope} />}
          </TransitionContainer>
        </AnimatePresence>
      </div>
    </div>
  )
}
