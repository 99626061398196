import { memo, useState } from 'react'
import { GqlCreateEvaluationEnvelopeInput } from '@gql'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventSummaryEnvelopes } from '@cotiss/evaluation-event/components/evaluation-event-summary-envelopes.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

type EvaluationEventEnvelopeOption = {
  title: string
  value: number
  description: string
}

const EVALUATION_EVENT_ENVELOPE_OPTIONS: EvaluationEventEnvelopeOption[] = [
  {
    title: 'One envelope',
    value: 1,
    description: 'All evaluators and submission documents are part of a single evaluation',
  },
  {
    title: 'Two envelopes',
    value: 2,
    description: 'Evaluators and submission documents can be split across two envelopes and evaluation can be done in parallel or sequentially',
  },
]

export const EvaluationEventWizardEnvelopesStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const [errorText, setErrorText] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const {
    evaluationEnvelopes,
    queryEvaluationEnvelopeList,
    mutateCreateEvaluationEnvelope,
    mutateUpdateEvaluationEnvelope,
    mutateDeleteEvaluationEnvelope,
  } = useEvaluationEnvelope()

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelopes_view')

    try {
      await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [])

  const handleClickContinue = () => {
    if (!evaluationEnvelopes.length) {
      setErrorText('You must have at least one envelope.')
      return
    }

    onNext()
  }

  const handleChange = async (newEnvelopeCount: number) => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelopes_update_submit')

    const baseEnvelopeCreateInput: GqlCreateEvaluationEnvelopeInput = {
      evaluationEventId: evaluationEvent.id,
      name: '',
      order: 1,
      weight: 1,
      isScored: true,
    }

    try {
      setIsSaving(true)
      // Adding one envelopes initially.
      if (!evaluationEnvelopes.length && newEnvelopeCount === 1) {
        await mutateCreateEvaluationEnvelope({ ...baseEnvelopeCreateInput, name: 'Non price & price', order: 1 })
        await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
      }

      // Adding two envelopes initially.
      if (!evaluationEnvelopes.length && newEnvelopeCount === 2) {
        const inputs: GqlCreateEvaluationEnvelopeInput[] = map(Array(2), (_, index) => ({
          ...baseEnvelopeCreateInput,
          name: !index ? 'Non price' : 'Price',
          order: index + 1,
        }))

        await Promise.all(map(inputs, (input) => mutateCreateEvaluationEnvelope(input)))
        await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
      }

      // Adding a second envelope.
      if (evaluationEnvelopes.length === 1 && newEnvelopeCount === 2) {
        await Promise.all([
          mutateUpdateEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelopes[0].id, name: 'Non price' }),
          mutateCreateEvaluationEnvelope({ ...baseEnvelopeCreateInput, name: 'Price', order: 2 }),
        ])
        await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
      }

      // Deleting the second envelope
      if (evaluationEnvelopes.length === 2 && newEnvelopeCount === 1) {
        const envelopeToDelete = evaluationEnvelopes[1]
        openModal(
          <ConfirmModal
            heading="Delete envelope?"
            description={`To reduce the number of envelopes, ${envelopeToDelete.name} will be deleted.`}
            onSubmit={async () => {
              await Promise.all([
                mutateUpdateEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelopes[0].id, name: 'Non price & price', order: 1, weight: 1 }),
                mutateDeleteEvaluationEnvelope({ evaluationEnvelopeId: envelopeToDelete.id }),
              ])
              await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
            }}
          />
        )
      }
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Envelopes
        </Text>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading}>
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm" isDisabled={isLoading}>
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      <Field className="mb-8" label="How many envelopes would you like in this evaluation?">
        <div className="grid grid-cols-2 grid-rows-1 gap-4">
          {map(EVALUATION_EVENT_ENVELOPE_OPTIONS, ({ title, value, description }) => (
            <RadioCard
              key={value}
              className="border border-gray-100 p-3.5"
              name="evaluation-event-envelopes"
              isSelected={evaluationEnvelopes.length === value}
              onChange={() => handleChange(value)}
              isDisabled={isSaving || isLoading}
            >
              <div className="flex justify-between">
                <Text className="font-medium">{title}</Text>
                <Radio className="ml-2" isChecked={evaluationEnvelopes.length === value} />
              </div>
              <Text className="mt-1" size="sm" variant="light">
                {description}
              </Text>
            </RadioCard>
          ))}
        </div>
      </Field>
      {(isLoading || Boolean(evaluationEnvelopes.length)) && (
        <div className="mb-2">
          <EvaluationEventSummaryEnvelopes isSortable isEditable />
        </div>
      )}
    </Card>
  )
})
