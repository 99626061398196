import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { forEach } from 'lodash'
import { useReducer } from 'react'

type State = {
  expandedMap: Record<string, boolean>
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment | null
  activeEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment | null
}

type Action =
  | { type: 'TOGGLE_CRITERIA_EXPANSION'; payload: string }
  | {
      type: 'SET_CRITERIA_ITEMS'
      payload: { parent: GqlEvaluationCriteriaFieldsFragment | null; active: GqlEvaluationCriteriaFieldsFragment | null }
    }

export const getIsExpandedMap = (parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
  const expandedMap: Record<string, boolean> = {}

  forEach(parentEvaluationCriteria, ({ id, isScored }) => {
    // If the criteria is scored, then we know that it does not have sub-criteria.
    if (!isScored) {
      expandedMap[id] = false
    }
  })

  return expandedMap
}

const initializeState = (parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
  const firstParentCriteria = parentEvaluationCriteria[0]
  const expandedMap = getIsExpandedMap(parentEvaluationCriteria)

  if (firstParentCriteria?.isScored) {
    return {
      expandedMap,
      parentEvaluationCriteriaItem: firstParentCriteria,
      activeEvaluationCriteriaItem: firstParentCriteria,
    }
  } else if (firstParentCriteria) {
    expandedMap[firstParentCriteria.id] = true
    return {
      expandedMap,
      parentEvaluationCriteriaItem: null,
      activeEvaluationCriteriaItem: null,
    }
  }

  return {
    expandedMap: {},
    parentEvaluationCriteriaItem: null,
    activeEvaluationCriteriaItem: null,
  }
}

const createReducer =
  (parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) =>
  (state: State, action: Action): State => {
    switch (action.type) {
      case 'TOGGLE_CRITERIA_EXPANSION': {
        const criteriaId = action.payload
        const isCurrentlyExpanded = state.expandedMap[criteriaId]

        return {
          ...state,
          expandedMap: isCurrentlyExpanded
            ? { ...state.expandedMap, [criteriaId]: false }
            : {
                ...getIsExpandedMap(parentEvaluationCriteria),
                [criteriaId]: true,
              },
        }
      }
      case 'SET_CRITERIA_ITEMS': {
        const { parent, active } = action.payload
        return {
          ...state,
          // Reset expanded map to default if parent is scored
          expandedMap: parent?.isScored ? getIsExpandedMap(parentEvaluationCriteria) : state.expandedMap,
          parentEvaluationCriteriaItem: parent,
          activeEvaluationCriteriaItem: active,
        }
      }
      default:
        return state
    }
  }

export const useModerationCriteriaExpansion = (parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
  const reducer = createReducer(parentEvaluationCriteria)
  return useReducer(reducer, initializeState(parentEvaluationCriteria))
}
