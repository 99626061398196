import { FormEvent, ReactNode, memo, useRef } from 'react'
import classNames from 'classnames'
import { Form } from '@cotiss/common/components/form.component'
import { DrawerContent } from '@cotiss/common/containers/callout/drawer/drawer-content.component'
import { DrawerFooter } from '@cotiss/common/containers/callout/drawer/drawer-footer.component'
import { DrawerHeader } from '@cotiss/common/containers/callout/drawer/drawer-header.component'
import { ErrorBoundary } from '@cotiss/error/components/error-boundary.component'

type Props = {
  className?: string
  children: ReactNode
  header: ReactNode
  footer?: ReactNode
  onSubmit?: () => void | Promise<unknown>
  hasPadding?: boolean
  isNarrow?: boolean
}

export const Drawer = memo(({ className, children, header, footer, onSubmit, hasPadding = true, isNarrow }: Props) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const classes = classNames(className, 'flex flex-col h-screen')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onSubmit && (await onSubmit())
  }

  const renderContent = () => (
    <>
      <DrawerHeader ref={headerRef} isNarrow={isNarrow}>
        {header}
      </DrawerHeader>
      <ErrorBoundary>
        <DrawerContent hasPadding={hasPadding}>{children}</DrawerContent>
        {footer && (
          <DrawerFooter ref={footerRef} isNarrow={isNarrow}>
            {footer}
          </DrawerFooter>
        )}
      </ErrorBoundary>
    </>
  )

  return onSubmit ? (
    <Form className={classes} onSubmit={handleSubmit}>
      {renderContent()}
    </Form>
  ) : (
    <div className={classes}>{renderContent()}</div>
  )
})
