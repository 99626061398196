import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'

interface ValidateResetPasswordTokenResponse {
  isValid: boolean
}

export const useGetValidateResetPasswordToken = ({ token }: { token: string }) => {
  const { data, ...rest } = useFetch<ValidateResetPasswordTokenResponse>('/v3/auth/validate-reset-password-token', {
    route: `/v3/auth/validate-reset-password-token/${token}`,
    isPublicApi: true,
  })

  return { isValid: data?.isValid, ...rest }
}
