import { memo } from 'react'
import { clamp } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

import { AsyncInput } from '@cotiss/common/components/async-input.component'

type Props = {
  onChange: (value: number) => void
  onSubmit: (value: number) => Promise<void>
  value: number
  isDisabled?: boolean
  disabledTooltip?: string
  min: number
  max: number
}

export const EvaluationEventWeightInput = memo(({ onChange, onSubmit, value, isDisabled, disabledTooltip, min = 1, max = 100 }: Props) => {
  const handleUpdateWeight = async () => {
    const clampedWeight = clamp(Number(value || 0), min, max)
    onChange?.(clampedWeight)
    await onSubmit?.(clampedWeight)
  }

  return (
    <AsyncInput
      className="w-20"
      value={value ?? ''}
      onChange={({ target }) => onChange(Number(target.value) || 0)}
      onSubmit={handleUpdateWeight}
      onFocus={({ target }) => target.select()}
      placeholder="--"
      isDisabled={isDisabled}
      rightSlot={
        isDisabled && (
          <Tooltip tooltip={disabledTooltip} isEnabled={Boolean(disabledTooltip)}>
            <Icon icon="lock" variant="light" />
          </Tooltip>
        )
      }
    />
  )
})
