import { memo, useMemo } from 'react'
import { filter } from 'lodash'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { EvaluationEventCriteriaCreateUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-criteria-create-update.modal'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Button } from '@cotiss/common/components/button.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { EvaluationEventParentCriteriaWeightListTable } from '@cotiss/evaluation-event/components/evaluation-event-parent-criteria-weight-list-table.component'

type Props = {
  className?: string
  envelopeId: string
  isLoading?: boolean
}

export const EvaluationEventParentCriteriaWeightList = memo(({ envelopeId, isLoading }: Props) => {
  const { track } = useEvaluationEventAnalytics()
  const { mutateUpdateEvaluationCriteria, queryEvaluationCriteriaList, evaluationCriteria, mutateDeleteEvaluationCriteria } = useEvaluationCriteria()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationEnvelopes } = useEvaluationEnvelope()

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'

  const parentCriteria = useMemo(() => {
    return filter(evaluationCriteria, { evaluationEnvelopeId: envelopeId, parentEvaluationCriteriaId: null })
  }, [evaluationCriteria])

  const { openModal } = useCallout()

  const handleUpdateWeight = async (evaluationCriteriaId: string, weight: number) => {
    track('evaluation_event_wizard_weight_criteria_update_submit')

    await mutateUpdateEvaluationCriteria({ evaluationCriteriaId, weight })
  }

  const handleDelete = async (evaluationCriteriaId: string) => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_criteria_delete_submit')

    await mutateDeleteEvaluationCriteria({ evaluationCriteriaId })
    await queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
  }

  return (
    <EvaluationEventParentCriteriaWeightListTable
      parentCriteria={parentCriteria}
      evaluationEnvelopes={evaluationEnvelopes}
      envelopeId={envelopeId}
      isLoading={isLoading}
      onDeleteCriteria={handleDelete}
      isWeightedMethodology={isWeightedMethodology}
      onUpdateCriteria={handleUpdateWeight}
      emptyCta={
        <Button
          variant="secondary"
          state="text"
          size="sm"
          onClick={() => openModal(<EvaluationEventCriteriaCreateUpdateModal evaluationEnvelopeId={envelopeId} />)}
        >
          + Add criteria
        </Button>
      }
    />
  )
})
