import { memo, useMemo, useState, useEffect } from 'react'
import ListCheckboxPlaceholder from '@assets/svg/list-checkbox-placeholder.svg'
import classNames from 'classnames'
import { compact, filter, find, includes, isEqual, map, size, sortBy, toLower } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Text } from '@cotiss/common/components/text.component'
import { EXTERNAL_LINK } from '@cotiss/common/constants/external-link.constants'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ProcessedUnspscCategory, ProcessedUnspscSubcategory } from '@cotiss/supplier/supplier.models'
import { supplierService } from '@cotiss/supplier/supplier.service'
import { SearchInput } from '@cotiss/common/components/search-input.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { useDebouncedChangeHandler } from '@cotiss/common/hooks/use-debounced-change-handler.hook'
import { UnspscCategoriesVirtualizedSubcategoriesList } from '@cotiss/common/modals/unspsc-categories/unspsc-categories-virtualized-subcategories-list'

type ActiveCategory = {
  categoryCode: string
  subcategories: ProcessedUnspscSubcategory[]
}

type Props = {
  selectedCategoryCodes: string[]
  onChange: (categoryCodes: string[]) => void
}

export const UnspscCategoriesModal = memo(({ selectedCategoryCodes, onChange }: Props) => {
  const [q, setQ] = useState('')
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoadingUnspscData, setIsLoadingUnspscData] = useState(true)
  const [sortedUnspscData, setSortedUnspscData] = useState<ProcessedUnspscCategory[]>([])
  const [activeCategory, setActiveCategory] = useState<ActiveCategory | null>(null)
  const [stateCategories, setStateCategories] = useState<ProcessedUnspscSubcategory[]>([])

  const { tempValue, handleDebouncedChange, handleClear } = useDebouncedChangeHandler(q, setQ)

  useEffect(() => {
    const loadUnspscData = async () => {
      setIsLoadingUnspscData(true)
      try {
        const categoryMap = await supplierService.getUnspscCategoryMap()
        const processedCategories = await supplierService.getProcessedUnspscCategories()

        setSortedUnspscData(processedCategories)
        setStateCategories(sortBy(compact(map(selectedCategoryCodes, (categoryCode) => categoryMap[categoryCode])), 'name'))
      } finally {
        setIsLoadingUnspscData(false)
      }
    }

    loadUnspscData()
  }, [])

  const filteredUnspscCategories = useMemo(() => {
    const qToUse = toLower(tempValue)
    const processedUnspscData = qToUse
      ? map(sortedUnspscData, ({ subcategories, name, code }) => {
          const filteredSubcategories = filter(subcategories, ({ name, code }) => includes(toLower(name), qToUse) || includes(toLower(code), qToUse))

          if (filteredSubcategories.length || includes(toLower(name), qToUse) || includes(toLower(code), qToUse)) {
            return {
              subcategories: filteredSubcategories,
              name,
              code,
            }
          }

          return null
        })
      : sortedUnspscData

    return compact(processedUnspscData)
  }, [sortedUnspscData, tempValue])

  const subCategoriesToRender = useMemo(() => {
    if (!activeCategory) {
      return []
    }

    const qToUse = toLower(tempValue)
    return qToUse
      ? filter(activeCategory.subcategories, ({ name, code }) => includes(toLower(name), qToUse) || includes(toLower(code), qToUse))
      : activeCategory.subcategories
  }, [activeCategory, filteredUnspscCategories, tempValue])

  const isDirty = useMemo(() => {
    return !isEqual(
      selectedCategoryCodes,
      map(stateCategories, ({ code }) => code)
    )
  }, [selectedCategoryCodes, stateCategories])

  const setStateCategoriesWithSort = (categories: ProcessedUnspscSubcategory[]) => {
    setStateCategories(sortBy(categories, 'name'))
  }

  const handleConfirm = () => {
    setIsSaving(true)
    onChange(map(stateCategories, ({ code }) => code))
  }

  const handleCategoryClick = ({ code: categoryCode }: ProcessedUnspscCategory) => {
    setActiveCategory({ categoryCode, subcategories: find(sortedUnspscData, { code: categoryCode })?.subcategories || [] })
  }

  const handleSubcategoryChange = (subcategory: ProcessedUnspscSubcategory, isSelected: boolean) => {
    setStateCategoriesWithSort(
      isSelected ? [...stateCategories, subcategory] : filter(stateCategories, (category) => category.code !== subcategory.code)
    )
  }

  const loadingSkeleton = () => {
    return (
      <div className="p-2">
        <div className="mt-4">
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-2/3 mt-1" />
        </div>
        <div className="mt-4">
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-2/3 mt-1" />
        </div>
        <div className="mt-4">
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-full mt-1" />
          <Skeleton className="h-4 w-2/3 mt-1" />
        </div>
      </div>
    )
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-700 bg-opacity-50 p-20 z-[1001]">
      <div className="flex flex-col bg-white rounded-lg max-w-[900px] h-[500px] w-full py-4">
        <div className="flex items-center justify-between w-full px-6">
          <div className="flex items-center justify-start gap-3 flex-1">
            <Text className="shrink-0 font-semibold" size="h7">
              Select UNSPSC Categories
            </Text>
            <VerticalDivider className="h-7" />
            <div className="flex-1 w-full">
              <SearchInput
                value={tempValue}
                onChange={({ target }) => handleDebouncedChange(target.value)}
                onClear={handleClear}
                placeholder="Search keyword or code #"
              />
            </div>
          </div>
          <div className="flex items-center shrink-0 ml-2">
            <Button className="mr-2" onClick={handleConfirm} variant="secondary" size="sm" isLoading={isSaving} isDisabled={!isDirty}>
              Confirm Selection
            </Button>

            <Button className="ml-2" onClick={() => closeModal()} state="translucent" variant="secondary" shape="square" size="sm" isRounded>
              <Icon icon="x-close" size={20} />
            </Button>
          </div>
        </div>

        <div className="flex items-stretch h-[calc(100%-42px)] mt-4">
          <div className="flex flex-col w-2/3 mr-4">
            <div className="flex items-center justify-between bg-secondary-100 rounded-r-lg py-3 px-6">
              <div className="w-1/3 mr-4">
                <Text className="font-medium uppercase" size="xs">
                  Categories ({size(filteredUnspscCategories)})
                </Text>
              </div>
              <div className="flex items-center justify-between w-2/3">
                <Text className="font-medium uppercase" size="xs">
                  Sub Categories{activeCategory && ` (${size(activeCategory.subcategories)})`}
                </Text>
                <Text className="font-medium uppercase" size="xs">
                  Code
                </Text>
              </div>
            </div>
            <div className="flex h-[calc(100%-42px)] pl-4">
              <div className="overflow-y-auto border-r border-gray-100 w-1/3 pr-2 mr-2">
                {isLoadingUnspscData
                  ? loadingSkeleton()
                  : map(filteredUnspscCategories, (category, index) => {
                      const { code, name, subcategories } = category
                      const isActive = Boolean(activeCategory?.categoryCode === code)
                      const classes = classNames('text-left text-xs rounded w-full p-2', {
                        'mt-4': !index,
                        'mt-0.5': index,
                        'bg-secondary-100': isActive,
                      })

                      return (
                        <Button key={code} className={classes} onClick={() => handleCategoryClick(category)} state="raw" isDisabled={isSaving}>
                          <Text variant={isActive ? 'secondary' : 'dark'}>
                            {name} ({subcategories.length})
                          </Text>
                        </Button>
                      )
                    })}
              </div>
              <div className="overflow-y-auto pl-2 w-2/3">
                {activeCategory ? (
                  <UnspscCategoriesVirtualizedSubcategoriesList
                    items={subCategoriesToRender}
                    onSubcategoryChange={handleSubcategoryChange}
                    isSaving={isSaving}
                    stateCategories={stateCategories}
                  />
                ) : (
                  <div className="flex justify-center items-center h-full w-full">
                    <NoDataPlaceholder
                      label="Select a category from the list and browse subcategories here"
                      variant="transparent"
                      orientation="vertical"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full pr-6 pb-4">
            {stateCategories.length > 0 ? (
              <div className="bg-secondary-50 border border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                <Text className="font-medium uppercase" size="xs">
                  Selected ({stateCategories.length})
                </Text>
                {map(stateCategories, (category) => (
                  <div key={category.code} className="mt-2">
                    <Pill onRemove={() => handleSubcategoryChange(category, false)}>{category.name}</Pill>
                  </div>
                ))}
              </div>
            ) : (
              <div className="border border-dashed flex flex-col justify-center items-center text-center border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                {activeCategory && (
                  <>
                    <ListCheckboxPlaceholder className="mb-4" />
                    <Text className="font-semibold mb-2">No Subcategories Selected</Text>
                    <Text size="xs" variant="light">
                      Tick the subcategories that you would like added. Learn more about{' '}
                      <ExternalLink href={EXTERNAL_LINK.unspsc} className="text-secondary-500 hover:underline" size="xs" isInline>
                        UNSPSC
                      </ExternalLink>{' '}
                      categories
                    </Text>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
