import { FormEvent, memo, useState } from 'react'
import { GqlEvaluationFieldsFragment } from '@gql'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
  onChange: () => void
}

export const EvaluationEventModerationCommentCreateUpdateModal = memo((props: Props) => {
  const { evaluation, onChange } = props
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateUpdateEvaluation } = useEvaluation()
  const [comment, setComment] = useState(evaluation.moderationComment || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await mutateUpdateEvaluation({
        evaluationId: evaluation.id,
        moderationComment: comment,
      })

      onChange()
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${evaluation.moderationComment ? 'Edit' : 'Add'} moderation comment`} />
      <ModalContent>
        <Label>Comment</Label>
        <TextArea
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          placeholder="Add moderation comment"
          rows={4}
          isDisabled={isSaving}
          autoFocus
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
