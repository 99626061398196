import { memo, useMemo, useState } from 'react'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { OCDS_CURRENCY_OPTIONS } from '@cotiss/common/constants/ocds.constants'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { OcdsCurrencyCode } from '@cotiss/common/models/ocds.model'
import { ContractWizardMilestoneTable } from '@cotiss/contract/components/contract-wizard-milestone-table.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

type Props = {
  contractShellId: string
  contractId: string
  showComparison?: boolean
}

type SummaryMilestoneTab = 'variated' | 'approved'
const CONTRACT_SUMMARY_MILESTONE_TABS: TabModel<SummaryMilestoneTab>[] = [
  { id: 'variated', label: 'Variated' },
  { id: 'approved', label: 'Approved' },
]

export const ContractSummaryMilestoneTab = memo(({ contractId, contractShellId, showComparison = false }: Props) => {
  const [tab, setTab] = useState<SummaryMilestoneTab>('variated')

  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { contract, isVariation } = useMemo(() => {
    const currentContract = contractShell?.contracts.find((contract) => contract._id === contractId)
    const publishedContract = contractShell?.contracts.find(
      (contract) => contract._id === contractService.getContract(contractShell, ['PUBLISHED'])?._id
    )

    const contract = tab === 'approved' ? publishedContract : currentContract

    return { contract, isVariation: Boolean(currentContract?.variationTypes.length) }
  }, [contractShell, tab, contractId])

  return (
    <div className="py-6">
      {isVariation && showComparison && (
        <Tabs<SummaryMilestoneTab>
          className="mb-4"
          tab={tab}
          tabs={CONTRACT_SUMMARY_MILESTONE_TABS}
          onChange={({ id }) => setTab(id)}
          variant="raised"
        />
      )}
      <div className="mb-4 w-96">
        {isLoading && <Skeleton className="w-3/4 h-12" />}
        {!isLoading && !contract?.metadata.currency && <Text>No currency selected</Text>}
        {contract?.metadata.currency && (
          <Select_DEPRECATED<OcdsCurrencyCode>
            value={contract.metadata.currency}
            options={OCDS_CURRENCY_OPTIONS}
            onChange={() => 0}
            isDisabled
            placeholder
            className="w-2/3"
          />
        )}
      </div>
      {!contract?.milestones.length && <NoDataPlaceholder illustration="dot-list" label="No contract durations" />}
      {Boolean(contract?.milestones.length) && (
        <>
          <div className="border border-grey-200 border-b-0 rounded-t-md mt-6">
            <div className="bg-primary-50 p-4 flex justify-between items-center">
              <Text className="font-semibold">Contract overview</Text>
            </div>
          </div>
          <ContractWizardMilestoneTable
            milestones={contract?.milestones || []}
            isVariation={isVariation}
            contractShellId={contractShellId}
            currency={contract?.metadata.currency}
            showErrors
          />
        </>
      )}
    </div>
  )
})
