import { Text } from '@cotiss/common/components/text.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { map } from 'lodash'
import { GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationFieldsFragment } from '@gql'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useState } from 'react'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  hasCriteria: boolean
}

export const EvaluationEventEnvelopeCommentaryComparison = ({ evaluationEnvelope, hasCriteria }: Props) => {
  const { queryEvaluationList } = useEvaluation()
  const [isLoading, setIsLoading] = useState(true)
  const [evaluations, setEvaluations] = useState<GqlEvaluationFieldsFragment[]>([])

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const isScoreable = isWeightedEnvelope && !hasCriteria

  useAsyncEffect(async () => {
    try {
      const response = await queryEvaluationList({
        filter: { evaluationEventId: evaluationEnvelope?.evaluationEventId, evaluationEnvelopeId: evaluationEnvelope?.id },
      })
      setEvaluations(response.evaluations)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }

    setIsLoading(false)
  }, [])

  const columns: TableColumn[] = [
    {
      heading: 'Submission name',
      thClassName: 'w-48',
      rows: map(evaluations, (evaluation) => {
        return {
          content: () => (
            <Text size="sm" className="truncate" title={evaluation?.submissionName ?? ''}>
              {evaluation?.submissionName}
            </Text>
          ),
        }
      }),
    },
    ...(isScoreable
      ? [
          {
            heading: 'Score',
            thClassName: 'w-32',
            rows: map(evaluations, (evaluation) => {
              return {
                content: () => (
                  <Text size="sm">
                    {evaluation?.value ?? '--'}{' '}
                    {evaluationEnvelope?.defaultRatingScale === 'percentage'
                      ? '%'
                      : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[evaluationEnvelope?.defaultRatingScale]}`}
                  </Text>
                ),
              }
            }),
          },
        ]
      : []),
    {
      heading: 'Commentary',
      rows: map(evaluations, (evaluation) => {
        return {
          content: () => <Text size="sm">{evaluation?.comment ?? '--'}</Text>,
        }
      }),
    },
  ]

  return (
    <div>
      <TableHeader className="flex justify-between items-center">
        <div>
          <Text className="font-semibold">
            Envelope {evaluationEnvelope?.order}: {evaluationEnvelope?.name}
          </Text>
          <Text className="mt-1" variant="light" size="sm">
            Below is the commentary you have given to this envelope across all submissions.
          </Text>
        </div>
      </TableHeader>
      <Table className="w-full" columns={columns} isLoading={isLoading} />
    </div>
  )
}
