import { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { clamp, map, sortBy } from 'lodash'
import { AsyncInput } from '@cotiss/common/components/async-input.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  evaluationSubCriteria: GqlEvaluationCriteriaFieldsFragment[]
  criteriaWeightById: Record<string, number>
  weightedPercentageById: Record<string, number>
  overallWeightedPercentageById: Record<string, number>
  setCriteriaWeightById: (criteriaWeightById: Record<string, number>) => void
  isEditable?: boolean
}

export const EvaluationEventSubCriteriaWeightList = memo((props: Props) => {
  const {
    className,
    evaluationEnvelope,
    parentEvaluationCriteriaItem,
    evaluationSubCriteria,
    weightedPercentageById,
    overallWeightedPercentageById,
    isEditable,
  } = props
  const { track } = useEvaluationEventAnalytics()
  const { mutateUpdateEvaluationCriteria } = useEvaluationCriteria()
  const [subCriteriaWeightById, setSubCriteriaWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationSubCriteria })
  )
  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const subCriteriaWeightPercentageById = useMemo(() => {
    const totalSubCriteriaWeight = evaluationEventService.getTotalWeight({ weightById: subCriteriaWeightById })

    return evaluationEventService.getWeightedPercentageById({ weightById: subCriteriaWeightById, totalWeight: totalSubCriteriaWeight })
  }, [subCriteriaWeightById])

  const handleUpdateWeight = async (evaluationCriteriaId: string) => {
    track('evaluation_event_wizard_sub_weight_criteria_update_submit')

    const clampedWeight = clamp(Number(subCriteriaWeightById[evaluationCriteriaId]) || 0, 1, 100)
    setSubCriteriaWeightById({ ...subCriteriaWeightById, [evaluationCriteriaId]: clampedWeight })

    await mutateUpdateEvaluationCriteria({ evaluationCriteriaId, weight: clampedWeight })
  }

  const { columns } = useMemo(() => {
    const sortedSubCriteria = sortBy(evaluationSubCriteria, 'index')

    const primaryColumns: TableColumn[] = [
      {
        heading: 'Sub-criteria',
        rows: [
          ...map(sortedSubCriteria, ({ content, index }) => ({
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">
                  {parentEvaluationCriteriaItem.index}.{index}.
                </Text>
                <Text>{content}</Text>
              </div>
            ),
          })),
          ...(isWeightedEnvelope
            ? [
                {
                  content: () => <Text className="font-medium">Totals</Text>,
                },
              ]
            : []),
        ],
      },
    ]

    const unweightedEnvelopeColumns: TableColumn[] = [
      {
        heading: <></>,
        thClassName: 'w-1/2',
        rows: [
          ...map(sortedSubCriteria, () => ({
            content: () => <Text variant="light">Evaluators can provide commentary against this unweighted sub-criteria.</Text>,
          })),
        ],
      },
    ]

    const weightedEnvelopeColumns: TableColumn[] = [
      {
        heading: 'Value (1 - 100)',
        thClassName: 'w-32',
        rows: [
          ...map(sortedSubCriteria, ({ id, weight }) => ({
            content: () =>
              isEditable ? (
                <div className="relative w-20">
                  <AsyncInput
                    value={subCriteriaWeightById[id]}
                    onChange={({ target }) => setSubCriteriaWeightById({ ...subCriteriaWeightById, [id]: Number(target.value) || 0 })}
                    onSubmit={() => handleUpdateWeight(id)}
                    onFocus={({ target }) => target.select()}
                    min={0}
                    max={100}
                    placeholder="--"
                    isDisabled={sortedSubCriteria.length < 2}
                    rightSlot={
                      sortedSubCriteria.length < 2 && (
                        <Tooltip tooltip="You cannot edit the weight when you only have 1 criteria">
                          <Icon icon="lock" variant="light" />
                        </Tooltip>
                      )
                    }
                  />
                </div>
              ) : (
                <Text className="text-right">{weight}</Text>
              ),
          })),
          {
            content: () => <></>,
          },
        ],
      },
      {
        heading: (
          <div className="flex items-center justify-end gap-2">
            Weight
            <Tooltip tooltip="Sub-criteria value / total sub-criteria value">
              <Icon icon="info-circle" size={14} variant="light" />
            </Tooltip>
          </div>
        ),
        thClassName: 'text-right w-40',
        rows: [
          ...map(sortedSubCriteria, ({ id }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>{utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100) || '--'}</Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({subCriteriaWeightById[id]}/{evaluationEventService.getTotalWeight({ weightById: subCriteriaWeightById })})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                100.00%
              </Text>
            ),
          },
        ],
      },
      {
        heading: (
          <div className="flex items-center justify-end gap-2">
            Envelope weight
            <Tooltip tooltip="Sub-criteria% x criteria% ">
              <Icon icon="info-circle" size={14} variant="light" />
            </Tooltip>
          </div>
        ),
        thClassName: 'text-right w-40',
        rows: [
          ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>
                  {utilService.formatAsPercentage(
                    Number(subCriteriaWeightPercentageById[id]) * Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100
                  ) || '--'}
                </Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
              </Text>
            ),
          },
        ],
      },
      {
        heading: (
          <div className="flex items-center justify-end gap-2">
            Overall weight
            <Tooltip tooltip="Sub-criteria% x criteria% x envelope%">
              <Icon icon="info-circle" size={14} variant="light" />
            </Tooltip>
          </div>
        ),
        thClassName: 'text-right w-44',
        rows: [
          ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>
                  {utilService.formatAsPercentage(
                    Number(subCriteriaWeightPercentageById[id]) *
                      Number(weightedPercentageById[parentEvaluationCriteriaId as string]) *
                      Number(weightedPercentageById[evaluationEnvelope.id]) *
                      100
                  ) || '--'}
                </Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[evaluationEnvelope.id]) * 100, 0)})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                {utilService.formatAsPercentage(Number(overallWeightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
              </Text>
            ),
          },
        ],
      },
    ]

    return { columns: [...primaryColumns, ...(isWeightedEnvelope ? weightedEnvelopeColumns : unweightedEnvelopeColumns)] }
  }, [evaluationSubCriteria, subCriteriaWeightPercentageById, subCriteriaWeightById, weightedPercentageById, isEditable])

  return <Table className={className} columns={columns} />
})
