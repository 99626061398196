import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Filter, FilterFieldOptions } from '@cotiss/common/models/filter.model'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { queryService } from '@cotiss/common/services/query.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { ContractShellFilterPopulatedModel } from '@cotiss/contract/contract.model'
import { FilterContractShellSortKey } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export type FilterContractShellParam = {
  currentPage: number
  pageSize: number
  sort?: FilterContractShellSortKey
  order: 'asc' | 'desc'
  filters?: Filter[]
  searchQuery?: string
}

export function useFilterContractShells(params: FilterContractShellParam) {
  const { openToast } = useToast()
  const { currentPage = 1, pageSize = 20, sort, order, filters, searchQuery } = params
  const { limit, offset } = paginationService.convertPageSizeToOffset({ currentPage, pageSize })
  const queryRoute = `/v2/contract-shells/filter${utilService.generateUrlSearchParams({ limit, offset, sort, order: order === 'asc' ? 1 : -1 })}`

  const { data, isLoading, error } = useQuery({
    queryKey: ['/v2/contract-shells', 'filter-contract-shells', currentPage, pageSize, sort, order, JSON.stringify(filters), searchQuery],
    queryFn: async ({ signal }) =>
      queryService.mutate<{ filters: Filter[]; searchQuery?: string }, PaginatedModel<ContractShellFilterPopulatedModel, FilterFieldOptions>>(
        queryRoute,
        {
          signal: signal,
          method: 'POST',
          body: { filters: filters ?? [], searchQuery },
        }
      ),
    staleTime: 0,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (error instanceof Error) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [error])

  return {
    contractShells: data?.items ?? [],
    pagination: data
      ? {
          currentPage,
          pageSize,
          pageCount: Math.ceil(data.count / pageSize),
          itemCount: data.items?.length,
          totalCount: data.count,
        }
      : undefined,
    meta: data?.meta,
    isLoading,
  }
}
