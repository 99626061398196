import { RemirrorRenderer } from '@remirror/react'
import { replace } from 'lodash'
import { renderToString } from 'react-dom/server'
import { RemirrorJSON } from 'remirror'

class RichTextService {
  getEmptyRemirrorJson = (): RemirrorJSON => ({
    type: 'doc',
    content: [],
  })

  isEmpty = (value?: RemirrorJSON) => !value || !replace(renderToString(<RemirrorRenderer json={value} />), /<[^>]+>/g, '')

  AddEmptyLines = (value: RemirrorJSON): RemirrorJSON => ({
    ...value,
    content: (value.content ?? []).map((c) => (c.type === 'paragraph' && !c.content ? { type: 'hardBreak' } : c)),
  })
}

export const richTextService = new RichTextService()
