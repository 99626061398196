import { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationCriteriaRatingScale, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { filter, includes, orderBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventRatingScaleDefinitionList } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-definition-list.component'
import {
  EVALUATION_EVENT_RATING_SCALE_OPTIONS,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
} from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'

type FormData = {
  content: string
  ratingScale: GqlEvaluationCriteriaRatingScale
  supplementary: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  evaluationSubCriteriaItem?: GqlEvaluationCriteriaFieldsFragment
  isEditable?: boolean
}

const SUB_CRITERIA_NAME_MAX_LENGTH = 200

export const EvaluationEventSubCriteriaRatingScaleDrawer = memo((props: Props) => {
  const { evaluationEnvelope, parentEvaluationCriteriaItem, evaluationSubCriteriaItem, isEditable } = props
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationCriteria, mutateUpdateEvaluationCriteria, mutateCreateEvaluationCriteria } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    content: evaluationSubCriteriaItem?.content || '',
    ratingScale: evaluationSubCriteriaItem?.ratingScale || evaluationEnvelope.defaultRatingScale,
    supplementary: evaluationSubCriteriaItem?.supplementary || '',
  })

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const isWeightedParentCriteria = parentEvaluationCriteriaItem?.weight !== 0

  useEffect(() => {
    setFormData({
      content: evaluationSubCriteriaItem?.content || '',
      ratingScale: evaluationSubCriteriaItem?.ratingScale || evaluationEnvelope.defaultRatingScale,
      supplementary: evaluationSubCriteriaItem?.supplementary || '',
    })
  }, [evaluationSubCriteriaItem, evaluationEnvelope])

  useEffect(() => {
    track(evaluationSubCriteriaItem ? 'evaluation_event_wizard_sub_criteria_update_view' : 'evaluation_event_wizard_sub_criteria_create_view')
  }, [])

  const handleSubmit = async () => {
    try {
      setIsSaving(true)

      if (evaluationSubCriteriaItem) {
        track('evaluation_event_wizard_sub_criteria_update_submit')
        await mutateUpdateEvaluationCriteria({
          evaluationCriteriaId: evaluationSubCriteriaItem.id,
          content: formData.content,
          ratingScale: formData.ratingScale,
          supplementary: formData.supplementary,
        })
      }

      if (!evaluationSubCriteriaItem) {
        // Get the next index of the criteria within the envelope.
        const subCriteria = filter(evaluationCriteria, { parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id })
        const index = (orderBy(subCriteria, 'index', 'desc')[0]?.index || 0) + 1

        track('evaluation_event_wizard_sub_criteria_create_submit')
        await mutateCreateEvaluationCriteria({
          parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id,
          evaluationEnvelopeId: evaluationEnvelope.id,
          content: formData.content,
          weight: isWeightedEnvelope ? 1 : 0,
          ratingScale: formData.ratingScale,
          supplementary: formData.supplementary,
          index,
          isScored: true,
        })
      }
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      {evaluationSubCriteriaItem ? 'Edit sub-criteria details' : 'Add sub-criteria'}
    </Text>
  )

  const renderFooter = () => {
    if (!isEditable) {
      return null
    }

    return (
      <>
        <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
          Confirm
        </Button>
        <Button onClick={() => closeDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <div className="grid grid-cols-3 gap-6 items-start">
        <div className="col-span-3">
          <Label className="flex items-baseline">
            Sub-criteria name
            <Text size="sm" variant="light" className="ml-1">{`(${formData.content.length}/${SUB_CRITERIA_NAME_MAX_LENGTH})`}</Text>
          </Label>
          <TextArea
            value={formData.content}
            onChange={({ target }) => setFormData({ ...formData, content: target.value })}
            maxLength={SUB_CRITERIA_NAME_MAX_LENGTH}
            rows={3}
            isDisabled={isSaving || !isEditable}
            isRequired
            autoFocus
          />
          <Text className="mt-1" size="sm" variant="light">
            Max {SUB_CRITERIA_NAME_MAX_LENGTH} characters.
          </Text>
        </div>

        <div className="col-span-3">
          <Label>
            Evaluation requirements{' '}
            <Text isInline variant="light">
              (optional)
            </Text>{' '}
          </Label>
          <Text variant="light" size="sm">
            Add custom rating scale definitions or any additional evaluation information.
          </Text>
          <TextArea
            className="mt-2"
            value={formData.supplementary}
            onChange={({ target }) => setFormData({ ...formData, supplementary: target.value })}
            rows={5}
            isDisabled={isSaving || !isEditable}
          />
        </div>

        {isWeightedParentCriteria ? (
          <>
            <div className="col-span-1">
              <Label>Rating scale</Label>
            </div>
            <div className="col-span-2">
              <Select_DEPRECATED<GqlEvaluationCriteriaRatingScale>
                className="w-full"
                value={formData.ratingScale}
                options={EVALUATION_EVENT_RATING_SCALE_OPTIONS}
                onChange={(ratingScale) => setFormData({ ...formData, ratingScale })}
                isDisabled={isSaving || !isEditable}
                placeholder
                isRequired
                isFull={false}
              />
            </div>
            {includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, formData.ratingScale) && (
              <div className="col-span-3">
                <EvaluationEventRatingScaleDefinitionList className="mt-4" ratingScale={formData.ratingScale} />
              </div>
            )}
          </>
        ) : null}
      </div>
    </Drawer>
  )
})
