import { gql } from '@apollo/client'

export const EVALUATION_ENVELOPE_FRAGMENT = gql`
  fragment EvaluationEnvelopeFields on EvaluationEnvelope {
    id
    evaluationEventId
    supplementary
    name
    weight
    order
    defaultRatingScale
    status
    createdAt
    updatedAt
    deletedAt
  }
`
