import { memo } from 'react'
import { map } from 'lodash'
import { EvaluationEventEvaluatorAccessList } from '@cotiss/evaluation-event/components/evaluation-event-evaluator-access-list.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'

export const EvaluationEventSummaryEvaluatorAccess = memo(() => {
  const { evaluationEnvelopes } = useEvaluationEnvelope()

  return (
    <div className="flex flex-col gap-8">
      {map(evaluationEnvelopes, (evaluationEnvelope) => (
        <EvaluationEventEvaluatorAccessList key={evaluationEnvelope.id} evaluationEnvelope={evaluationEnvelope} />
      ))}
    </div>
  )
})
