import { memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { find, map, sortBy } from 'lodash'
import { Table, TableColumn, TableRow } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { EvaluationEventCriteriaCreateUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-criteria-create-update.modal'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { EvaluationEventWeightInput } from '@cotiss/evaluation-event/components/evaluation-event-weight-input.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { Icon } from '@cotiss/common/components/icon.component'

type Props = {
  parentCriteria: GqlEvaluationCriteriaFieldsFragment[]
  evaluationEnvelopes: GqlEvaluationEnvelopeFieldsFragment[]
  emptyCta: ReactNode
  isLoading?: boolean
  className?: string
  envelopeId: string
  onDeleteCriteria: (evaluationCriteriaId: string) => Promise<void>
  onUpdateCriteria: (evaluationCriteriaId: string, weight: number) => Promise<void>
  isWeightedMethodology: boolean
}

const totalsRowCell = (hasCriteria: boolean, content: TableRow): TableRow[] => (hasCriteria ? [content] : [])

export const EvaluationEventParentCriteriaWeightListTable = memo(
  ({
    parentCriteria,
    evaluationEnvelopes,
    envelopeId,
    emptyCta,
    isWeightedMethodology,
    isLoading,
    className,
    onDeleteCriteria,
    onUpdateCriteria,
  }: Props) => {
    const { openModal } = useCallout()

    const envelopeWeightPercentageById = useMemo(() => {
      return evaluationEventService.getSingleLevelWeightPercentages({ items: evaluationEnvelopes })
    }, [evaluationEnvelopes])

    const [parentCriteriaWeightById, setParentCriteriaWeightById] = useState<Record<string, number>>(
      evaluationEventService.getWeightById({ items: parentCriteria })
    )
    const parentCriteriaWeightPercentageById = useMemo(() => {
      const totalParentCriteriaWeight = evaluationEventService.getTotalWeight({ weightById: parentCriteriaWeightById })

      return evaluationEventService.getWeightedPercentageById({ weightById: parentCriteriaWeightById, totalWeight: totalParentCriteriaWeight })
    }, [parentCriteriaWeightById])

    useEffect(() => {
      setParentCriteriaWeightById(evaluationEventService.getWeightById({ items: parentCriteria }))
    }, [parentCriteria])

    const isWeightedEnvelope = find(evaluationEnvelopes, { id: envelopeId })?.weight !== 0
    // We need to consider the evaluation event methodology here as there is a bug where an envelope can be weighted while the methodology is simpleScore
    const isWeighted = isWeightedMethodology && isWeightedEnvelope
    const hasCriteria = parentCriteria.length > 0
    const shouldShowTotalsRow = hasCriteria && isWeighted

    const { columns } = useMemo(() => {
      const sortedParentCriteria = sortBy(parentCriteria, 'index')

      const primaryColumns: TableColumn[] = [
        {
          heading: 'Criteria',
          thClassName: 'w-1/2',
          rows: [
            ...map(sortedParentCriteria, (evaluationCriteriaItem) => ({
              content: () => (
                <Text>
                  {evaluationCriteriaItem.index}. {evaluationCriteriaItem.content}
                </Text>
              ),
              cta: (
                <TableRowCta
                  actions={[
                    {
                      label: 'Edit',
                      onClick: () =>
                        openModal(
                          <EvaluationEventCriteriaCreateUpdateModal
                            evaluationEnvelopeId={envelopeId}
                            evaluationCriteriaItem={evaluationCriteriaItem}
                          />
                        ),
                    },
                    {
                      label: 'Delete',
                      onClick: () =>
                        openModal(
                          <ConfirmModal
                            heading="Delete criteria"
                            description="Are you sure you want to delete this criteria?"
                            onSubmit={async () => await onDeleteCriteria(evaluationCriteriaItem.id)}
                          />
                        ),
                    },
                  ]}
                />
              ),
            })),
            ...totalsRowCell(shouldShowTotalsRow, {
              content: () => <Text className="font-medium">Totals</Text>,
            }),
          ],
        },
      ]

      const weightedEnvelopeColumns: TableColumn[] = [
        {
          heading: 'Value (1 - 100)',
          thClassName: 'w-1/6',
          rows: [
            ...map(sortedParentCriteria, ({ id }) => ({
              content: () => (
                <EvaluationEventWeightInput
                  onSubmit={async (weight) => await onUpdateCriteria(id, weight)}
                  onChange={(value: number) => setParentCriteriaWeightById({ ...parentCriteriaWeightById, [id]: value })}
                  value={parentCriteriaWeightById[id]}
                  isDisabled={sortedParentCriteria.length < 2}
                  disabledTooltip="You cannot edit the weight when you only have 1 criteria."
                  min={1}
                  max={100}
                />
              ),
            })),
            ...totalsRowCell(shouldShowTotalsRow, {
              content: () => <></>,
            }),
          ],
        },
        {
          heading: (
            <div className="flex items-center justify-end gap-2">
              Envelope weight
              <Tooltip tooltip="Criteria value / total criteria value">
                <Icon icon="info-circle" size={14} variant="light" />
              </Tooltip>
            </div>
          ),
          thClassName: 'text-right w-1/6',
          rows: [
            ...map(sortedParentCriteria, ({ id }) => ({
              tdClassName: 'text-right',
              content: () => (
                <>
                  <Text className="font-medium">{utilService.formatAsPercentage(Number(parentCriteriaWeightPercentageById[id]) * 100)}</Text>
                  <Text className="mt-1" variant="light" size="sm">
                    ({parentCriteriaWeightById[id] || 0}/{evaluationEventService.getTotalWeight({ weightById: parentCriteriaWeightById })})
                  </Text>
                </>
              ),
            })),
            ...totalsRowCell(shouldShowTotalsRow, {
              tdClassName: 'text-right',
              content: () => (
                <Text className="font-medium" variant="secondary">
                  100.00%
                </Text>
              ),
            }),
          ],
        },
        {
          heading: (
            <div className="flex items-center justify-end gap-2">
              Overall weight
              <Tooltip tooltip="Criteria% x envelope%">
                <Icon icon="info-circle" size={14} variant="light" />
              </Tooltip>
            </div>
          ),
          thClassName: 'text-right w-1/6',
          rows: [
            ...map(sortedParentCriteria, ({ id, evaluationEnvelopeId }) => ({
              tdClassName: 'text-right',
              content: () => (
                <>
                  <Text className="font-medium">
                    {parentCriteriaWeightPercentageById[id] && envelopeWeightPercentageById[evaluationEnvelopeId]
                      ? utilService.formatAsPercentage(
                          Number(parentCriteriaWeightPercentageById[id]) * Number(envelopeWeightPercentageById[evaluationEnvelopeId]) * 100
                        )
                      : '--'}
                  </Text>
                  <Text className="mt-1" variant="light" size="sm">
                    ({utilService.formatAsPercentage(Number(parentCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                    {utilService.formatAsPercentage(Number(envelopeWeightPercentageById[evaluationEnvelopeId]) * 100, 0)})
                  </Text>
                </>
              ),
            })),
            ...totalsRowCell(shouldShowTotalsRow, {
              tdClassName: 'text-right',
              content: () => (
                <Text className="font-medium" variant="secondary">
                  {utilService.formatAsPercentage(Number(envelopeWeightPercentageById[envelopeId]) * 100)}
                </Text>
              ),
            }),
          ],
        },
      ]

      const columns = [...primaryColumns, ...(isWeighted ? weightedEnvelopeColumns : [])]

      return { columns }
    }, [parentCriteriaWeightPercentageById, parentCriteriaWeightById, parentCriteria, envelopeWeightPercentageById])

    return <Table className={className} columns={columns} isLoading={isLoading} emptyCta={emptyCta} />
  }
)
