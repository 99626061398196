import { includes } from 'lodash'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { DOCUMENT_QUERY_KEYS } from '@cotiss/document/document.constants'
import { SUPPLIER_QUERY_KEYS } from '@cotiss/supplier/supplier.constants'
import { METAFIELD_QUERY_KEYS } from '@cotiss/metafield/metafield.constants'
import { EVALUATION_QUERY_KEYS } from '@cotiss/evaluation/evaluation.constants'
import { PRICE_ITEM_QUERY_KEYS } from '@cotiss/price-item/price-item.constants'
import { SHORT_LIST_QUERY_KEYS } from '@cotiss/short-list/short-list.constants'
import { PREFERENCES_QUERY_KEYS } from '@cotiss/preferences/preferences.constants'
import { PROCUREMENT_QUERY_KEYS } from '@cotiss/procurement/procurement.constants'
import { NOTIFICATION_QUERY_KEY } from '@cotiss/notification/notification.constants'
import { ORGANISATION_QUERY_KEYS } from '@cotiss/organisation/organisation.constants'
import { TENDER_UPDATE_QUERY_KEYS } from '@cotiss/tender-update/tender-update.constants'
import { ACCESS_CONTROL_QUERY_KEYS } from '@cotiss/access-control/access-control.constants'
import { ACCOUNT_QUERY_KEYS } from '@cotiss/account/account.constants'
import { APPROVAL_QUERY_KEYS } from '@cotiss/approval/approval.constants'
import { CORRESPONDENCE_QUERY_KEYS } from '@cotiss/correspondence/correspondence.constants'
import { TENDER_RESPONSE_QUERY_KEYS } from '@cotiss/tender-response/tender-response.constants'
import { TENDER_QUESTION_QUERY_KEYS } from '@cotiss/tender-question/tender-question.constants'
import { METAFIELD_VALUE_QUERY_KEYS } from '@cotiss/metafield-value/metafield-value.constants'
import { CHAIR_EVALUATION_QUERY_KEYS } from '@cotiss/chair-evaluation/chair-evaluation.constants'
import { GROUP_EVALUATION_QUERY_KEYS } from '@cotiss/group-evaluation/group-evaluation.constants'
import { TENDER_INVITATION_QUERY_KEYS } from '@cotiss/tender-invitation/tender-invitation.constants'
import { AUTH_QUERY_KEYS } from '@cotiss/auth/auth.constants'
import { authResource } from '@cotiss/auth/auth.resource'
import { authService } from '@cotiss/auth/auth.service'
import { PREFERRED_SUPPLIER_QUERY_KEYS } from '@cotiss/preferred-supplier/preferred-supplier.constants'
import { PRICE_ITEM_RESPONSE_QUERY_KEYS } from '@cotiss/price-item-response/price-item-response.constants'
import { PROCUREMENT_RESPONSE_QUERY_KEYS } from '@cotiss/procurement-response/procurement-response.constants'
import { CONFLICT_OF_INTEREST_QUERY_KEYS } from '@cotiss/conflict-of-interest/conflict-of-interest.constants'
import { TENDER_MANDATORY_CRITERIA_QUERY_KEYS } from '@cotiss/tender-mandatory-criteria/tender-mandatory-criteria.constants'
import { CONFLICT_OF_INTEREST_TEMPLATE_ROUTES } from '@cotiss/conflict-of-interest-template/conflict-of-interest-template.constants'
import { CONTRACT_QUERY_KEYS } from '@cotiss/contract/contract.constants'
import { FORUM_QUERY_KEYS } from '@cotiss/forum/forum.constants'
import { PROJECT_QUERY_KEY } from '@cotiss/project/project.constants'
import { RECOMMENDATION_QUERY_KEYS } from '@cotiss/recommendation/recommendation.constants'
import { TENDER_QUERY_KEYS } from '@cotiss/tender/tender.constants'
import { TENDER_CRITERIA_QUERY_KEYS } from '@cotiss/tender-criteria/tender-criteria.constants'
import { USER_QUERY_KEYS } from '@cotiss/user/user.constants'

export type QueryKey = (typeof QUERY_KEYS)[number]
const QUERY_KEYS = [
  ...ACCESS_CONTROL_QUERY_KEYS,
  ...ACCOUNT_QUERY_KEYS,
  ...APPROVAL_QUERY_KEYS,
  ...AUTH_QUERY_KEYS,
  ...CHAIR_EVALUATION_QUERY_KEYS,
  ...CONFLICT_OF_INTEREST_QUERY_KEYS,
  ...CONFLICT_OF_INTEREST_TEMPLATE_ROUTES,
  ...CONTRACT_QUERY_KEYS,
  ...CORRESPONDENCE_QUERY_KEYS,
  ...DOCUMENT_QUERY_KEYS,
  ...EVALUATION_QUERY_KEYS,
  ...FORUM_QUERY_KEYS,
  ...GROUP_EVALUATION_QUERY_KEYS,
  ...METAFIELD_QUERY_KEYS,
  ...METAFIELD_VALUE_QUERY_KEYS,
  ...NOTIFICATION_QUERY_KEY,
  ...ORGANISATION_QUERY_KEYS,
  ...PREFERENCES_QUERY_KEYS,
  ...PREFERRED_SUPPLIER_QUERY_KEYS,
  ...PRICE_ITEM_QUERY_KEYS,
  ...PRICE_ITEM_RESPONSE_QUERY_KEYS,
  ...PROCUREMENT_QUERY_KEYS,
  ...PROCUREMENT_RESPONSE_QUERY_KEYS,
  ...PROJECT_QUERY_KEY,
  ...RECOMMENDATION_QUERY_KEYS,
  ...SHORT_LIST_QUERY_KEYS,
  ...SUPPLIER_QUERY_KEYS,
  ...TENDER_CRITERIA_QUERY_KEYS,
  ...TENDER_INVITATION_QUERY_KEYS,
  ...TENDER_MANDATORY_CRITERIA_QUERY_KEYS,
  ...TENDER_QUERY_KEYS,
  ...TENDER_QUESTION_QUERY_KEYS,
  ...TENDER_RESPONSE_QUERY_KEYS,
  ...TENDER_UPDATE_QUERY_KEYS,
  ...USER_QUERY_KEYS,
] as const

export type PaginationParam = {
  limit?: number
  offset?: number
  sort?: string
  order?: number
}

export type MutateType = 'json' | 'form-data'

type MutateFnOptionsParam<B> = {
  body?: B
  method?: 'POST' | 'DELETE' | 'PUT'
  type?: MutateType
  signal?: AbortSignal
}

type GetHeadersParam = {
  type?: MutateType
}

type HandleFetchErrorParam = {
  response: Response
  route: string
  retryAttempts: number
}

type HandleFetchUnauthorisedParam = {
  route: string
  retryAttempts: number
}

class QueryService {
  query = async <T>({ route, retryAttempts = 0 }: { route: string; retryAttempts?: number }) => {
    const response = await fetch(`${import.meta.env.VITE_API_DOMAIN}${route}`, {
      headers: this.getHeaders(),
    })

    if (!response.ok) {
      await this.handleFetchError<T>({ response, route, retryAttempts })
    }

    return (await response.json()) as T
  }

  mutate = async <B, R = void>(route: string, { body, method = 'POST', type = 'json', signal }: MutateFnOptionsParam<B>) => {
    const response = await fetch(`${import.meta.env.VITE_API_DOMAIN}${route}`, {
      method,
      body: type === 'json' ? JSON.stringify(body) : (body as FormData),
      headers: this.getHeaders({ type }),
      signal,
    })

    if (!response.ok) {
      const errorResponse = await response.json()

      throw new Error(errorResponse.message)
    }

    if (response.status !== 204) {
      // We have to wrap this in a try/catch, because there are some instances where the response is empty even though the status is not 204. These
      // are rare cases, and when they do happen, we don't expect anything to be returned.
      try {
        const contentType = response.headers.get('Content-Type')

        // If the response is a file, then we don't want to parse it as JSON.
        // Return the response body as is.
        if (includes([contentType], 'application/octet-stream')) {
          return response.body as R
        }

        return (await response.json()) as R
      } catch {
        return undefined as R
      }
    }
  }

  getHeaders = ({ type = 'json' }: GetHeadersParam = {}) => {
    const headers: Record<string, string> = {}

    if (type === 'json') {
      headers['Content-Type'] = 'application/json'
    }

    const accessToken = localStorageService.getItem('access-token')

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`
    }

    return headers
  }

  handleFetchError = async <T>({ response, route, retryAttempts }: HandleFetchErrorParam) => {
    // If we encounter a 401 error, attempt to refresh the token, and retry the query. If this fails a second time, then we need to redirect the
    // user to login.
    if (response.status === 401) {
      return await this.handleFetchUnauthorised<T>({ route, retryAttempts })
    }

    const errorResponse = await response.json()

    throw new Error(errorResponse.message)
  }

  handleFetchUnauthorised = async <T>({ route, retryAttempts }: HandleFetchUnauthorisedParam) => {
    const refreshToken = localStorageService.getItem('refresh-token')

    if (refreshToken && authService.isTokenValid(refreshToken)) {
      const newSession = await authResource.refreshToken(refreshToken)

      if (newSession && authService.isTokenValid(newSession.accessToken) && authService.isTokenValid(newSession.refreshToken)) {
        localStorageService.setItem('access-token', newSession.accessToken)
        localStorageService.setItem('refresh-token', newSession.refreshToken)

        return await this.query({ route, retryAttempts })
      }
    }

    window.location.href = '/login'

    return undefined as T
  }
}

export const queryService = new QueryService()
