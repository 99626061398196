import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreateEvaluationInput,
  GqlEvaluationFieldsFragment,
  GqlEvaluationListInput,
  GqlEvaluationOverviewFieldsFragment,
  GqlEvaluationViewInput,
  GqlUpdateEvaluationInput,
} from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreateEvaluation } from '@cotiss/evaluation-event/graphql/evaluation/mutate-create-evaluation.graphql'
import { mutateUpdateEvaluation } from '@cotiss/evaluation-event/graphql/evaluation/mutate-update-evaluation.graphql'
import { queryEvaluationList } from '@cotiss/evaluation-event/graphql/evaluation/query-evaluation-list.graphql'
import { queryEvaluationOverviewList } from '@cotiss/evaluation-event/graphql/evaluation/query-evaluation-overview-list.graphql'
import { queryEvaluationOverviewView } from '@cotiss/evaluation-event/graphql/evaluation/query-evaluation-overview-view.graphql'
import { queryEvaluationView } from '@cotiss/evaluation-event/graphql/evaluation/query-evaluation-view.graphql'

const evaluationVar = makeVar<GqlEvaluationFieldsFragment | null>(null)
const evaluationsVar = makeVar<GqlEvaluationFieldsFragment[]>([])
const evaluationOverviewVar = makeVar<GqlEvaluationOverviewFieldsFragment | null>(null)
const evaluationOverviewsVar = makeVar<GqlEvaluationOverviewFieldsFragment[]>([])

export const useEvaluation = () => {
  const evaluation = useReactiveVar(evaluationVar)
  const evaluations = useReactiveVar(evaluationsVar)
  const evaluationOverview = useReactiveVar(evaluationOverviewVar)
  const evaluationOverviews = useReactiveVar(evaluationOverviewsVar)

  return {
    evaluation,
    evaluations,
    evaluationOverview,
    evaluationOverviews,
    setEvaluation: evaluationVar,
    setEvaluations: evaluationsVar,
    setEvaluationOverview: evaluationOverviewVar,
    setEvaluationOverviews: evaluationOverviewsVar,
    queryEvaluationList: async (input: GqlEvaluationListInput) => {
      const { items: evaluations, pagination } = await queryEvaluationList(input)

      evaluationsVar(evaluations)

      return { evaluations, pagination }
    },
    queryEvaluationView: async (input: GqlEvaluationViewInput) => {
      const evaluation = await queryEvaluationView(input)

      evaluationVar(evaluation)
      evaluationsVar(uniqBy([evaluation, ...evaluations], 'id'))

      return evaluation
    },
    queryEvaluationOverviewList: async (input: GqlEvaluationListInput) => {
      const { items: evaluationOverviews, pagination } = await queryEvaluationOverviewList(input)

      evaluationOverviewsVar(evaluationOverviews)

      return { evaluationOverviews, pagination }
    },
    queryEvaluationOverviewView: async (input: GqlEvaluationViewInput) => {
      const evaluationOverview = await queryEvaluationOverviewView(input)

      evaluationOverviewVar(evaluationOverview)
      evaluationOverviewsVar(uniqBy([evaluationOverview, ...evaluationOverviews], 'evaluationId'))

      return evaluationOverview
    },

    mutateCreateEvaluation: async (input: GqlCreateEvaluationInput) => {
      const createdEvaluation = await mutateCreateEvaluation(input)

      evaluationVar(createdEvaluation)
      evaluationsVar(uniqBy([createdEvaluation, ...evaluations], 'id'))

      return createdEvaluation
    },
    mutateUpdateEvaluation: async (input: GqlUpdateEvaluationInput) => {
      const updatedEvaluation = await mutateUpdateEvaluation(input)

      evaluationVar(updatedEvaluation)
      evaluationsVar(evaluations.map((evaluation) => (evaluation.id === updatedEvaluation.id ? updatedEvaluation : evaluation)))

      return updatedEvaluation
    },
  }
}

export const clearReactiveEvaluation = async () => {
  evaluationVar(null)
  evaluationsVar([])
  evaluationOverviewVar(null)
  evaluationOverviewsVar([])
}
