import { memo, useMemo, useState } from 'react'
import { filter, flatMap, groupBy, some, uniqBy } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventEnvelopeList } from '@cotiss/evaluation-event/components/evaluation-event-envelope-list.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

type Props = {
  hasParallelRecommendation?: boolean
  isEditable?: boolean
  isSortable?: boolean
}

export const EvaluationEventSummaryEnvelopes = memo(({ hasParallelRecommendation, isEditable, isSortable }: Props) => {
  const { openToast } = useToast()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent, mutateUpdateEvaluationEvent } = useEvaluationEvent()
  const areOverlappingPanelMembers = useMemo(() => {
    const envelopeAccessControls = flatMap(evaluationUsers, ({ accessControls }) => {
      return uniqBy(
        filter(accessControls, ({ resourceType }) => resourceType === 'envelope'),
        'resourceId'
      )
    })

    return some(groupBy(envelopeAccessControls, 'evaluationUserId'), (accessControls) => Boolean(accessControls.length > 1))
  }, [evaluationUsers])

  const handleChangeIsParallel = async (isParallel: boolean) => {
    if (!evaluationEvent) {
      return
    }

    track(isParallel ? 'evaluation_event_wizard_envelopes_is_parallel_submit' : 'evaluation_event_wizard_envelopes_is_sequential_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEvent({ evaluationEventId: evaluationEvent.id, isParallel })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderBanner = () => {
    if (!hasParallelRecommendation || (evaluationEnvelopes.length || 0) <= 1) {
      return null
    }

    const variant = areOverlappingPanelMembers ? (evaluationEvent?.isParallel ? 'danger' : 'light') : evaluationEvent?.isParallel ? 'light' : 'danger'

    return (
      <Banner
        className="mb-6"
        icon={variant === 'danger' ? 'alert-circle' : 'star-06'}
        variant={variant}
        iconVariant={variant === 'danger' ? 'danger' : 'secondary'}
      >
        <div>
          <Text className="font-semibold" variant="heading">
            Cotiss recommendation
          </Text>
          <Text size="sm">
            {areOverlappingPanelMembers
              ? 'We noticed some evaluation panel members are assigned to both envelopes. Therefore we recommend setting the evaluation sequence to unlock sequentially.'
              : 'We noticed none of your evaluation panel members are assigned to both envelopes. Therefore we recommend setting the evaluation sequence to unlock at the same time'}
          </Text>
        </div>
      </Banner>
    )
  }

  return (
    <>
      {renderBanner()}
      {(evaluationEnvelopes.length || 0) > 1 && (
        <>
          {isSortable && (
            <Field label="Select the sequence of envelope activation">
              <div className="grid grid-cols-2 grid-rows-1 gap-4">
                <RadioCard
                  className="border border-gray-100 p-3.5"
                  name="evaluation-event-envelopes"
                  isSelected={!evaluationEvent?.isParallel}
                  onChange={() => handleChangeIsParallel(false)}
                  isDisabled={isSaving}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium">Sequential</Text>
                    <Radio className="ml-2" isChecked={!evaluationEvent?.isParallel} />
                  </div>
                  <Text className="mt-1" size="sm" variant="light">
                    Once all evaluation and moderation is completed for the first envelope the second envelope will be open for evaluation
                  </Text>
                </RadioCard>
                <RadioCard
                  className="border border-gray-100 p-3.5"
                  name="evaluation-event-envelopes"
                  isSelected={evaluationEvent?.isParallel}
                  onChange={() => handleChangeIsParallel(true)}
                  isDisabled={isSaving}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium">Parallel</Text>
                    <Radio className="ml-2" isChecked={evaluationEvent?.isParallel} />
                  </div>
                  <Text className="mt-1" size="sm" variant="light">
                    Both envelopes will be able to be viewed by their respective evaluation panels at the same time
                  </Text>
                </RadioCard>
              </div>
            </Field>
          )}
          {!isSortable && (
            <Field label="Envelope activation sequence">
              <Text>
                {evaluationEvent?.isParallel && 'Envelopes unlocked at the same time'}
                {!evaluationEvent?.isParallel && 'Envelopes unlocked sequentially'}
              </Text>
            </Field>
          )}
          <Hr className="my-8" />
          {isSortable && (evaluationEnvelopes.length || 0) > 1 && (
            <Text className="font-medium mb-4">Drag and drop to order which envelope opens first and assign their weight.</Text>
          )}
        </>
      )}
      <EvaluationEventEnvelopeList isEditable={isEditable} isSortable={isSortable && !evaluationEvent?.isParallel} />
    </>
  )
})
