import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  className?: string
  resetErrorBoundary?: () => void
}

export const ErrorBoundaryFallback = ({ className, resetErrorBoundary }: Props) => {
  const { track } = useAnalytics()

  const location = useLocation()
  const errorLocation = useRef(location.pathname)
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary?.()
    }
  }, [location.pathname])

  useEffect(() => {
    track('error_page_view')
  }, [track])

  function handleRetry() {
    track('error_page_retry')
    window.location.reload()
  }

  return (
    <div className={classNames(className, 'flex flex-col justify-center items-center h-full')}>
      <ErrorPanel onRetry={handleRetry} />
    </div>
  )
}
