import React, { memo, forwardRef } from 'react'
import { ProcessedUnspscSubcategory } from '@cotiss/supplier/supplier.models'
import { Text } from '@cotiss/common/components/text.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { FixedSizeList } from 'react-window'

type SubCategoryRowData = {
  items: ProcessedUnspscSubcategory[]
  onSubcategoryChange: (subcategory: ProcessedUnspscSubcategory, isSelected: boolean) => void
  isSaving: boolean
  stateCategories: ProcessedUnspscSubcategory[]
}

type SubCategoryRowProps = {
  data: SubCategoryRowData
  index: number
  style: React.CSSProperties
}

type InnerProps = {
  style: React.CSSProperties
  children?: React.ReactNode
}

const PADDING_SIZE = 16

const Inner = forwardRef<HTMLDivElement, InnerProps>(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      paddingTop: PADDING_SIZE,
      paddingBottom: PADDING_SIZE,
    }}
    {...rest}
  />
))

const SubCategoryRow = memo(({ data, index, style }: SubCategoryRowProps) => {
  const { items, onSubcategoryChange, isSaving, stateCategories } = data
  const subcategory = items[index]
  const id = `cotiss-unspsc-category-${subcategory.code}`
  const { code, name } = subcategory
  const isSelected = Boolean(stateCategories.find((category: { code: string; name: string }) => category.code === code))
  return (
    <div style={{ ...style, paddingBottom: 2, top: `${parseFloat(String(style.top)) + 16}px` }}>
      <label className="flex items-center justify-between bg-gray-50 text-xs cursor-pointer w-full p-2.5" htmlFor={id}>
        <Text className="mr-2 truncate" title={name}>
          {name}
        </Text>
        <Text className="flex items-center text-light">
          {code}
          <Checkbox
            id={id}
            className="ml-2"
            isChecked={isSelected}
            onChange={() => onSubcategoryChange(subcategory, !isSelected)}
            isDisabled={isSaving}
          />
        </Text>
      </label>
    </div>
  )
})

export const UnspscCategoriesVirtualizedSubcategoriesList = ({ items, onSubcategoryChange, isSaving, stateCategories }: SubCategoryRowData) => (
  <FixedSizeList
    height={384}
    width="100%"
    itemCount={items.length}
    itemSize={42}
    innerElementType={Inner}
    itemData={{
      items,
      onSubcategoryChange,
      isSaving,
      stateCategories,
    }}
  >
    {SubCategoryRow}
  </FixedSizeList>
)
