import { memo, useMemo, useState } from 'react'
import { GqlEvaluationSubmissionFieldsFragment, GqlPagination } from '@gql'
import { compact, find, forEach, map, sortBy, uniq } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { EvaluationEventEvaluationStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-status-badge.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { Table } from '@cotiss/common/components/table.component'

type Props = {
  isInitialised?: boolean
}

export const EvaluationEventEvaluateEnvelopeUserEvaluationTab = memo(({ isInitialised }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { evaluations, evaluationOverviews, queryEvaluationList, queryEvaluationOverviewList, mutateCreateEvaluation } = useEvaluation()
  const { evaluationEventId, evaluationEnvelopeId, evaluationUserId } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationUserId: string
  }>()

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const hasCriteria = evaluationCriteria.length > 0
  const shouldShowTableWithCriteria = isWeightedEnvelope && hasCriteria

  useAsyncEffect(async () => {
    if (!isInitialised) {
      return
    }

    try {
      setIsLoading(true)
      const { evaluationSubmissions, pagination } = await queryEvaluationSubmissionList({
        filter: { evaluationEventId },
        pagination: { page: currentPage, pageSize: 20 },
      })

      const evaluationSubmissionIds = compact(uniq(map(evaluationSubmissions, 'id')))
      await Promise.all([
        queryEvaluationList({ filter: { evaluationEventId, evaluationEnvelopeId, evaluationUserId, evaluationSubmissionIds } }),
        queryEvaluationOverviewList({ filter: { evaluationEventId, evaluationEnvelopeId, evaluationUserId, evaluationSubmissionIds } }),
      ])
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [isInitialised, currentPage])

  const handleCreateEvaluation = async (evaluationSubmission: GqlEvaluationSubmissionFieldsFragment) => {
    try {
      setIsSaving(true)
      const evaluation = await mutateCreateEvaluation({
        evaluationEventId,
        evaluationUserId,
        evaluationEnvelopeId,
        evaluationSubmissionId: evaluationSubmission.id,
      })

      push(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId', {
          evaluationEventId,
          evaluationId: evaluation.id,
        })
      )
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderSubmissionCell = (evaluationSubmission: GqlEvaluationSubmissionFieldsFragment) => {
    const evaluation = find(evaluations, { evaluationSubmissionId: evaluationSubmission.id })
    return {
      content: () => (
        <Text className="font-medium truncate" title={evaluationSubmission.organisation?.name || evaluationSubmission.name}>
          {evaluationSubmission.name || evaluationSubmission.organisation?.name}
        </Text>
      ),
      cta: (
        <TableRowCta
          cta={{
            isDisabled: isSaving,
            label: (
              <>
                View <Icon className="ml-2" icon="arrow-right" />
              </>
            ),
            // If an evaluation exists, we can navigate to the evaluation view page. Otherwise, we need to create a new evaluation.
            href: evaluation
              ? routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId', {
                  evaluationEventId,
                  evaluationId: evaluation.id,
                })
              : undefined,
            onClick: !evaluation ? async () => handleCreateEvaluation(evaluationSubmission) : undefined,
          }}
        />
      ),
    }
  }

  const renderStatusCell = (evaluationSubmission: GqlEvaluationSubmissionFieldsFragment) => {
    return {
      content: () => <EvaluationEventEvaluationStatusBadge status={find(evaluations, { evaluationSubmissionId: evaluationSubmission.id })?.status} />,
    }
  }

  const renderTotalCell = (evaluationSubmission: GqlEvaluationSubmissionFieldsFragment) => {
    const evaluation = find(evaluations, { evaluationSubmissionId: evaluationSubmission.id })
    const evaluationOverview = evaluation && find(evaluationOverviews, { evaluationId: evaluation.id })

    return {
      content: () => (
        <Text>
          {evaluationOverview?.totalEnvelopeWeightedPercentageScore
            ? utilService.formatAsPercentage(Number(evaluationOverview?.totalEnvelopeWeightedPercentageScore) * 100)
            : '--'}
        </Text>
      ),
    }
  }

  // If the evaluation is weighted and has criteria we need to show the full criteria breakdown table.
  // If the envelope is not weighted, we show a simple table with only the submission name and status.
  // If the envelope is weighted but has no criteria, we show a simple table with the submission name, status and weighted total.

  const simpleTableColumns = useMemo(() => {
    return [
      {
        heading: 'Submission',
        rows: map(evaluationSubmissions, (evaluationSubmission) => renderSubmissionCell(evaluationSubmission)),
      },
      {
        heading: 'Status',
        rows: map(evaluationSubmissions, (evaluationSubmission) => renderStatusCell(evaluationSubmission)),
      },
      ...(isWeightedEnvelope
        ? [
            {
              heading: 'Weighted total',
              rows: map(evaluationSubmissions, (evaluationSubmission) => renderTotalCell(evaluationSubmission)),
            },
          ]
        : []),
    ]
  }, [evaluationEvent, evaluationEnvelope, evaluationCriteria, evaluations, evaluationOverviews, isLoading])

  const criteriaTableColumns = useMemo(() => {
    const weightById = evaluationEventService.getWeightById({ items: evaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const weightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Criteria',
        colSpan: 3,
        rows: [
          {
            colSpan: 3,
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Weight
              </Text>
            ),
          },
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Submission
              </Text>
            ),
          },
          ...map(evaluationSubmissions, (evaluationSubmission) => {
            return renderSubmissionCell(evaluationSubmission)
          }),
        ],
      },
      {
        rows: [
          // Empty cells to get colSpan of 3 above working as expected.
          {},
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Status
              </Text>
            ),
          },
          ...map(evaluationSubmissions, (evaluationSubmission) => renderStatusCell(evaluationSubmission)),
        ],
      },
      {
        rows: [
          // Empty cells to get colSpan of 3 above working as expected.
          {},
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Weighted total
              </Text>
            ),
          },
          ...map(evaluationSubmissions, (evaluationSubmission) => renderTotalCell(evaluationSubmission)),
        ],
      },
    ]

    const columns: ScrollableTableColumn[] = []

    forEach(sortBy(evaluationCriteria, 'index'), ({ id: evaluationCriteriaId, content, index }, i) => {
      columns.push({
        heading: (
          <>
            {isLoading && <Skeleton className="h-2 w-full" />}
            {!isLoading && (
              <Text className="normal-case">
                {index}. {content}
              </Text>
            )}
          </>
        ),
        rows: [
          {
            hasHover: false,
            content: () => (
              <Text size="sm" variant="light">
                {utilService.formatAsPercentage(Number(weightPercentageById[evaluationCriteriaId]) * 100)}
              </Text>
            ),
          },
          !i
            ? {
                variant: 'primary',
                colSpan: evaluationCriteria.length,
                hasHover: false,
                content: () => (
                  <Text className="uppercase" size="xs" variant="light">
                    Weighted score
                  </Text>
                ),
              }
            : {},
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
            const evaluation = find(evaluations, { evaluationSubmissionId })
            const evaluationOverview = evaluation && find(evaluationOverviews, { evaluationId: evaluation.id })
            const criteriaBreakdown = evaluationOverview && find(evaluationOverview.criteriaBreakdown, { evaluationCriteriaId })

            return {
              content: () => (
                <Text>
                  {(criteriaBreakdown?.isScored || criteriaBreakdown?.completedScoredSubCriteriaCount) && criteriaBreakdown.rawScoreSummary
                    ? utilService.formatAsPercentage(criteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)
                    : '--'}
                </Text>
              ),
            }
          }),
        ],
      })
    })

    return { fixedColumns, columns }
  }, [evaluationEvent, evaluationEnvelope, evaluationCriteria, evaluations, evaluationOverviews, isLoading])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  if (!isLoading && !evaluationEvent) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader variant="white">
        {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
        {!isLoading && evaluationEnvelope && (
          <>
            <Text variant="light" size="sm">
              Envelope {evaluationEnvelope.order}.
            </Text>
            <Text className="font-medium" variant="heading" size="h7">
              {evaluationEnvelope.name}
            </Text>
          </>
        )}
      </TableHeader>
      {shouldShowTableWithCriteria ? (
        <ScrollableTable
          fixedColumns={criteriaTableColumns.fixedColumns}
          fixedColumnsWidth={600}
          columns={criteriaTableColumns.columns}
          pagination={pagination}
          onPageChange={setCurrentPage}
          isLoading={isLoading}
        />
      ) : (
        <Table columns={simpleTableColumns} isLoading={isLoading} pagination={pagination} onPageChange={setCurrentPage} />
      )}
    </>
  )
})
