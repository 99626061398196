import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Button } from '@cotiss/common/components/button.component'
import { EVALUATION_EVENT_RATING_SCALE_NAME_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { EvaluationEventEnvelopeRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-envelope-rating-scale.drawer'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Icon } from '@cotiss/common/components/icon.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  hasCriteria: boolean
}

export const EvaluationEventRatingScaleSelector = ({ evaluationEnvelope, hasCriteria }: Props) => {
  const { openDrawer } = useCallout()
  return (
    <div className="flex items-center justify-start gap-2">
      <div className="flex items-center gap-1">
        <Tooltip tooltip="Set a default rating scale for all criteria and sub-criteria within this envelope. The default will not apply to individually updated scales.">
          <Icon icon="info-circle" size={14} variant="light" />
        </Tooltip>
        <Text variant="light">Default rating scale:</Text>
      </div>
      <Button
        className="flex-1 rounded-md group hover:bg-primary-50 p-2"
        state="text"
        onClick={() =>
          openDrawer(<EvaluationEventEnvelopeRatingScaleDrawer evaluationEnvelope={evaluationEnvelope} isEditable hasCriteria={hasCriteria} />)
        }
      >
        <div className="flex items-center justify-between gap-3 group-hover:text-secondary-500">
          <Text className="max-w-md flex group-hover:text-secondary-500 whitespace-pre-wrap">
            {EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationEnvelope.defaultRatingScale]}
          </Text>
          <Icon icon="edit" variant="light" />
        </div>
      </Button>
    </div>
  )
}
