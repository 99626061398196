import { memo, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { some } from 'lodash'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { EvaluationEventEvaluatorAccessListTable } from '@cotiss/evaluation-event/components/evaluation-event-evaluator-access-list-table.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { EvaluationEventEnvelopeWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weighted-title'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isEditable?: boolean
}

export const EvaluationEventEvaluatorAccessList = memo(({ className, evaluationEnvelope, isEditable }: Props) => {
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent } = useEvaluationEvent()
  const envelopeWeightPercentageById = useMemo(() => {
    return evaluationEventService.getSingleLevelWeightPercentages({ items: evaluationEnvelopes })
  }, [evaluationEnvelopes])

  const hasCriteria = some(evaluationCriteria, (criteria) => criteria.evaluationEnvelopeId === evaluationEnvelope.id)

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'

  return (
    <div>
      <TableHeader className={className}>
        <div className="p-1">
          <Text size="lg" className="font-medium">
            <EvaluationEventEnvelopeWeightedTitle
              relativeWeight={envelopeWeightPercentageById[evaluationEnvelope.id]}
              order={evaluationEnvelope.order}
              name={evaluationEnvelope.name}
              shouldShowWeight={isWeightedMethodology}
            />
          </Text>
        </div>
      </TableHeader>
      <EvaluationEventEvaluatorAccessListTable isEditable={isEditable} evaluationEnvelope={evaluationEnvelope} hasCriteria={hasCriteria} />
    </div>
  )
})
