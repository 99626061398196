import { memo, useMemo, useState } from 'react'
import {
  GqlEvaluationCriteriaFieldsFragment,
  GqlEvaluationCriteriaRatingScale,
  GqlEvaluationEnvelopeOverviewCriteriaBreakdownFieldsFragment,
} from '@gql'
import classNames from 'classnames'
import { find, map, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { utilService } from '@cotiss/common/services/util.service'
import { EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { motion, AnimatePresence } from 'framer-motion'

type OnSelectParam = {
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  activeEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

type RenderAverageSubCriteriaScoreParam = {
  criteriaBreakdown: GqlEvaluationEnvelopeOverviewCriteriaBreakdownFieldsFragment
  ratingScale: GqlEvaluationCriteriaRatingScale
}

type Props = {
  className?: string
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  activeEvaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment | null
  parentCriteriaWeightPercentageById: Record<string, number>
  onSelect: (param: OnSelectParam) => void
  onToggleExpand: (parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => void
  isExpanded?: boolean
}

export const EvaluationEventModerationParentCriteriaItem = memo((props: Props) => {
  const {
    className,
    parentEvaluationCriteriaItem,
    activeEvaluationCriteriaItem,
    parentCriteriaWeightPercentageById,
    onSelect,
    onToggleExpand,
    isExpanded,
  } = props
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(false)
  const { evaluationSubmission } = useEvaluationSubmission()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const { evaluationEnvelope, evaluationEnvelopeOverview } = useEvaluationEnvelope()
  const [subEvaluationCriteria, setSubEvaluationCriteria] = useState<GqlEvaluationCriteriaFieldsFragment[]>([])
  const parentButtonClasses = classNames('flex items-center justify-between shrink-0 rounded w-full p-2 pr-0.5', {
    'bg-primary-100': parentEvaluationCriteriaItem.id !== activeEvaluationCriteriaItem?.id,
    'bg-secondary-100': parentEvaluationCriteriaItem.id === activeEvaluationCriteriaItem?.id,
  })

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const isParentActive = parentEvaluationCriteriaItem.id === activeEvaluationCriteriaItem?.id

  const { criteriaBreakdown, criteriaWeightPercentageById } = useMemo(() => {
    const weightById = evaluationEventService.getWeightById({ items: subEvaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const criteriaWeightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    const criteriaBreakdown = find(evaluationEnvelopeOverview?.criteriaBreakdown, {
      evaluationCriteriaId: parentEvaluationCriteriaItem.id,
      evaluationSubmissionId: evaluationSubmission?.id,
    })

    return { criteriaBreakdown, criteriaWeightPercentageById }
  }, [parentEvaluationCriteriaItem, evaluationEnvelopeOverview, evaluationSubmission, subEvaluationCriteria])

  useAsyncEffect(async () => {
    if (!isExpanded || !evaluationEvent || !evaluationEnvelope || !evaluationSubmission) {
      return
    }

    setIsLoading(true)
    const { evaluationCriteria } = await queryEvaluationCriteriaList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id,
      },
    })
    const sortedEvaluationCriteria = sortBy(evaluationCriteria, 'index')

    setSubEvaluationCriteria(sortedEvaluationCriteria)

    sortedEvaluationCriteria.length
      ? onSelect({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem: sortedEvaluationCriteria[0] })
      : onSelect({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem: parentEvaluationCriteriaItem })

    setIsLoading(false)
  }, [isExpanded, evaluationEvent, evaluationEnvelope, evaluationSubmission])

  const renderBreakdownDelta = (criteriaBreakdown?: GqlEvaluationEnvelopeOverviewCriteriaBreakdownFieldsFragment) => {
    if (!criteriaBreakdown || !criteriaBreakdown.moderatedScoreSummary || !criteriaBreakdown.rawScoreSummary) {
      return null
    }

    const delta =
      criteriaBreakdown.moderatedScoreSummary.averageWeightedPercentageScore - criteriaBreakdown.rawScoreSummary.averageWeightedPercentageScore

    return Boolean(delta) && <Icon icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
  }

  const renderAverageSubCriteriaScore = ({ criteriaBreakdown, ratingScale }: RenderAverageSubCriteriaScoreParam) => {
    if (!criteriaBreakdown.moderatedScoreSummary) {
      return null
    }
    if (ratingScale === 'percentage') {
      return utilService.formatAsPercentage(criteriaBreakdown.moderatedScoreSummary.averageScore)
    }

    return `${criteriaBreakdown.moderatedScoreSummary.averageScore.toFixed(1)}/${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[ratingScale]}`
  }

  const handleParentClick = () => {
    if (parentEvaluationCriteriaItem.isScored) {
      onSelect({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem: parentEvaluationCriteriaItem })
    } else {
      onToggleExpand(parentEvaluationCriteriaItem)
    }
  }

  return (
    <div className={className}>
      <Button className={parentButtonClasses} onClick={handleParentClick} state="raw">
        <div className="flex items-center justify-between w-full h-8">
          <div className="flex items-center ml-2">
            {!parentEvaluationCriteriaItem.isScored && <Icon className="mr-1" icon={isExpanded ? 'chevron-down' : 'chevron-up'} />}
            <Text className="line-clamp-2" size="sm" variant={isParentActive ? 'secondary' : 'dark'}>
              {parentEvaluationCriteriaItem.index}. {parentEvaluationCriteriaItem.content}
            </Text>
          </div>

          {isWeightedEnvelope && criteriaBreakdown && (
            <div className="flex items-center justify-end">
              <div className="flex items-center justify-end gap-2">
                <div className="text-right w-14">
                  <Text className="uppercase" size="xs" variant="light">
                    Weight
                  </Text>
                  <Text className="shrink-0" size="sm" variant={isParentActive ? 'secondary' : 'dark'}>
                    {utilService.formatAsPercentage(parentCriteriaWeightPercentageById[parentEvaluationCriteriaItem.id] * 100)}
                  </Text>
                </div>
                <div className="text-right w-14">
                  <Text className="uppercase" size="xs" variant="light">
                    Avg
                  </Text>
                  <Text className="shrink-0" size="sm" variant={isParentActive ? 'secondary' : 'dark'}>
                    {criteriaBreakdown.moderatedScoreSummary
                      ? utilService.formatAsPercentage(criteriaBreakdown.moderatedScoreSummary.averageWeightedPercentageScore * 100)
                      : '--'}
                  </Text>
                </div>
              </div>
              <Text className="w-5 ml-1">{renderBreakdownDelta(criteriaBreakdown)}</Text>
            </div>
          )}
        </div>
      </Button>
      {isExpanded && isLoading && (
        <>
          {map(Array(3), (_, index) => (
            <div key={index} className="flex item-center justify-between w-full h-6 mt-2">
              {map(Array(3), (_, index) => (
                <div key={index} className={classNames('flex items-center w-full px-2', { 'border-l': index })}>
                  <Skeleton className="w-full h-2" />
                </div>
              ))}
            </div>
          ))}
        </>
      )}
      {isExpanded && !isLoading && (
        <AnimatePresence>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            {map(subEvaluationCriteria, (evaluationCriteriaItem) => {
              // TODO: Move this to it's own component?
              const isActive = evaluationCriteriaItem.id === activeEvaluationCriteriaItem?.id
              const subButtonClasses = classNames('flex item-center justify-between rounded w-full p-2 pr-0.5 mt-2', { 'bg-secondary-100': isActive })
              const subCriteriaBreakdown = find(evaluationEnvelopeOverview?.criteriaBreakdown, {
                evaluationCriteriaId: evaluationCriteriaItem.id,
                evaluationSubmissionId: evaluationSubmission?.id,
              })

              return (
                <Button
                  key={evaluationCriteriaItem.id}
                  className={subButtonClasses}
                  onClick={() => onSelect({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem: evaluationCriteriaItem })}
                  state="raw"
                >
                  <div className="flex items-center justify-between w-full h-8">
                    <Text size="sm" variant={isActive ? 'secondary' : 'dark'} className="ml-2">
                      SUB. {evaluationCriteriaItem.index}
                    </Text>
                    {isWeightedEnvelope && (
                      <div className="flex items-center justify-end">
                        <div className="flex items-center justify-end gap-2">
                          <div className="text-right w-14">
                            <Text className="uppercase" size="xs" variant="light">
                              Weight
                            </Text>
                            <Text size="sm" variant={isActive ? 'secondary' : 'dark'}>
                              {utilService.formatAsPercentage(criteriaWeightPercentageById[evaluationCriteriaItem.id] * 100)}
                            </Text>
                          </div>

                          <div className="text-right w-14">
                            <Text className="uppercase" size="xs" variant="light">
                              Avg
                            </Text>
                            <Text className="shrink-0" size="sm" variant={isActive ? 'secondary' : 'dark'}>
                              {subCriteriaBreakdown ? (
                                <>
                                  {renderAverageSubCriteriaScore({
                                    criteriaBreakdown: subCriteriaBreakdown,
                                    ratingScale: evaluationCriteriaItem.ratingScale,
                                  })}
                                </>
                              ) : (
                                '--'
                              )}
                            </Text>
                          </div>
                        </div>
                        <Text className="w-5 ml-1">{renderBreakdownDelta(subCriteriaBreakdown)}</Text>
                      </div>
                    )}
                  </div>
                </Button>
              )
            })}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
})
