import { memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { every, map, some, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { EvaluationEventCriteriaCreateUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-criteria-create-update.modal'
import { EvaluationEventEnvelopeWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weighted-title'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { EvaluationEventParentCriteriaWeightList } from '@cotiss/evaluation-event/components/evaluation-event-parent-criteria-weight-list.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardCriteriaStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const isNoCriteriaEvaluationEnabled = useFeature('enable-no-criteria-evaluation')
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEnvelopes, queryEvaluationEnvelopeList } = useEvaluationEnvelope()
  const sortedEvaluationEnvelopes = sortBy(evaluationEnvelopes, 'order')
  const { evaluationCriteria, queryEvaluationCriteriaList } = useEvaluationCriteria()

  const envelopeWeightPercentageById = useMemo(() => {
    return evaluationEventService.getSingleLevelWeightPercentages({ items: evaluationEnvelopes })
  }, [evaluationEnvelopes])

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_criteria_view')

    try {
      await queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
      await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [])

  const handleClickContinue = () => {
    if (isNoCriteriaEvaluationEnabled) {
      onNext()
      return
    }
    if (!every(evaluationEnvelopes, ({ id: evaluationEnvelopeId }) => some(evaluationCriteria, { evaluationEnvelopeId }))) {
      setErrorText('Every envelope must have at least one criteria.')
      return
    }

    onNext()
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <div>
          <Text className="font-semibold" variant="heading" size="h5">
            Criteria
          </Text>
          {!isNoCriteriaEvaluationEnabled && (
            <Text size="sm" variant="light">
              Every envelope must have at least 1 criteria
            </Text>
          )}
        </div>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading}>
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm" isDisabled={isLoading}>
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      {map(sortedEvaluationEnvelopes, (envelope) => {
        return (
          <div key={envelope.id} className={classNames({ 'mt-6': envelope.order > 1 })}>
            <TableHeader className="flex justify-between items-center gap-2">
              <div className="p-1">
                <Text size="lg" className="font-medium">
                  <EvaluationEventEnvelopeWeightedTitle
                    relativeWeight={envelopeWeightPercentageById[envelope.id]}
                    order={envelope.order}
                    name={envelope.name}
                    shouldShowWeight={isWeightedMethodology}
                  />
                </Text>
                <Text className="mt-1" size="sm" variant="light">
                  Add criteria to evaluate aspects of the tender
                </Text>
              </div>
              <Button
                variant="secondary-dark"
                state="translucent"
                size="xs"
                onClick={() => openModal(<EvaluationEventCriteriaCreateUpdateModal evaluationEnvelopeId={envelope.id} />)}
              >
                + Add criteria
              </Button>
            </TableHeader>
            <EvaluationEventParentCriteriaWeightList envelopeId={envelope.id} isLoading={isLoading} />
          </div>
        )
      })}
    </Card>
  )
})
