import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEffect, useMemo, useState } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { EvaluationEventWeightInput } from '@cotiss/evaluation-event/components/evaluation-event-weight-input.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'

type Props = {
  envelopeId: string
  evaluationEnvelopes: GqlEvaluationEnvelopeFieldsFragment[]
  isEditable: boolean
  onSubmit: (envelopeId: string, weight: number) => Promise<void>
}

export const EvaluationEventEnvelopeListItemWeight = ({ envelopeId, isEditable, onSubmit, evaluationEnvelopes }: Props) => {
  const [envelopeWeightById, setEnvelopeWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationEnvelopes })
  )
  const envelopeWeightPercentageById = useMemo(() => {
    const totalEnvelopeWeight = evaluationEventService.getTotalWeight({ weightById: envelopeWeightById })

    return evaluationEventService.getWeightedPercentageById({ weightById: envelopeWeightById, totalWeight: totalEnvelopeWeight })
  }, [envelopeWeightById])

  useEffect(() => {
    setEnvelopeWeightById(evaluationEventService.getWeightById({ items: evaluationEnvelopes }))
  }, [evaluationEnvelopes])

  const isUnweightedEvaluationEnvelopeEnabled = useFeature('enable-unweighted-evaluation-envelope')

  const staticWeightPercentage = (
    <div className="text-right w-16">
      <Text variant="secondary">{utilService.formatAsPercentage(Number(envelopeWeightPercentageById[envelopeId]) * 100)}</Text>
    </div>
  )

  if (!isEditable) {
    return <div className="flex items-center justify-end">{staticWeightPercentage}</div>
  }

  return (
    <div className="flex items-center justify-end">
      <VerticalDivider className="h-[72px] " />
      <div className="w-[240px] flex items-center justify-between gap-4 px-6">
        <Text className="uppercase" variant="light" size="sm">
          Value ({isUnweightedEvaluationEnvelopeEnabled ? 0 : 1} - 100)
        </Text>
        <EvaluationEventWeightInput
          onSubmit={async (value: number) => await onSubmit(envelopeId, value)}
          onChange={(value: number) => setEnvelopeWeightById({ ...envelopeWeightById, [envelopeId]: value })}
          value={envelopeWeightById[envelopeId]}
          isDisabled={evaluationEnvelopes.length < 2}
          disabledTooltip="You cannot edit the weight when you only have 1 envelope."
          min={isUnweightedEvaluationEnvelopeEnabled ? 0 : 1}
          max={100}
        />
      </div>
      <VerticalDivider className="h-[72px]" />
      <div className="w-[240px] flex items-center justify-between gap-4 px-6">
        <Text className="uppercase" variant="light" size="sm">
          Weight ({envelopeWeightById[envelopeId]}/{evaluationEventService.getTotalWeight({ weightById: envelopeWeightById })})
        </Text>
        {staticWeightPercentage}
      </div>
    </div>
  )
}
