import { gql } from '@apollo/client'

export const EVALUATION_EVENT_FRAGMENT = gql`
  fragment EvaluationEventFields on EvaluationEvent {
    id
    name
    organisationId
    organisation {
      ...OrganisationFields
    }
    methodology
    status
    isParallel
    isSetupComplete
    isArchived
    createdAt
    updatedAt
    deletedAt
    envelopes {
      id
      name
      order
      status
    }
    owners {
      id
      userId
      firstName
      lastName
      role
    }
  }
`
