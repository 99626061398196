import { find, forEach, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { useGetPreferredSupplier } from '@cotiss/preferred-supplier/resources/use-get-preferred-supplier.resource'
import { PerformanceScorecardCreateFormData } from '@cotiss/performance/drawers/performance-scorecard-create.drawer'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { metafieldValueService } from '@cotiss/metafield-value/metafield-value.service'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { ContractVariationStatusBadge } from '@cotiss/contract/components/contract-variation-status-badge.component'
import { FilterContractShellSortKey } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { utilService } from '@cotiss/common/services/util.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { memo, useMemo, useState } from 'react'
import { useFilterContractShells } from '@cotiss/contract/resources/use-filter-contract-shells.resource'
import { Filter } from '@cotiss/common/models/filter.model'

type Props = {
  formData: PerformanceScorecardCreateFormData
  setFormData: (formData: PerformanceScorecardCreateFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardCreateContractShellStep = memo(({ formData, setFormData, onBack, isDisabled }: Props) => {
  const { user } = useGetLoggedInUser()
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })
  const { preferredSupplier, isLoading: isPreferredSupplierLoading } = useGetPreferredSupplier(formData.contactId)
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
  })

  const baseFilters: Filter[] = [
    {
      field: 'archived',
      operation: 'IS_FALSE',
      value: true,
    },
    {
      field: 'supplier',
      operation: 'IS_ANY_OF',
      value: [formData.supplierId],
    },
    {
      field: 'status',
      operation: 'IS_NONE_OF',
      value: ['DRAFTING'],
    },
  ]

  const {
    contractShells,
    pagination,
    isLoading: isLoadingContractShells,
  } = useFilterContractShells({
    filters: baseFilters,
    currentPage,
    pageSize: 20,
    sort: sortKey,
    order: sortDirection,
  })

  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
  })

  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues || isPreferredSupplierLoading

  const preferredSupplierName = preferredSupplier?.supplierOrganisation?.name || preferredSupplier?.organisationName || ''
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: formData.name,
      onClick: () => {
        setFormData({ ...formData, contractShellId: '' })
        onBack()
      },
    },
    {
      label: preferredSupplierName,
      onClick: () => {
        setFormData({ ...formData, contractShellId: '' })
        onBack()
      },
      isLoading,
    },
    {
      label: 'Select contract',
    },
  ]

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(contractShells, (contractShell) => ({
          content: () => (
            <Radio
              value={contractShell._id}
              name="performance-scorecard-contract-shell"
              onChange={() => setFormData({ ...formData, contractShellId: contractShell._id })}
              isChecked={contractShell._id === formData.contractShellId}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(contractShells, ({ title }) => ({
          content: () => (
            <Text className="truncate" title={title}>
              {title}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Owners',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <UserAvatarGroup users={contracts.metadata.owners} />,
        })),
      },
      {
        heading: 'Variation status',
        rows: map(contractShells, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Total exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          contractShells,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          contractShells,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Next expiry',
        rows: map(contractShells, ({ contracts: { priceDurations } }) => ({
          content: () => {
            const nextExpiry = contractService.getNextExpirationDate(priceDurations)
            return (
              <Text size="sm" variant="light">
                {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
              </Text>
            )
          },
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(contractShells, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(contractShells, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract ID',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.externalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    forEach(metafields, (metafield) => {
      columns.push({
        heading: metafield.fieldLabel,
        rows: map(contractShells, ({ contracts: { _id: contractId } }) => ({
          tdClassName: 'max-w-[350px]',
          content: () => {
            const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
            const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

            if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
              return (
                <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                  {processedMetafieldValue}
                </Button>
              )
            }

            return (
              <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                {processedMetafieldValue}
              </Text>
            )
          },
        })),
      })
    })

    return { fixedColumns, columns }
  }, [formData.contractShellId, contractShells, user, metafields, metafieldValues, isDisabled])

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Select contract linked to this vendor
          </Text>
          <Text size="sm">Select an option below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 3 of 3
        </Badge>
      </Banner>
      <div className="flex items-start mt-8">
        <OrganisationLogo className="mr-4" organisation={preferredSupplier?.supplierOrganisation} />
        <div className="flex items-start">
          {preferredSupplierName && (
            <Text className="font-medium" size="h4">
              {preferredSupplierName}
            </Text>
          )}
          {!preferredSupplierName && <Skeleton className="w-2/3 h-3.5" variant="gray" />}
          {preferredSupplier?.supplierOrganisation?.claimedStatus && (
            <Badge
              className="ml-2"
              variant={preferredSupplier?.supplierOrganisation?.claimedStatus === 'CLAIMED' ? 'success' : 'neutral'}
              state="outline"
            >
              {organisationService.getClaimedStatusText(preferredSupplier?.supplierOrganisation?.claimedStatus)}
            </Badge>
          )}
        </div>
      </div>

      <Hr className="my-4" />
      {(isLoading || Boolean(contractShells.length)) && (
        <>
          <TableHeader>
            <Text className="font-medium" size="lg">
              Select contract
            </Text>
          </TableHeader>
          <ScrollableTable
            fixedColumns={fixedColumns}
            columns={columns}
            pagination={pagination}
            onPageChange={setCurrentPage}
            isLoading={isLoading}
          />
        </>
      )}
      {!isLoading && !contractShells.length && <NoDataPlaceholder label="Vendor has no associated contracts." />}
    </>
  )
})
