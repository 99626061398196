import { memo, ReactNode } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Button } from '@cotiss/common/components/button.component'
import { DraggableListItem } from '@cotiss/common/components/draggable-list-item.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventEnvelopeUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-envelope-update.modal'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isActive?: boolean
  isInOverlay?: boolean
  isEditable?: boolean
  isSortable?: boolean
  isDisabled?: boolean
  children?: ReactNode
}

export const EvaluationEventEnvelopeListItem = memo((props: Props) => {
  const { evaluationEnvelope, isEditable, isDisabled, children, ...restProps } = props
  const { openModal } = useCallout()

  return (
    <DraggableListItem id={evaluationEnvelope.id} isDisabled={isDisabled} {...restProps}>
      <div className="relative flex items-center justify-between w-full gap-4 px-4 h-[72px]">
        <div className="flex items-center justify-start shrink-0 gap-4">
          <Text className="truncate">{evaluationEnvelope.name}</Text>
          {isEditable && (
            <Button
              className="invisible group-hover:visible"
              onClick={() => openModal(<EvaluationEventEnvelopeUpdateModal evaluationEnvelope={evaluationEnvelope} />)}
              state="outline"
              variant="secondary"
              size="xs"
              isDisabled={isDisabled}
            >
              Edit
            </Button>
          )}
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </DraggableListItem>
  )
})
