import { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaRatingScale, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { includes } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventRatingScaleDefinitionList } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-definition-list.component'
import {
  EVALUATION_EVENT_RATING_SCALE_OPTIONS,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
} from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { TextArea } from '@cotiss/common/components/text-area.component'

type FormData = {
  defaultRatingScale: GqlEvaluationCriteriaRatingScale
  supplementary: string | undefined
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  hasCriteria: boolean
  isEditable?: boolean
}

export const EvaluationEventEnvelopeRatingScaleDrawer = memo(({ evaluationEnvelope, hasCriteria, isEditable }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()

  const { queryEvaluationEnvelopeView, mutateUpdateEvaluationEnvelope } = useEvaluationEnvelope()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    defaultRatingScale: evaluationEnvelope.defaultRatingScale,
    supplementary: evaluationEnvelope.supplementary ?? undefined,
  })

  const isWeightedEnvelope = evaluationEnvelope.weight !== 0

  const isEditingDefaultRatingScaleOnly = hasCriteria && isWeightedEnvelope

  useEffect(() => {
    setFormData({
      defaultRatingScale: evaluationEnvelope.defaultRatingScale,
      supplementary: evaluationEnvelope.supplementary ?? undefined,
    })
  }, [evaluationEnvelope])

  useEffect(() => {
    track('evaluation_event_wizard_envelope_edit_rating_scale_view')
  }, [])

  const handleSubmit = async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelope_edit_rating_scale_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEnvelope({
        evaluationEnvelopeId: evaluationEnvelope.id,
        defaultRatingScale: formData.defaultRatingScale,
        supplementary: formData.supplementary,
        isScored: true,
      })

      // Changing the default rating scale will affect the criteria rating scale, so we need to refresh both.
      await Promise.all([
        queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } }),
        queryEvaluationEnvelopeView({ evaluationEnvelopeId: evaluationEnvelope.id }),
      ])
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      {isEditingDefaultRatingScaleOnly ? 'Set default rating scale' : 'Edit envelope details'}
    </Text>
  )

  const renderFooter = () => {
    if (!isEditable) {
      return null
    }
    return (
      <>
        <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
          Confirm
        </Button>
        <Button onClick={() => closeDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <div className="grid grid-cols-3 gap-6 items-start">
        {!hasCriteria ? (
          <div className="col-span-3">
            <Label>
              Evaluation requirements{' '}
              <Text isInline variant="light">
                (optional)
              </Text>{' '}
            </Label>
            <Text variant="light" size="sm">
              Add custom rating scale definitions or any additional evaluation information.
            </Text>
            <TextArea
              className="mt-2"
              value={formData.supplementary ?? ''}
              onChange={({ target }) => setFormData({ ...formData, supplementary: target.value })}
              rows={5}
              isDisabled={isSaving || !isEditable}
            />
          </div>
        ) : null}
        {isWeightedEnvelope ? (
          <>
            <div className="col-span-1">
              <Label>Select {hasCriteria ? 'default ' : ''}rating scale</Label>
              {isEditingDefaultRatingScaleOnly ? (
                <Text variant="light" size="sm">
                  Set a default rating scale for all criteria and sub-criteria within this envelope. The default will not apply to individually
                  updated scales.
                </Text>
              ) : null}
            </div>
            <div className="col-span-2">
              <Select_DEPRECATED<GqlEvaluationCriteriaRatingScale>
                className="w-full"
                value={formData.defaultRatingScale}
                options={EVALUATION_EVENT_RATING_SCALE_OPTIONS}
                onChange={(defaultRatingScale) => setFormData({ ...formData, defaultRatingScale })}
                isDisabled={isSaving || !isEditable}
                placeholder
                isRequired
                isFull={false}
              />
            </div>
            {includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, formData.defaultRatingScale) && (
              <div className="col-span-3">
                <EvaluationEventRatingScaleDefinitionList className="mt-4" ratingScale={formData.defaultRatingScale} />
              </div>
            )}
          </>
        ) : null}
      </div>
    </Drawer>
  )
})
