import { memo, useEffect, useMemo, useState } from 'react'
import { find, forEach, map, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import HighlightedText from '@cotiss/common/components/highlighted-text.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ContractCreateDrawer } from '@cotiss/contract/drawers/contract-create.drawer'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { metafieldValueService } from '@cotiss/metafield-value/metafield-value.service'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractVariationStatusBadge } from '@cotiss/contract/components/contract-variation-status-badge.component'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ContractNextExpirationDate } from '@cotiss/contract/components/contract-next-expiration-date.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { ContractListItemCta } from '@cotiss/contract/components/contract-list-item-cta.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { useContractSearchAndFiltersQuery } from '@cotiss/contract/components/contract-search-and-filters-query'
import { useContractSearchAndFiltersContext } from '@cotiss/contract/components/contract-search-and-filters-context.component'

export const ContractList = memo(() => {
  const { replace } = useHistory()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const { openDrawer } = useCallout()
  const [isDownloading, setIsDownloading] = useState(false)

  const { exportCsv } = useMutateContractShell()

  const { contractShells, pagination, isLoadingContractShells, handlePageChange, onSort } = useContractSearchAndFiltersQuery()

  const { queryState, isFiltering, processedFilters } = useContractSearchAndFiltersContext()

  const { searchQuery } = queryState

  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
  })

  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues

  useEffect(() => {
    const queryParams = utilService.generateUrlSearchParams({
      statusCard: queryState.statusCard ?? undefined,
      filters: JSON.stringify(queryState.advancedFilters),
      q: searchQuery,
    })
    replace({
      pathname: routerService.getHref('/contract/list/:tab?', { tab: queryState.primaryTab }),
      search: isFiltering ? queryParams : '',
    })
  }, [queryState.advancedFilters, searchQuery, queryState.primaryTab, queryState.statusCard, isFiltering])

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({ filters: processedFilters, searchQuery, timeZone: datetimeService.getLocalTimeZone() })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `contract_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const contractListTableHeader = (
    <TableHeader className="flex justify-between items-center" variant="white">
      <Text size="md" className="flex items-center gap-1">
        Results{' '}
        {isLoading ? (
          <Skeleton className="h-3 w-6 inline-block" />
        ) : (
          <Text isInline variant="light">
            ({pagination?.totalCount})
          </Text>
        )}
      </Text>
      <Button
        size="xs"
        variant="secondary"
        state="ghost"
        isDisabled={isLoading}
        isLoading={isDownloading}
        onClick={handleDownloadCsv}
        className="gap-1"
      >
        <Icon icon="download-01" /> <span>Download CSV</span>
      </Button>
    </TableHeader>
  )

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(contractShells, (contractShell) => ({
          content: () => (
            <Text className="truncate" title={contractShell.title}>
              <HighlightedText text={contractShell.title} highlightText={searchQuery} />
            </Text>
          ),
          cta: (
            <>
              {/* For initial contracts that are in drafting, only the person who created the contract can view */}
              {!contractShell.contracts.variationTypes.length &&
              contractShell.contracts.status == 'DRAFTING' &&
              !some(contractShell.contracts.metadata.owners, (owner) => owner._id === user?._id) ? (
                <Tooltip tooltip="This contract is locked as it is still in draft">
                  <Icon icon="lock" variant="light" />
                </Tooltip>
              ) : (
                <ContractListItemCta contractShell={contractShell} userId={user?._id} />
              )}
            </>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              <HighlightedText
                text={contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
                highlightText={searchQuery}
              />
            </Text>
          ),
        })),
      },
      {
        heading: 'Next expiry',
        rows: map(contractShells, ({ contracts: { priceDurations } }) => ({
          content: () => <ContractNextExpirationDate priceDurations={priceDurations}></ContractNextExpirationDate>,
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Owners',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <UserAvatarGroup users={contracts.metadata.owners} />,
        })),
      },
      {
        heading: 'Variation status',
        rows: map(contractShells, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              <HighlightedText text={contracts.metadata.externalReference || '--'} highlightText={searchQuery} />
            </Text>
          ),
        })),
      },
      {
        heading: 'Total Exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          contractShells,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          contractShells,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(contractShells, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(contractShells, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract id',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    forEach(metafields, (metafield) => {
      columns.push({
        heading: metafield.fieldLabel,
        rows: map(contractShells, ({ contracts: { _id: contractId } }) => ({
          tdClassName: 'max-w-[350px]',
          content: () => {
            const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
            const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

            if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
              return (
                <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                  {processedMetafieldValue}
                </Button>
              )
            }

            return (
              <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                {processedMetafieldValue}
              </Text>
            )
          },
        })),
      })
    })

    return { fixedColumns, columns }
  }, [contractShells, user, metafields, metafieldValues])

  if (!isLoading && !contractShells?.length) {
    return (
      <div>
        {contractListTableHeader}
        <div className="flex items-center justify-center h-60 bg-white border">
          {isFiltering ? (
            <Text size="md" variant="light">
              No results
            </Text>
          ) : (
            <NoDataPlaceholder
              illustration="dot-list"
              variant="transparent"
              label="You haven't created any contracts yet. Once created, you can track your contracts here"
              ctaSize="xs"
              ctaLabel="+ Create contract"
              onCtaClick={() => openDrawer(<ContractCreateDrawer />)}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      {contractListTableHeader}
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={handlePageChange} isLoading={isLoading} />
    </div>
  )
})
