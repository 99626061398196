import { LDClient, LDContext, initialize, LDOptions } from 'launchdarkly-js-client-sdk'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { UserMeModel } from '@cotiss/user/user.models'

export type Feature = keyof typeof FEATURE_DEFAULT_MAP

const FEATURE_DEFAULT_MAP = {
  ['contract-management-e-sign']: false,
  ['contract-management-v1']: false,
  ['es-core-field-descriptors']: false,
  ['evaluation-event']: false,
  ['old-evaluations']: true,
  ['performance-scorecard']: false,
  ['procurements']: false,
  ['sso-configuration']: false,
  ['enable-unweighted-evaluation-envelope']: false,
  ['enable-evaluation-envelope-level-comment']: false,
  ['enable-no-criteria-evaluation']: false,
} as const

class FeatureService {
  private _client: LDClient | undefined
  private _isInitialised = false

  init = async (context?: LDContext) => {
    if (!import.meta.env.VITE_LD_CLIENT_SIDE_ID) {
      return
    }

    const options: LDOptions = {}

    if (import.meta.env.VITE_LD_PROXY_ENABLED) {
      options.streamUrl = import.meta.env.VITE_LD_PROXY_STREAM_URL
      options.baseUrl = import.meta.env.VITE_LD_PROXY_URL
      options.eventsUrl = import.meta.env.VITE_LD_PROXY_EVENTS_URL
    }

    try {
      this._client = initialize(import.meta.env.VITE_LD_CLIENT_SIDE_ID, context || { kind: 'anonymous', key: utilService.generateUid() }, options)
      await this._client.waitForInitialization()
      this._isInitialised = true
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }
  }

  identify = async (user: UserMeModel) => {
    if (!this._isInitialised || !this._client || !user?._id) {
      return
    }

    await this._client.identify({
      kind: 'user',
      key: user._id,
      email: user.email,
      accountId: user.account._id,
      organisationId: user.account.organisation?._id,
    })
  }

  variation = (feature: Feature) => {
    if (!this._isInitialised || !this._client) {
      return FEATURE_DEFAULT_MAP[feature]
    }

    return this._client.variation(feature, FEATURE_DEFAULT_MAP[feature])
  }
}

export const featureService = new FeatureService()
