import { memo, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventEnvelopeRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-envelope-rating-scale.drawer'
import { EVALUATION_EVENT_RATING_SCALE_NAME_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isEditable?: boolean
}

export const EvaluationEventNoCriteriaDetails = memo(({ evaluationEnvelope, isEditable }: Props) => {
  const { openDrawer } = useCallout()

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Rating scale',
        thClassName: 'w-[340px]',
        rows: [
          {
            content: () =>
              isWeightedEnvelope ? (
                <div className="self-start">
                  <Text>{EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationEnvelope.defaultRatingScale]}</Text>
                </div>
              ) : (
                <Text>
                  NA -{' '}
                  <Text isInline variant="light">
                    This is a non-scored envelope.
                  </Text>
                </Text>
              ),
            cta: (
              <TableRowCta
                cta={{
                  label: isEditable ? 'Edit' : 'View',
                  onClick: () =>
                    openDrawer(
                      <EvaluationEventEnvelopeRatingScaleDrawer evaluationEnvelope={evaluationEnvelope} hasCriteria={false} isEditable={isEditable} />
                    ),
                }}
              />
            ),
          },
        ],
      },
      {
        heading: 'Evaluation requirements',
        rows: [
          {
            tdClassName: 'align-top',
            content: () => <Text className="whitespace-pre-wrap line-clamp-3">{evaluationEnvelope.supplementary ?? '--'}</Text>,
          },
        ],
      },
    ]

    return { columns }
  }, [evaluationEnvelope, isEditable])

  return (
    <div>
      <TableHeader className="flex items-center justify-between w-full">
        <div className="p-2">
          <Text className="font-medium">This envelope does not have any assigned criteria. Panel members will evaluate the envelope as a whole.</Text>
        </div>
      </TableHeader>
      <Table columns={columns} />
    </div>
  )
})
