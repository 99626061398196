import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Filter } from '@cotiss/common/models/filter.model'
import { queryService } from '@cotiss/common/services/query.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ContractShellFilterTotals } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export type ContractShellFilterTotalsParam = {
  baseFilters: { filters: Filter[]; searchQuery?: string }
  totalFilters: Record<string, { filters: Filter[] }>
}

export type ContractShellFilterTotalsResponse = {
  totals: Record<string, ContractShellFilterTotals>
}

export function useCountContractShells(params: ContractShellFilterTotalsParam, queryKey: string) {
  const { openToast } = useToast()
  const { data, isLoading, error } = useQuery({
    queryKey: [
      '/v2/contract-shells',
      'count-contract-shells',
      queryKey,
      JSON.stringify(params.totalFilters),
      JSON.stringify(params.baseFilters.filters),
      params.baseFilters.searchQuery,
    ],
    queryFn: async ({ signal }) =>
      queryService.mutate<ContractShellFilterTotalsParam, ContractShellFilterTotalsResponse>('/v2/contract-shells/filter-totals', {
        signal: signal,
        method: 'POST',
        body: { baseFilters: params.baseFilters, totalFilters: params.totalFilters },
      }),
    staleTime: 0,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (error instanceof Error) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [error])

  return {
    totals: data?.totals ?? {},
    isLoading,
  }
}
