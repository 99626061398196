import { memo, useCallback, useEffect, useMemo } from 'react'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { Button } from '@cotiss/common/components/button.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { find } from 'lodash'
import { userService } from '@cotiss/user/user.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventModerationCommentCreateUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-moderation-comment-create-update.modal'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useParams } from 'react-router-dom'
import { GqlEvaluationFieldsFragment } from '@gql'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { EvaluationEventRatingScaleValueField } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-value-field.component'
import { EvaluationEventEvaluationStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-status-badge.component'
import { renderScoreDelta } from '@cotiss/evaluation-event/components/evaluation-event-moderation-criteria-review.component'

type moderationCommentaryRows = {
  evaluators: Array<{ content: () => JSX.Element }>
  scores: Array<{ content: () => JSX.Element }>
  commentaries: Array<{ content: () => JSX.Element }>
  moderatorComments: Array<{ content: () => JSX.Element; cta?: JSX.Element }>
}

export const EvaluationEventModerationEnvelopeCommentaryList = memo(({ hasCriteria }: { hasCriteria: boolean }) => {
  const { evaluationUsers, evaluationUserInSession } = useEvaluationUser()
  const { evaluations, queryEvaluationList, mutateUpdateEvaluation } = useEvaluation()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationEnvelope, evaluationEnvelopeOverview, queryEvaluationEnvelopeOverviewView } = useEvaluationEnvelope()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { evaluationSubmissionId, evaluationEnvelopeId } = useParams<{ evaluationSubmissionId: string; evaluationEnvelopeId: string }>()

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0
  const isScoreableEnvelope = isWeightedEnvelope && !hasCriteria

  const handleRefreshList = useCallback(() => {
    if (!evaluationEvent || !evaluationSubmissionId || !evaluationEnvelopeId) {
      return
    }

    queryEvaluationList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        evaluationSubmissionId,
        evaluationEnvelopeId,
      },
    })
  }, [evaluationEvent, evaluationSubmissionId, evaluationEnvelopeId])

  useEffect(() => {
    handleRefreshList()
  }, [handleRefreshList])

  const handleModeratorScoreUpdate = async ({ value, evaluation }: { value: string; evaluation: GqlEvaluationFieldsFragment }) => {
    try {
      await mutateUpdateEvaluation({
        evaluationId: evaluation.id,
        moderationValue: Number(value),
      })
      await Promise.all([queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId: evaluationEnvelopeId }), handleRefreshList()])
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const rows = useMemo(() => {
    const getUserName = (evaluationUserId: string) => {
      const user = find(evaluationUsers, { id: evaluationUserId })

      return (
        <div className="flex items-center truncate">
          <Text className="truncate">{userService.getFullName(user?.user)}</Text>
          {evaluationUserInSession?.id === user?.id && (
            <Text className="ml-1" variant="light">
              (you)
            </Text>
          )}
        </div>
      )
    }

    const handleDeleteComment = async (evaluation: GqlEvaluationFieldsFragment) => {
      try {
        await mutateUpdateEvaluation({
          evaluationId: evaluation.id,
          moderationComment: null,
        })
        handleRefreshList()
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        openToast(error.message, 'danger')
      }
    }
    return evaluations.reduce(
      (acc, evaluation) => {
        // Add to evaluator rows
        acc.evaluators.push({
          content: () => getUserName(evaluation.evaluationUserId),
        })

        // Add to score rows
        acc.scores.push({
          content: () => {
            if (!evaluationEnvelope) {
              return <></>
            }

            return (
              <div className="flex items-center justify-between">
                {evaluation.status === 'abstained' ? (
                  <EvaluationEventEvaluationStatusBadge status={evaluation.status} />
                ) : (
                  <>
                    <div className="w-[130px] flex-shrink-0">
                      <EvaluationEventRatingScaleValueField
                        ratingScale={evaluationEnvelope?.defaultRatingScale}
                        value={evaluation.moderationValue?.toString() || evaluation.value?.toString()}
                        onSubmit={(value) => handleModeratorScoreUpdate({ value, evaluation })}
                        isDisabled={!evaluationEvent || evaluationEnvelope?.status !== 'moderate' || submissionBreakdown?.status === 'reviewed'}
                      />
                    </div>
                    {renderScoreDelta({ value: evaluation.value, moderationValue: evaluation.moderationValue })}
                  </>
                )}
              </div>
            )
          },
        })

        // Add to commentary rows
        acc.commentaries.push({
          content: () => <Text>{evaluation.comment || '--'}</Text>,
        })

        const submissionBreakdown = find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId })
        const isEditable = Boolean(evaluationEvent && evaluationEnvelope?.status === 'moderate' && submissionBreakdown?.status !== 'reviewed')

        // Add to moderator comment rows
        acc.moderatorComments.push({
          content: () => (
            <div className="flex items-center justify-between w-full">
              {evaluation.moderationComment ? (
                <Text className="w-full mr-2">{evaluation.moderationComment}</Text>
              ) : (
                <Button
                  state="text"
                  size="sm"
                  variant="link"
                  onClick={() =>
                    openModal(<EvaluationEventModerationCommentCreateUpdateModal evaluation={evaluation} onChange={handleRefreshList} />)
                  }
                  isDisabled={!isEditable}
                >
                  + Add comment
                </Button>
              )}
            </div>
          ),
          cta:
            isEditable && evaluation.moderationComment ? (
              <TableRowCta
                actions={[
                  {
                    label: 'Edit',
                    onClick: () =>
                      openModal(<EvaluationEventModerationCommentCreateUpdateModal evaluation={evaluation} onChange={handleRefreshList} />),
                  },
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete comment"
                          description="Are you sure you want to delete this comment?"
                          onSubmit={() => handleDeleteComment(evaluation)}
                        />
                      ),
                  },
                ]}
              />
            ) : undefined,
        })

        return acc
      },
      { evaluators: [], scores: [], commentaries: [], moderatorComments: [] } as moderationCommentaryRows
    )
  }, [evaluations, evaluationUsers, evaluationEnvelopeOverview, evaluationSubmissionId, evaluationEvent, evaluationEnvelope, handleRefreshList])

  const columns: TableColumn[] = [
    {
      heading: 'Evaluator',
      rows: rows.evaluators,
    },
    ...(isScoreableEnvelope
      ? [
          {
            heading: 'Score',
            thClassName: 'w-48',
            rows: rows.scores,
          },
        ]
      : []),
    {
      heading: 'Commentary',
      rows: rows.commentaries,
    },
    {
      heading: 'Moderator comment',
      rows: rows.moderatorComments,
    },
  ]

  return (
    <div>
      <TableHeader className="relative flex items-center justify-between">
        <Text className="font-medium p-1" size="lg">
          {hasCriteria ? (
            <>
              Comments{' '}
              <Text size="sm" isInline variant="light">
                (optional)
              </Text>
            </>
          ) : (
            <span>Individual evaluation</span>
          )}
        </Text>
      </TableHeader>
      <Table columns={columns} />
    </div>
  )
})
