import { Text } from '@cotiss/common/components/text.component'
import { ViewMoreContainer } from '@cotiss/common/components/view-more-container.component'

export const EvaluationEventEvaluationRequirements = ({ evaluationRequirements }: { evaluationRequirements: string }) => {
  return (
    <div>
      <Text className="font-medium mb-1">Evaluation requirements</Text>
      <ViewMoreContainer>
        <Text className="font-medium whitespace-pre-line" variant="light">
          {evaluationRequirements}
        </Text>
      </ViewMoreContainer>
    </div>
  )
}
