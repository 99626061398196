import { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationScoreFieldsFragment, GqlPagination } from '@gql'
import classNames from 'classnames'
import { filter, find, groupBy, keyBy, map, sortBy } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { EvaluationEventEvaluationCriteriaBreakdownStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-criteria-breakdown-status-badge.component'
import { EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationScore } from '@cotiss/evaluation-event/hooks/use-evaluation-score.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  parentEvaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment
  onCtaClick: (evaluationCriteria: GqlEvaluationCriteriaFieldsFragment) => void
}

export const EvaluationEventCriteriaScoreList = memo(({ parentEvaluationCriteriaItem, onCtaClick }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const { queryEvaluationScoreList } = useEvaluationScore()
  const { evaluation, evaluationOverview } = useEvaluation()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [evaluationScores, setEvaluationScores] = useState<GqlEvaluationScoreFieldsFragment[]>([])
  const [evaluationCriteria, setEvaluationCriteria] = useState<GqlEvaluationCriteriaFieldsFragment[]>([])

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  const refreshScores = async (evaluationCriteria: GqlEvaluationCriteriaFieldsFragment[]) => {
    if (!evaluationEvent || !evaluation) {
      return
    }
    const { evaluationScores } = await queryEvaluationScoreList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        evaluationId: evaluation.id,
        evaluationCriteriaIds: map(evaluationCriteria, 'id'),
      },
    })
    setEvaluationScores(evaluationScores)
  }

  useAsyncEffect(async () => {
    // If we don't have this data available, then something has gone wrong.
    if (!evaluationEnvelope || !evaluationEvent || !evaluation) {
      return
    }
    try {
      const { evaluationCriteria, pagination } = await queryEvaluationCriteriaList({
        filter: {
          evaluationEnvelopeId: evaluationEnvelope.id,
          evaluationEventId: evaluationEvent.id,
          parentEvaluationCriteriaId: parentEvaluationCriteriaItem?.id || null,
        },
        pagination: { page: currentPage, pageSize: 100 },
      })

      await refreshScores(evaluationCriteria)
      setEvaluationCriteria(evaluationCriteria)
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [currentPage])

  const { parentColumns, parentCriteria, parentCriteriaBreakdown, leafColumns, leafCriteria } = useMemo(() => {
    const criteriaByIsScored = groupBy(evaluationCriteria, 'isScored')
    const parentCriteria = sortBy(criteriaByIsScored['false'], 'index') || []
    const leafCriteria = sortBy(criteriaByIsScored['true'], 'index') || []

    const allCriteriaInThisList = sortBy(evaluationCriteria, 'index')
    const weightById = evaluationEventService.getWeightById({ items: allCriteriaInThisList })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const weightPercentageById = evaluationEventService.getWeightedPercentageById({
      weightById,
      totalWeight,
    })

    const parentCriteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, {
      evaluationCriteriaId: parentEvaluationCriteriaItem?.id,
    })

    const accessControlCriteriaMap = keyBy(
      filter(evaluationUserInSession?.accessControls, ({ resourceType, access }) => {
        return resourceType === 'criteria' && access === 'evaluate'
      }),
      'resourceId'
    )

    const isAllLeafDisabled = Boolean(parentEvaluationCriteriaItem && !accessControlCriteriaMap[parentEvaluationCriteriaItem.id])

    const parentColumns: TableColumn[] = [
      {
        heading: 'Criteria',
        rows: map(parentCriteria, (evaluationCriteriaItem) => {
          const isDisabled = !accessControlCriteriaMap[evaluationCriteriaItem.id]
          return {
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">{evaluationCriteriaItem.index}.</Text>
                <Text className="line-clamp-3">{evaluationCriteriaItem.content}</Text>
              </div>
            ),
            cta: !isDisabled ? (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      Evaluate <Icon className="ml-1" icon="arrow-right" />
                    </>
                  ),
                  onClick: () => onCtaClick(evaluationCriteriaItem),
                }}
              />
            ) : (
              <Tooltip tooltip="You are not required to evaluate this criteria.">
                <Icon icon="lock" variant="light" />
              </Tooltip>
            ),
          }
        }),
      },
      {
        heading: 'Status',
        rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => {
          const isDisabled = !accessControlCriteriaMap[evaluationCriteriaId]
          const criteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, {
            evaluationCriteriaId,
          })
          return {
            content: () => (
              <div className="flex items-center">
                {!isDisabled && (
                  <>
                    <EvaluationEventEvaluationCriteriaBreakdownStatusBadge criteriaBreakdown={criteriaBreakdown} />
                    {criteriaBreakdown && (
                      <Text className="shrink-0 ml-2" variant="light" size="sm">
                        ({criteriaBreakdown.completedScoredSubCriteriaCount} of {criteriaBreakdown.scoredSubCriteriaCount})
                      </Text>
                    )}
                  </>
                )}
                {isDisabled && <Text>--</Text>}
              </div>
            ),
          }
        }),
      },
      ...(isWeightedEnvelope
        ? [
            {
              heading: 'Weight',
              thClassName: 'text-right w-28',
              rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => ({
                content: () => (
                  <Text className="text-right">{utilService.formatAsPercentage(Number(weightPercentageById[evaluationCriteriaId]) * 100)}</Text>
                ),
              })),
            },
            {
              heading: 'Weighted score',
              thClassName: 'text-right w-36',
              rows: map(parentCriteria, ({ id: evaluationCriteriaId }) => {
                const criteriaBreakdown = find(evaluationOverview?.criteriaBreakdown, {
                  evaluationCriteriaId,
                })
                return {
                  content: () =>
                    criteriaBreakdown?.completedScoredSubCriteriaCount ? (
                      <Text className="text-right" variant="secondary">
                        {criteriaBreakdown.rawScoreSummary
                          ? utilService.formatAsPercentage(criteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)
                          : '--'}
                      </Text>
                    ) : (
                      <Text className="text-right">--</Text>
                    ),
                }
              }),
            },
          ]
        : []),
    ]

    const leafColumns: TableColumn[] = [
      {
        heading: 'Criteria',
        rows: map(leafCriteria, (evaluationCriteriaItem) => {
          const isDisabled = parentEvaluationCriteriaItem ? isAllLeafDisabled : !accessControlCriteriaMap[evaluationCriteriaItem.id]
          return {
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">
                  {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}.` : ''}
                  {evaluationCriteriaItem.index}.
                </Text>
                <Text className="line-clamp-3">{evaluationCriteriaItem.content}</Text>
              </div>
            ),
            cta: !isDisabled ? (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      Evaluate <Icon className="ml-1" icon="arrow-right" />
                    </>
                  ),
                  onClick: () => onCtaClick(evaluationCriteriaItem),
                }}
              />
            ) : (
              <Tooltip tooltip="You are not required to score this criteria.">
                <Icon icon="lock" variant="light" />
              </Tooltip>
            ),
          }
        }),
      },
      ...(isWeightedEnvelope
        ? [
            {
              heading: 'Weight',
              thClassName: 'text-right w-28',
              rows: map(leafCriteria, ({ id: evaluationCriteriaId }) => ({
                tdClassName: 'text-right',
                content: () => (
                  <Text className="truncate">{utilService.formatAsPercentage(Number(weightPercentageById[evaluationCriteriaId]) * 100)}</Text>
                ),
              })),
            },
            {
              heading: 'Score',
              thClassName: 'w-40',
              rows: map(leafCriteria, (evaluationCriteriaItem) => {
                const evaluationScore = find(evaluationScores, {
                  evaluationCriteriaId: evaluationCriteriaItem.id,
                })
                return {
                  content: () => {
                    const isDisabled = parentEvaluationCriteriaItem ? isAllLeafDisabled : !accessControlCriteriaMap[evaluationCriteriaItem.id]
                    return isDisabled ? (
                      <Text>--</Text>
                    ) : (
                      <Text>
                        {evaluationScore ? evaluationScore.value : '--'}{' '}
                        {evaluationCriteriaItem.ratingScale === 'percentage'
                          ? '%'
                          : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[evaluationCriteriaItem.ratingScale]}`}
                      </Text>
                    )
                  },
                }
              }),
            },
          ]
        : []),
      {
        heading: 'Comment',
        rows: map(leafCriteria, (evaluationCriteriaItem) => {
          const evaluationScore = find(evaluationScores, {
            evaluationCriteriaId: evaluationCriteriaItem.id,
          })
          return {
            content: () => <Text className="line-clamp-3">{evaluationScore?.comment || '--'}</Text>,
          }
        }),
      },
    ]

    return { parentColumns, parentCriteria, parentCriteriaBreakdown, leafColumns, leafCriteria }
  }, [parentEvaluationCriteriaItem, evaluationCriteria, evaluationScores, isWeightedEnvelope, evaluation])

  if (!evaluationCriteria.length) return null

  return (
    <div className="flex flex-col gap-y-6">
      {Boolean(parentCriteria.length) && (
        <div>
          <TableHeader>
            <Text className="font-semibold">Criteria with sub-criteria</Text>
            <Text className="mt-1" variant="light" size="sm">
              Hover over each criteria and select to evaluate each sub-criteria
            </Text>
          </TableHeader>
          <Table columns={parentColumns} isLoading={isLoading} />
        </div>
      )}
      {Boolean(leafCriteria.length) && (
        <div>
          <TableHeader className={classNames('flex items-center justify-between')}>
            <div>
              <Text className="font-semibold">
                {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}. ${parentEvaluationCriteriaItem.content}` : 'Criteria only'}
              </Text>
              <Text className="mt-1" variant="light" size="sm">
                Evaluate each sub-criteria and add a justification comment
              </Text>
            </div>
            {isWeightedEnvelope && parentCriteriaBreakdown && (
              <div>
                <Text size="sm" variant="light">
                  Weighted score
                </Text>
                <Text className="text-right" variant="secondary">
                  {parentCriteriaBreakdown.completedScoredSubCriteriaCount && parentCriteriaBreakdown.rawScoreSummary
                    ? utilService.formatAsPercentage(parentCriteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)
                    : '--'}
                </Text>
              </div>
            )}
          </TableHeader>
          <Table className="w-full" columns={leafColumns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
        </div>
      )}
    </div>
  )
})
