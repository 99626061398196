import { memo, useEffect, useState } from 'react'
import { find, forEach, includes, map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { metafieldValueService } from '@cotiss/metafield-value/metafield-value.service'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { ProcurementCreateUpdateModal } from '@cotiss/procurement/modals/procurement-create-update.modal'
import { ListProcurementSortKey, useListProcurement } from '@cotiss/procurement/resources/use-list-procurement.resource'
import { useMutateProcurement } from '@cotiss/procurement/resources/use-mutate-procurement.resource'
import { useListProject } from '@cotiss/project/resources/use-list-project.resource'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export type ProcurementListTab = 'archived' | 'active'

const PROCUREMENT_LIST_TABS: TabModel<ProcurementListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const ProjectListPage = memo(() => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { tab } = useParams<{ tab?: ProcurementListTab }>()
  const { replace, push } = useHistory()
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'PROCUREMENT_PLAN',
  })
  const { updateProcurement, exportCsv } = useMutateProcurement()
  const [isDownloading, setIsDownloading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<ListProcurementSortKey>({ initialKey: 'createdAt' })
  const {
    procurements,
    pagination,
    meta,
    isLoading: isProcurementsLoading,
  } = useListProcurement({
    currentPage,
    sort: sortKey,
    order: sortDirection,
    pageSize: 20,
    isArchived: tab === 'archived',
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(procurements, (procurement) => procurement._id),
  })
  const isLoading = isProjectsLoading || isProcurementsLoading || isLoadingMetafields || isLoadingMetafieldValues

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/project/list/:tab?', { tab: 'active' }))
    }
  }, [])

  const handleTabChange = (_tab: ProcurementListTab) => {
    push(routerService.getHref('/project/list/:tab?', { tab: _tab }))
  }

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({
        isArchived: tab === 'archived',
        timeZone: datetimeService.getLocalTimeZone(),
      })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `procurement_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  if (!permissions.hasProcurementAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  const procurementListTableHeader = (
    <TableHeader className="flex justify-between items-center" variant="white">
      <Text size="md" className="flex items-center gap-1">
        Results{' '}
        {isLoading ? (
          <Skeleton className="h-3 w-6 inline-block" />
        ) : (
          <Text isInline variant="light">
            ({pagination?.totalCount})
          </Text>
        )}
      </Text>
      <Button
        size="xs"
        variant="secondary"
        state="ghost"
        isDisabled={isLoading}
        isLoading={isDownloading}
        onClick={handleDownloadCsv}
        className="gap-1"
      >
        <Icon icon="download-01" /> <span>Download CSV</span>
      </Button>
    </TableHeader>
  )

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Procurement name',
      onSort: () => onSort('title'),
      rows: map(procurements, (procurement) => {
        const hasProcurementAccess = includes(meta?.hasAccess, procurement._id)

        return {
          content: () => <Text className="truncate">{procurement.title}</Text>,
          cta: hasProcurementAccess ? (
            <TableRowCta
              cta={
                procurement.isArchived
                  ? undefined
                  : {
                      label: (
                        <>
                          View <Icon className="ml-1" icon="arrow-right" />
                        </>
                      ),
                      href: routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId: procurement._id }),
                    }
              }
              actions={[
                ...(procurement.isArchived
                  ? []
                  : [
                      {
                        label: 'Edit',
                        onClick: () => openModal(<ProcurementCreateUpdateModal procurement={procurement} />),
                      },
                    ]),
                {
                  label: procurement.isArchived ? 'Unarchive' : 'Archive',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading={procurement.isArchived ? 'Unarchive procurement' : 'Archive procurement'}
                        description={
                          procurement.isArchived
                            ? `Are you sure you want unarchive this procurement?`
                            : `Are you sure you want to archive this procurement?`
                        }
                        onSubmit={async () => {
                          await updateProcurement(procurement._id, { isArchived: !procurement.isArchived })
                        }}
                      />
                    ),
                },
              ]}
            />
          ) : (
            <Icon icon="lock" variant="light" size={20} />
          ),
        }
      }),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Date created',
      onSort: () => onSort('createdAt'),
      rows: map(procurements, ({ createdAt }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(createdAt, 'do MMM yyyy')}
          </Text>
        ),
      })),
    },
    {
      heading: 'Reference',
      rows: map(procurements, ({ internalIdentifier }) => ({
        content: () => (
          <Text className="truncate" size="sm">
            {internalIdentifier}
          </Text>
        ),
      })),
    },
    {
      heading: 'Project',
      rows: map(procurements, ({ project }) => ({
        content: () => (
          <Text className="truncate max-w-xs" size="sm">
            {find(projects, { _id: project })?.title || '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Users',
      rows: map(procurements, ({ accessControlUsers }) => ({
        content: () => <UserAvatarGroup users={accessControlUsers} />,
      })),
    },
    {
      heading: 'Type',
      rows: map(procurements, ({ tenders }) => ({
        content: () => (
          <>
            {map(tenders, (tender) => (
              <TenderTypeBadge key={tender._id} className="mr-1" tenderType={tender.tenderType} />
            ))}
          </>
        ),
      })),
    },
    {
      heading: 'Opex budget',
      onSort: () => onSort('opexBudget'),
      rows: map(procurements, ({ opexBudget }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {opexBudget ? utilService.formatAsCurrency(opexBudget.amount, opexBudget.currency) : '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Capex budget',
      onSort: () => onSort('capexBudget'),
      rows: map(procurements, ({ capexBudget }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {capexBudget ? utilService.formatAsCurrency(capexBudget.amount, capexBudget.currency) : '--'}
          </Text>
        ),
      })),
    },
  ]

  // Add metafields columns
  forEach(metafields, (metafield) => {
    columns.push({
      heading: metafield.fieldLabel,
      rows: map(procurements, (procurement) => ({
        tdClassName: 'max-w-[350px]',
        content: () => {
          const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: procurement._id })
          const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

          if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
            return (
              <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                {processedMetafieldValue}
              </Button>
            )
          }

          return (
            <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
              {processedMetafieldValue}
            </Text>
          )
        },
      })),
    })
  })

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Procurements
        </Text>
        <Button onClick={() => openModal(<ProcurementCreateUpdateModal />)} size="md">
          + New procurement
        </Button>
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <Tabs<ProcurementListTab> tab={tab} tabs={PROCUREMENT_LIST_TABS} onChange={({ id }) => handleTabChange(id)} variant="raised" />
      </div>
      <PageContent>
        {!isLoading && !procurements.length ? (
          <div>
            {procurementListTableHeader}
            <div className="flex items-center justify-center bg-white border">
              <NoDataPlaceholder
                illustration="dot-list"
                variant="white"
                label={tab === 'active' ? 'You have not created any procurements yet.' : 'No procurements have been found.'}
                ctaSize="xs"
                ctaLabel={tab === 'active' ? 'Create procurement' : undefined}
                onCtaClick={tab === 'active' ? () => openModal(<ProcurementCreateUpdateModal />) : undefined}
              />
            </div>
          </div>
        ) : (
          <div>
            {procurementListTableHeader}
            <ScrollableTable
              fixedColumns={fixedColumns}
              columns={columns}
              pagination={pagination}
              onPageChange={setCurrentPage}
              isLoading={isLoading}
            />
          </div>
        )}
      </PageContent>
    </Page>
  )
})
