import { FormEvent, memo, useEffect, useState } from 'react'
import CotissIconSecondarySvg from '@assets/svg/cotiss-icon-secondary.svg'
import { AnimatePresence } from 'framer-motion'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { UnspscCategoriesModal } from '@cotiss/common/modals/unspsc-categories/unspsc-categories.modal'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { AccountEmployeeNumberRange } from '@cotiss/account/account.models'
import { useMutateAccount } from '@cotiss/account/resources/use-mutate-account.resource'
import { PreferencesEmailNotificationPeriod } from '@cotiss/preferences/preferences.models'
import { useGetPreferences } from '@cotiss/preferences/resources/use-get-preferences.resource'
import { useMutatePreferences } from '@cotiss/preferences/resources/use-mutate-preferences.resource'
import { SupplierOnboardingStepBusinessSummary } from '@cotiss/supplier/components/supplier-onboarding-step-business-summary.component'
import { SupplierOnboardingStepComplete } from '@cotiss/supplier/components/supplier-onboarding-step-complete.component'
import { SupplierOnboardingStepRegions } from '@cotiss/supplier/components/supplier-onboarding-step-regions.component'
import { SupplierOnboardingStepTeamSize } from '@cotiss/supplier/components/supplier-onboarding-step-team-size.component'
import { SupplierOnboardingStepTenderNotifications } from '@cotiss/supplier/components/supplier-onboarding-step-tender-notifications.component'
import { SupplierOnboardingStepUnspscCategories } from '@cotiss/supplier/components/supplier-onboarding-step-unspsc-categories.component'
import { SupplierOnboardingRegionsModal } from '@cotiss/supplier/modals/supplier-onboarding-regions.modal'
import { useGetSupplierProfile } from '@cotiss/supplier/resources/use-get-supplier-profile.resource'
import { useGetSupplierProfilesOnboarding } from '@cotiss/supplier/resources/use-get-supplier-profiles-onboarding.resource'
import { useMutateSupplier } from '@cotiss/supplier/resources/use-mutate-supplier.resource'
import { SupplierProfilesOnboardingStage } from '@cotiss/supplier/supplier.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type SupplierOnboardingFormData = {
  emailNotificationPeriod: PreferencesEmailNotificationPeriod
  teamSize: AccountEmployeeNumberRange
  regions: string[]
  categories: string[]
  summary: string
}

export const SupplierOnboardingFullModal = memo(() => {
  const { user, isLoading: isLoadingUser } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { updateAccount } = useMutateAccount()
  const { preferences, isLoading: isLoadingPreferences } = useGetPreferences()
  const { updatePreferences } = useMutatePreferences()
  const { openModal, closeModal, closeFullModal } = useCallout()
  const { supplierProfilesOnboarding } = useGetSupplierProfilesOnboarding()
  const { updateSupplierProfile, updateSupplierProfilesOnboarding } = useMutateSupplier()
  const { supplierProfile, isLoading: isLoadingSupplierProfile } = useGetSupplierProfile(supplierProfilesOnboarding?.organisation)
  const [step, setStep] = useState<SupplierProfilesOnboardingStage | null>('tender_notifications')
  const [formData, setFormData] = useState<SupplierOnboardingFormData>({
    emailNotificationPeriod: 'hourly',
    teamSize: '1-50',
    regions: [],
    categories: [],
    summary: '',
  })
  const isLoading = isLoadingUser || isLoadingPreferences || isLoadingSupplierProfile

  useEffect(() => {
    if (isLoading) {
      return
    }

    setFormData((fd) => ({
      emailNotificationPeriod: preferences?.emailNotificationPeriod ?? fd.emailNotificationPeriod,
      teamSize: user?.account.employeeNoRange ?? fd.teamSize,
      regions: supplierProfile?.regions ?? fd.regions,
      categories: supplierProfile?.categories.map(({ _id }) => _id) ?? fd.categories,
      summary: supplierProfile?.description ?? fd.summary,
    }))
  }, [isLoading])

  useEffect(() => {
    setStep(supplierProfilesOnboarding?.stage || 'tender_notifications')
  }, [supplierProfilesOnboarding])

  if (!step || !user) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      if (step === 'tender_notifications') {
        await Promise.all([
          updatePreferences({ emailNotificationPeriod: formData.emailNotificationPeriod }),
          updateSupplierProfilesOnboarding({ stage: 'team_size' }),
        ])
        setStep('team_size')
        return
      }

      if (step === 'team_size') {
        await Promise.all([
          updateAccount(user.account._id, { employeeNoRange: formData.teamSize }),
          updateSupplierProfilesOnboarding({ stage: 'regions' }),
        ])
        setStep('regions')
        return
      }

      if (step === 'regions') {
        await Promise.all([updateSupplierProfile({ regions: formData.regions }), updateSupplierProfilesOnboarding({ stage: 'unspsc_categories' })])
        setStep('unspsc_categories')
        return
      }

      if (step === 'unspsc_categories') {
        await Promise.all([
          updateSupplierProfile({ categories: formData.categories }),
          updateSupplierProfilesOnboarding({ stage: 'business_summary' }),
        ])
        setStep('business_summary')
        return
      }

      if (step === 'business_summary') {
        await Promise.all([
          updateSupplierProfile({ description: formData.summary }),
          updateSupplierProfilesOnboarding({ stage: 'profile_basic_completed', completed: true }),
        ])
        setStep('profile_basic_completed')
        return
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleUnspscCategoryChange = (categories: string[]) => {
    setFormData({ ...formData, categories })
    closeModal()
  }

  const handleOpenCategoriesModal = () => {
    openModal(<UnspscCategoriesModal selectedCategoryCodes={formData.categories} onChange={handleUnspscCategoryChange} />)
  }

  const handleOpenRegionModal = () => {
    openModal(<SupplierOnboardingRegionsModal regions={formData.regions} onChange={(regions) => setFormData({ ...formData, regions })} />)
  }

  return (
    <Form className="flex w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col justify-between w-full px-20 py-16">
        <div className="flex items-start justify-between">
          <div className="flex items-center">
            <CotissIconSecondarySvg className="w-10 -mt-6" />
            <div className="relative bg-secondary-100 rounded-2xl max-w-[300px] px-6 py-4 ml-8">
              <Text>Based on your profile we will send you tenders we think are most relevant.</Text>
              <div className="absolute top-[20px] -left-[15px] border-y-[15px] border-t-transparent border-r-[20px] border-r-secondary-100 border-b-transparent w-0 h-0" />
            </div>
          </div>
          <Button onClick={() => closeFullModal()} state="ghost" shape="square">
            <Icon icon="x-close" />
          </Button>
        </div>
        <div className="pl-20">
          <AnimatePresence initial={false} mode="wait">
            {step === 'tender_notifications' && (
              <SupplierOnboardingStepTenderNotifications
                key="tender_notifications"
                emailNotificationPeriod={formData.emailNotificationPeriod}
                onChange={(emailNotificationPeriod) => setFormData({ ...formData, emailNotificationPeriod })}
              />
            )}
            {step === 'team_size' && (
              <SupplierOnboardingStepTeamSize
                key="team_size"
                teamSize={formData.teamSize}
                onChange={(teamSize) => setFormData({ ...formData, teamSize })}
              />
            )}
            {step === 'regions' && (
              <SupplierOnboardingStepRegions
                key="regions"
                regions={formData.regions}
                onOpenModal={handleOpenRegionModal}
                onChange={(regions) => setFormData({ ...formData, regions })}
              />
            )}
            {step === 'business_summary' && (
              <SupplierOnboardingStepBusinessSummary
                key="business_summary"
                summary={formData.summary}
                onChange={(summary) => setFormData({ ...formData, summary })}
              />
            )}
            {step === 'unspsc_categories' && (
              <SupplierOnboardingStepUnspscCategories
                key="unspsc_categories"
                categoryCodes={formData.categories}
                onOpenModal={handleOpenCategoriesModal}
                onChange={(categories) => setFormData({ ...formData, categories })}
              />
            )}
            {step === 'profile_basic_completed' && <SupplierOnboardingStepComplete key="profile_basic_completed" />}
          </AnimatePresence>
        </div>
        <div className="flex items-center justify-end">
          {step !== 'profile_basic_completed' && (
            <>
              <Button className="mr-4" onClick={() => closeFullModal()} variant="link" state="text">
                Skip & check this later
              </Button>
              <Button variant="secondary" size="lg" type="submit">
                Continue <Icon icon="arrow-right" />
              </Button>
            </>
          )}
        </div>
      </div>
    </Form>
  )
})
