import { memo, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { filter, forEach, map, some, sortBy } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

import { userService } from '@cotiss/user/user.service'
import { EvaluationEventEvaluatorAccessUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-evaluator-access-update.modal'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isEditable?: boolean
  hasCriteria?: boolean
}

export const EvaluationEventEvaluatorAccessListTable = memo(({ evaluationEnvelope, isEditable, hasCriteria }: Props) => {
  const { openModal } = useCallout()
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { fixedColumns, columns } = useMemo(() => {
    const evaluationUsersInEnvelope = filter(evaluationUsers, ({ accessControls }) => {
      return some(accessControls, { resourceType: 'envelope', resourceId: evaluationEnvelope.id, access: 'evaluate' })
    })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Panel member',
        rows: map(evaluationUsersInEnvelope, (evaluationUser) => ({
          content: () => <Text className="truncate">{userService.getFullName(evaluationUser.user)}</Text>,
          cta: isEditable && hasCriteria && (
            <TableRowCta
              cta={{
                label: 'Edit access',
                onClick: () =>
                  openModal(<EvaluationEventEvaluatorAccessUpdateModal evaluationUser={evaluationUser} evaluationEnvelope={evaluationEnvelope} />),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = []

    if (hasCriteria) {
      const parentCriteria = filter(evaluationCriteria, { evaluationEnvelopeId: evaluationEnvelope.id, parentEvaluationCriteriaId: null })

      forEach(sortBy(parentCriteria, 'index'), ({ id: criteriaId, content, index }) => {
        columns.push({
          heading: (
            <Text className="normal-case">
              {index}. {content}
            </Text>
          ),
          rows: map(evaluationUsersInEnvelope, ({ accessControls }) => {
            const isEvaluatingCriteria = some(
              accessControls,
              ({ resourceType, resourceId, access }) => resourceType === 'criteria' && resourceId === criteriaId && access === 'evaluate'
            )

            return {
              content: () => (
                <>
                  {isEvaluatingCriteria && (
                    <Badge state="outline" variant="secondary">
                      Evaluate
                    </Badge>
                  )}
                  {!isEvaluatingCriteria && (
                    <Badge className="mr-1" state="outline" variant="neutral">
                      <Icon className="mr-1" icon="visible" /> View only
                    </Badge>
                  )}
                </>
              ),
            }
          }),
        })
      })
    } else {
      columns.push({
        heading: (
          <Text className="normal-case">This envelope does not have any assigned criteria. Panel members will evaluate the envelope as a whole.</Text>
        ),
        rows: map(evaluationUsersInEnvelope, ({ accessControls }) => {
          const isEvaluatingCriteria = some(
            accessControls,
            ({ resourceType, resourceId, access }) => resourceType === 'envelope' && resourceId === evaluationEnvelope.id && access === 'evaluate'
          )

          return {
            content: () => (
              <>
                {isEvaluatingCriteria && (
                  <Badge state="outline" variant="secondary">
                    Evaluate
                  </Badge>
                )}
                {!isEvaluatingCriteria && (
                  <Badge className="mr-1" state="outline" variant="neutral">
                    <Icon className="mr-1" icon="visible" /> View only
                  </Badge>
                )}
              </>
            ),
          }
        }),
      })
    }

    return { fixedColumns, columns }
  }, [evaluationEnvelope, evaluationUsers, evaluationCriteria, hasCriteria])

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} />
})
