import { ErrorBoundaryFallback } from '@cotiss/error/components/error-boundary-fallback.component'
import * as Sentry from '@sentry/react'

type Props = {
  className?: string
  errorCode?: number
  onRetry?: () => void
  children: React.ReactNode
}

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorBoundaryFallback resetErrorBoundary={resetError} />}>{children}</Sentry.ErrorBoundary>
  )
}
