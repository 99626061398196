import { Fragment, memo, useMemo } from 'react'
import { filter, map, sortBy } from 'lodash'
import { Hr } from '@cotiss/common/components/hr.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventParentTerminalCriteria } from '@cotiss/evaluation-event/components/evaluation-event-parent-terminal-criteria.component'
import { EvaluationEventSubCriteriaList } from '@cotiss/evaluation-event/components/evaluation-event-sub-criteria-list.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { EvaluationEventEnvelopeWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weighted-title'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { EvaluationEventWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-weighted-title'
import { EvaluationEventNoCriteriaDetails } from '@cotiss/evaluation-event/components/evaluation-event-no-criteria-details.component'

export const EvaluationEventSummaryCriteria = memo(() => {
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent } = useEvaluationEvent()
  const { weightedPercentageById, overallWeightedPercentageById } = useMemo(() => {
    return evaluationEventService.getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria })
  }, [evaluationEnvelopes, evaluationCriteria])

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'
  return (
    <>
      {map(evaluationEnvelopes, (evaluationEnvelope, index) => {
        const { id, name, order } = evaluationEnvelope

        const parentCriteria = sortBy(filter(evaluationCriteria, { evaluationEnvelopeId: id, parentEvaluationCriteriaId: null }), 'index')

        return (
          <div className={index ? 'mt-8' : ''} key={id}>
            <div className="flex justify-start">
              <Text size="lg" className="font-medium">
                <EvaluationEventEnvelopeWeightedTitle
                  order={order}
                  name={name}
                  relativeWeight={weightedPercentageById[id]}
                  shouldShowWeight={isWeightedMethodology}
                />
              </Text>
            </div>
            <Hr className="my-4" />
            {!parentCriteria.length ? (
              <EvaluationEventNoCriteriaDetails evaluationEnvelope={evaluationEnvelope} />
            ) : (
              map(parentCriteria, (parentEvaluationCriteriaItem) => {
                const { id: parentEvaluationCriteriaId, content, index, isScored } = parentEvaluationCriteriaItem

                return (
                  <Fragment key={parentEvaluationCriteriaId}>
                    <TableHeader className="mt-8">
                      <div className="p-1">
                        <Text size="sm" variant="light" className="mb-1">
                          Criteria {index}
                        </Text>
                        <Text size="md" className="font-medium">
                          <EvaluationEventWeightedTitle
                            relativeWeight={weightedPercentageById[parentEvaluationCriteriaId]}
                            title={content}
                            shouldShowWeight={isWeightedMethodology}
                          />
                        </Text>
                      </div>
                    </TableHeader>
                    {isScored ? (
                      <EvaluationEventParentTerminalCriteria
                        evaluationEnvelope={evaluationEnvelope}
                        evaluationCriteriaItem={parentEvaluationCriteriaItem}
                        weightedPercentageById={weightedPercentageById}
                        overallWeightedPercentageById={overallWeightedPercentageById}
                      />
                    ) : (
                      <EvaluationEventSubCriteriaList
                        evaluationEnvelope={evaluationEnvelope}
                        parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                        weightedPercentageById={weightedPercentageById}
                        overallWeightedPercentageById={overallWeightedPercentageById}
                      />
                    )}
                  </Fragment>
                )
              })
            )}
          </div>
        )
      })}
    </>
  )
})
