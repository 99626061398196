import { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment } from '@gql'
import { find, map, sortBy, findIndex } from 'lodash'
import { useParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { utilService } from '@cotiss/common/services/util.service'
import { Icon } from '@cotiss/common/components/icon.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationEventModerationCriteriaReview } from '@cotiss/evaluation-event/components/evaluation-event-moderation-criteria-review.component'
import { EvaluationEventModerationParentCriteriaItem } from '@cotiss/evaluation-event/components/evaluation-event-moderation-parent-criteria-item.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelopeSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope-submission.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { EvaluationEventModerationEnvelopeCommentaryList } from '@cotiss/evaluation-event/components/evaluation-event-moderation-envelope-commentary-list.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useModerationCriteriaExpansion } from '@cotiss/evaluation-event/hooks/use-moderation-criteria-expansion.hook'

type HandleSelectParam = {
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  activeEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export type EvaluationEventModerateEnvelopeSubmissionStepTab = 'criteria_subcriteria' | 'envelope_commentary'
export const EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_STEP_TABS: TabModel<EvaluationEventModerateEnvelopeSubmissionStepTab>[] = [
  { id: 'criteria_subcriteria', label: 'Criteria & sub-criteria' },
  { id: 'envelope_commentary', label: 'Envelope commentary' },
]

export const EvaluationEventModerateEnvelopeSubmissionResultsTab = memo(() => {
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [parentEvaluationCriteria, setParentEvaluationCriteria] = useState<GqlEvaluationCriteriaFieldsFragment[] | null>(null)

  const { evaluationEnvelope, evaluationEnvelopeOverview, queryEvaluationEnvelopeOverviewView } = useEvaluationEnvelope()
  const { mutateCreateEvaluationEnvelopeSubmission, mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId } =
    useEvaluationEnvelopeSubmission()
  const { evaluationEventId, evaluationEnvelopeId, evaluationSubmissionId } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationSubmissionId: string
  }>()

  const submissionBreakdown = find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId })

  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  useAsyncEffect(async () => {
    try {
      // TODO: We may have to consider how to paginate this list.
      const { evaluationCriteria } = await queryEvaluationCriteriaList({
        filter: {
          evaluationEventId,
          evaluationEnvelopeId,
          parentEvaluationCriteriaId: null,
        },
      })

      const sortedParentCriteria = sortBy(evaluationCriteria, 'index')

      setParentEvaluationCriteria(sortedParentCriteria)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }
  }, [])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId({ evaluationEnvelopeId, evaluationSubmissionId })
      await queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const handleMarkAsReviewed = async () => {
    try {
      setIsSaving(true)
      await mutateCreateEvaluationEnvelopeSubmission({ evaluationEnvelopeId, evaluationSubmissionId, status: 'reviewed' })
      await queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const renderWeightedTotalDelta = (submissionBreakdown?: GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment) => {
    if (
      !submissionBreakdown?.moderatedScoreSummary?.averageEnvelopeWeightedPercentageScore ||
      !submissionBreakdown?.rawScoreSummary?.averageEnvelopeWeightedPercentageScore ||
      submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore ===
        submissionBreakdown.rawScoreSummary.averageEnvelopeWeightedPercentageScore
    ) {
      return null
    }

    const delta =
      submissionBreakdown?.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore -
      submissionBreakdown?.rawScoreSummary.averageEnvelopeWeightedPercentageScore

    return (
      <Text className="shrink-0 ml-1" variant={delta > 0 ? 'success' : 'danger'}>
        <Icon icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
        {utilService.formatAsPercentage(delta * 100)}
      </Text>
    )
  }

  return (
    <PageContent>
      {/* TODO: Add skeleton loader */}
      <Banner
        className="mb-4"
        variant={submissionBreakdown?.status === 'reviewed' ? 'success' : 'secondary'}
        icon={submissionBreakdown?.status === 'reviewed' ? 'clipboard-check' : 'check-circle'}
      >
        <div className="mr-4">
          <Text className="font-semibold" variant="heading">
            Submission review
          </Text>
          <Text size="sm" variant="light">
            Once the scores for each criteria and sub-criteria have been reviewed, mark the submission as reviewed.
          </Text>
        </div>
        {submissionBreakdown && !submissionBreakdown.status && (
          <Button onClick={handleMarkAsReviewed} size="sm" variant="secondary" isLoading={isSaving}>
            Mark as reviewed
          </Button>
        )}
        {submissionBreakdown?.status === 'reviewed' && (
          <div className="flex items-center">
            <div className="flex items-center bg-white rounded p-2 mr-6">
              <Icon className="mr-2" icon="check-circle" variant="success" />
              <Text className="whitespace-nowrap mr-2">Marked as reviewed</Text>
              {!isSaving && evaluationEnvelope?.status === 'moderate' && (
                <Button onClick={handleEdit} state="text" variant="secondary">
                  <Icon icon="edit" />
                </Button>
              )}
              {isSaving && <Spinner colour={COLOUR.green[400]} />}
            </div>
            <Button
              href={routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
                evaluationEventId,
                evaluationEnvelopeId,
              })}
              state="text"
              variant="secondary"
              isLink
            >
              Back to list
            </Button>
          </div>
        )}
      </Banner>

      <Card>
        <CardHeader>
          <div>
            <Text variant="heading" size="h7" className="mb-1">
              Evaluation results
            </Text>
            <Text variant="light" size="sm">
              As a group, review the scores for each question within each criteria. You have the option to adjust an evaluator&apos;s score.
            </Text>
          </div>
          {isWeightedEnvelope ? (
            <div className="whitespace-nowrap text-right ml-4">
              <Text size="sm" variant="light">
                Weighted total
              </Text>
              <Text className="flex items-center justify-end">
                {submissionBreakdown?.moderatedScoreSummary
                  ? utilService.formatAsPercentage(submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore * 100)
                  : '--'}
                {renderWeightedTotalDelta(submissionBreakdown)}
              </Text>
            </div>
          ) : null}
        </CardHeader>
        {parentEvaluationCriteria ? <SubmissionResultsContent parentEvaluationCriteria={parentEvaluationCriteria} /> : null}
      </Card>
    </PageContent>
  )
})

const SubmissionResultsContent = ({ parentEvaluationCriteria }: { parentEvaluationCriteria: GqlEvaluationCriteriaFieldsFragment[] }) => {
  const [state, dispatch] = useModerationCriteriaExpansion(parentEvaluationCriteria)

  const hasCriteria = Boolean(parentEvaluationCriteria.length)

  const { parentCriteriaWeightPercentageById } = useMemo(() => {
    const weightById = evaluationEventService.getWeightById({ items: parentEvaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const parentCriteriaWeightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    return { parentCriteriaWeightPercentageById }
  }, [parentEvaluationCriteria])

  const { step, transition, onTransition } = useTransition({
    initialStep: hasCriteria ? 1 : 2,
  })

  const handleTabChange = ({ id }: TabModel<EvaluationEventModerateEnvelopeSubmissionStepTab>) => {
    const newStep = findIndex(EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_STEP_TABS, (tab) => tab.id === id) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }
  const activeTab = EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_STEP_TABS[step - 1].id

  const handleToggleExpand = (parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => {
    dispatch({
      type: 'TOGGLE_CRITERIA_EXPANSION',
      payload: parentEvaluationCriteriaItem.id,
    })
  }

  const handleSelect = ({ parentEvaluationCriteriaItem, activeEvaluationCriteriaItem }: HandleSelectParam) => {
    dispatch({
      type: 'SET_CRITERIA_ITEMS',
      payload: { parent: parentEvaluationCriteriaItem, active: activeEvaluationCriteriaItem },
    })
  }

  return (
    <>
      {hasCriteria ? (
        <div className="mb-4 border-b border-gray-200">
          <Tabs<EvaluationEventModerateEnvelopeSubmissionStepTab>
            tab={activeTab}
            tabs={EVALUATION_EVENT_MODERATION_ENVELOPE_SUBMISSION_STEP_TABS}
            onChange={handleTabChange}
            variant="underline"
            className="text-gray-700"
          />
        </div>
      ) : null}

      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <div className="flex justify-between py-4">
              <div className="shrink-0 border-r border-gray-200 w-1/3 pr-4">
                {map(parentEvaluationCriteria, (parentEvaluationCriteriaItem) => (
                  <EvaluationEventModerationParentCriteriaItem
                    key={parentEvaluationCriteriaItem.id}
                    className="mb-3"
                    parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                    activeEvaluationCriteriaItem={state.activeEvaluationCriteriaItem}
                    parentCriteriaWeightPercentageById={parentCriteriaWeightPercentageById}
                    onSelect={handleSelect}
                    onToggleExpand={handleToggleExpand}
                    isExpanded={state.expandedMap[parentEvaluationCriteriaItem.id]}
                  />
                ))}
              </div>
              <div className="w-full pl-4">
                <AnimatePresence mode="wait">
                  {!state.parentEvaluationCriteriaItem || !state.activeEvaluationCriteriaItem ? (
                    <Text>Please select a criteria to moderate.</Text>
                  ) : (
                    <div className="min-h-[430px]">
                      <EvaluationEventModerationCriteriaReview
                        parentEvaluationCriteriaItem={state.parentEvaluationCriteriaItem}
                        activeEvaluationCriteriaItem={state.activeEvaluationCriteriaItem}
                      />
                    </div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="py-4 min-h-[430px]">
              <EvaluationEventModerationEnvelopeCommentaryList hasCriteria={hasCriteria} />
            </div>
          )}
        </TransitionContainer>
      </AnimatePresence>
    </>
  )
}
