import '@ungap/with-resolvers'
import * as Sentry from '@sentry/react'
// This polyfill is required by the latest version of PdfJs for supporting legacy browsers
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import { CalloutProvider } from '@cotiss/common/containers/callout/callout.provider'
import { ToastProvider } from '@cotiss/common/containers/toast/toast.provider'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { AppProvider } from '@cotiss/app/app.provider'
import { AuthProvider } from '@cotiss/auth/auth.provider'
import { App } from './app'

const jsx = (
  <Sentry.ErrorBoundary>
    <BrowserRouter>
      <ApolloProvider client={apolloService}>
        <QueryClientProvider client={new QueryClient()}>
          <AppProvider>
            <ToastProvider>
              <AuthProvider>
                <TooltipProvider>
                  <CalloutProvider>
                    <App />
                  </CalloutProvider>
                </TooltipProvider>
              </AuthProvider>
            </ToastProvider>
          </AppProvider>
          {import.meta.env.VITE_IS_REACT_QUERY_DEV_TOOLS_ENABLED && <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />}
        </QueryClientProvider>
      </ApolloProvider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
)

createRoot(document.getElementById('cotiss-app') as HTMLElement).render(jsx)
