import { FormEvent, memo, useState } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationUserFieldsFragment } from '@gql'
import { some } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { userService } from '@cotiss/user/user.service'

type Props = {
  evaluationUser: GqlEvaluationUserFieldsFragment
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventPanelMemberRemoveConfirmModal = memo(({ evaluationUser, evaluationEnvelope }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const [isAcknowledged, setIsAcknowledged] = useState(false)
  const { mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId, mutateDeleteEvaluationUser } = useEvaluationUser()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      track('evaluation_event_panel_update_panel_delete_panel_submit')

      const isInOtherEnvelope = some(evaluationEnvelopes, ({ id: evaluationEnvelopeId }) => {
        return some(evaluationUser.accessControls, { resourceType: 'envelope', resourceId: evaluationEnvelopeId })
      })

      if (evaluationUser?.role === 'owner' || isInOtherEnvelope) {
        // 1. If the user is an owner OR the user is in any other envelope, only remove their access from this envelope.
        await mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId({
          evaluationUserId: evaluationUser.id,
          evaluationEnvelopeId: evaluationEnvelope.id,
        })
      } else {
        // 2. Otherwise, delete them.
        await mutateDeleteEvaluationUser({ evaluationUserId: evaluationUser.id })
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[600px] max-w-[600px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Remove panel member" />
      <ModalContent>
        <Banner icon="alert-circle" variant="danger">
          <div>
            <Text className="font-medium">Warning</Text>
            <Text className="mt-1" size="sm">
              By removing{' '}
              <Text className="font-semibold" size="sm" isInline>
                {userService.getFullName(evaluationUser.user)}
              </Text>
              , their evaluation data for this envelope will be deleted. This action cannot be undone.
            </Text>
          </div>
        </Banner>
        <label className="flex mt-6 cursor-pointer" htmlFor="evaluation-event-panel-member-remove">
          <Checkbox
            id="evaluation-event-panel-member-remove"
            className="mr-2 mt-1"
            isChecked={isAcknowledged}
            onChange={() => setIsAcknowledged(!isAcknowledged)}
          />

          <Text>I acknowledge that removing this panel member will delete their evaluation data from this envelope.</Text>
        </label>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving} isDisabled={!isAcknowledged}>
        Remove panel member
      </ModalConfirmationButton>
    </Form>
  )
})
