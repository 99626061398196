import { memo } from 'react'
import { EvaluationEventWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-weighted-title'

type Props = {
  order: number
  name: string
  relativeWeight: number
  shouldShowWeight?: boolean
}

export const EvaluationEventEnvelopeWeightedTitle = memo(({ order, name, relativeWeight, shouldShowWeight = true }: Props) => {
  return <EvaluationEventWeightedTitle title={`Envelope ${order}: ${name}`} shouldShowWeight={shouldShowWeight} relativeWeight={relativeWeight} />
})
