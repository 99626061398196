import { memo, useEffect, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { every, filter, find, map, some, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Switch } from '@cotiss/common/components/switch.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventParentTerminalCriteria } from '@cotiss/evaluation-event/components/evaluation-event-parent-terminal-criteria.component'
import { EvaluationEventSubCriteriaList } from '@cotiss/evaluation-event/components/evaluation-event-sub-criteria-list.component'
import { EvaluationEventSubCriteriaRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-sub-criteria-create-update.drawer'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { EvaluationEventEnvelopeWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weighted-title'
import { EvaluationEventWeightedTitle } from '@cotiss/evaluation-event/components/evaluation-event-weighted-title'
import { EvaluationEventRatingScaleSelector } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-selector.component'

import { EvaluationEventNoCriteriaDetails } from '@cotiss/evaluation-event/components/evaluation-event-no-criteria-details.component'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardCriteriaDetailsStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal, openDrawer } = useCallout()
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const [isSavingMap, setIsSavingMap] = useState<Record<string, boolean | undefined>>({})
  const { evaluationCriteria, mutateUpdateEvaluationCriteria, queryEvaluationCriteriaList } = useEvaluationCriteria()

  const isWeightedMethodology = evaluationEvent?.methodology === 'weightedAttribute'

  const { weightedPercentageById } = useMemo(() => {
    return evaluationEventService.getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria })
  }, [evaluationEnvelopes, evaluationCriteria])

  useEffect(() => {
    track('evaluation_event_wizard_sub_criteria_view')
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  const handleClickContinue = () => {
    if (
      !every(evaluationEnvelopes, ({ id: evaluationEnvelopeId }) => {
        const parentCriteriaWithSubCriteria = filter(evaluationCriteria, {
          evaluationEnvelopeId,
          parentEvaluationCriteriaId: null,
          isScored: false,
        })

        return every(parentCriteriaWithSubCriteria, ({ id }) => some(evaluationCriteria, { parentEvaluationCriteriaId: id }))
      })
    ) {
      setErrorText('Every criteria that has sub-criteria enabled must have at least one sub-criteria.')
      return
    }

    onNext()
  }

  const handleMutateCriteria = async (evaluationCriteriaId: string, isScored: boolean) => {
    if (!evaluationEvent) {
      return
    }

    try {
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: true })

      track('evaluation_event_wizard_criteria_update_submit')
      await mutateUpdateEvaluationCriteria({
        evaluationCriteriaId,
        isScored,
        // Unset supplementary if it is not a leaf node
        supplementary: !isScored ? '' : undefined,
      })

      // We need to refresh the evaluation event as updating the envelopes may update the steps of the wizard (E.g. if there is sub criteria.)
      // If the parent criteria has been updated from having sub-criteria (not isScored) to not having sub-criteria (isScored), any sub-criteria that
      // have been created for this criteria will be deleted. Then we need to refresh the evaluation criteria to remove any sub-criteria that have
      // been created against this criteria.
      await Promise.all([
        queryEvaluationEventView({ evaluationEventId: evaluationEvent.id }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } }),
      ])
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: false })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: false })
    }
  }

  const handleParentCriteriaToggle = (parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => {
    if (!parentEvaluationCriteriaItem.isScored && some(evaluationCriteria, { parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id })) {
      openModal(
        <ConfirmModal
          heading="Are you sure you want to switch off sub-criteria?"
          description="Confirming this will delete any existing sub-criteria that have already been created under this criteria."
          onSubmit={() => handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)}
        />
      )
    } else if (parentEvaluationCriteriaItem.isScored && parentEvaluationCriteriaItem.supplementary) {
      openModal(
        <ConfirmModal
          heading="Are you sure you want to switch on sub-criteria?"
          description="Confirming this will delete any evaluation requirements that have already been created for this criteria."
          onSubmit={() => handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)}
        />
      )
    } else {
      handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)
    }
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Criteria details
        </Text>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      {map(evaluationEnvelopes, (evaluationEnvelope) => {
        const parentCriteria = sortBy(
          filter(evaluationCriteria, { evaluationEnvelopeId: evaluationEnvelope.id, parentEvaluationCriteriaId: null }),
          'index'
        )

        const hasCriteria = Boolean(parentCriteria.length)

        const isWeightedEnvelope = find(evaluationEnvelopes, { id: evaluationEnvelope.id })?.weight !== 0

        return (
          <div className="mt-9 first:mt-0" key={evaluationEnvelope.id}>
            <div className="grid grid-cols-12 gap-x-6 justify-between items-center">
              <div className="col-span-6 xl:col-span-7">
                <Text size="lg" className="font-medium">
                  <EvaluationEventEnvelopeWeightedTitle
                    relativeWeight={weightedPercentageById[evaluationEnvelope.id]}
                    order={evaluationEnvelope.order}
                    name={evaluationEnvelope.name}
                    shouldShowWeight={isWeightedMethodology}
                  />
                </Text>
              </div>
              <div className="col-span-6 xl:col-span-5 flex items-center justify-end space-x-6 h-9">
                {hasCriteria && isWeightedEnvelope ? (
                  <EvaluationEventRatingScaleSelector evaluationEnvelope={evaluationEnvelope} hasCriteria={hasCriteria} />
                ) : null}
              </div>
            </div>
            <Hr className="my-3" />
            <div className="flex flex-col gap-y-8 pt-3">
              {!parentCriteria.length ? (
                <EvaluationEventNoCriteriaDetails evaluationEnvelope={evaluationEnvelope} isEditable />
              ) : (
                map(parentCriteria, (parentEvaluationCriteriaItem) => {
                  return (
                    <div key={parentEvaluationCriteriaItem.id}>
                      <TableHeader className="flex items-center justify-between w-full">
                        <div className="p-1">
                          <Text size="sm" variant="light" className="mb-1">
                            Criteria {parentEvaluationCriteriaItem.index}
                          </Text>
                          <Text size="md" className="font-medium">
                            <EvaluationEventWeightedTitle
                              relativeWeight={weightedPercentageById[parentEvaluationCriteriaItem.id]}
                              title={parentEvaluationCriteriaItem.content}
                              shouldShowWeight={isWeightedMethodology}
                            />
                          </Text>
                        </div>
                        <div className="flex items-center">
                          <Switch
                            className="mr-2"
                            variant="secondary"
                            size="sm"
                            isOn={!parentEvaluationCriteriaItem.isScored}
                            onClick={() => handleParentCriteriaToggle(parentEvaluationCriteriaItem)}
                            isDisabled={isSavingMap[parentEvaluationCriteriaItem.id]}
                          />
                          <Text className="mr-6">This criteria has sub-criteria</Text>
                          <Button
                            size="xs"
                            state="translucent"
                            variant="secondary-dark"
                            onClick={() =>
                              openDrawer(
                                <EvaluationEventSubCriteriaRatingScaleDrawer
                                  evaluationEnvelope={evaluationEnvelope}
                                  parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                                  isEditable
                                />
                              )
                            }
                            isDisabled={isSavingMap[parentEvaluationCriteriaItem.id] || parentEvaluationCriteriaItem.isScored}
                          >
                            + Add sub-criteria
                          </Button>
                        </div>
                      </TableHeader>
                      {parentEvaluationCriteriaItem.isScored ? (
                        <EvaluationEventParentTerminalCriteria
                          evaluationEnvelope={evaluationEnvelope}
                          evaluationCriteriaItem={parentEvaluationCriteriaItem}
                          isEditable
                        />
                      ) : (
                        <EvaluationEventSubCriteriaList
                          evaluationEnvelope={evaluationEnvelope}
                          parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                          isEditable
                        />
                      )}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        )
      })}
    </Card>
  )
})
