import { envService } from '@cotiss/common/services/env.service'

export const ADORA_USER_TYPES = {
  BUYER: 'buyer',
  SUPPLIER: 'supplier',
}

class AdoraService {
  init = () => {
    if (envService.isProduction()) {
      const head = document.querySelector('head')
      const script = document.createElement('script')

      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', 'https://adora-cdn.com/adora-start.js')
      script.onload = () => {
        if (window.adoraStart) {
          window.adoraStart({
            orgId: import.meta.env.VITE_ADORA_ORG_ID,
          })
        }
      }

      if (!head) {
        return
      }

      head.appendChild(script)
    }
  }

  identify = (userId: string, userCohorts?: Record<string, string>) => {
    if (!window.adoraStart) {
      return
    }

    window.adoraStart({
      orgId: import.meta.env.VITE_ADORA_ORG_ID,
      uid: userId,
      userCohorts,
    })
  }
}

// Add TypeScript interface for the window object
declare global {
  interface Window {
    adoraStart?: (config: { orgId?: string; uid?: string; userCohorts?: Record<string, string>; bypassSampling?: boolean }) => void
  }
}

export const adoraService = new AdoraService()
