import { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { AnimatePresence } from 'framer-motion'
import { filter, findIndex, includes } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { EvaluationEventCriteriaScoreComparisonList } from '@cotiss/evaluation-event/components/evaluation-event-criteria-score-comparison-list.component'
import { EvaluationEventRatingScaleDefinitionList } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-definition-list.component'
import { EvaluationEventRatingScaleValueField } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-value-field.component'
import {
  EVALUATION_EVENT_RATING_SCALE_NAME_MAP,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
} from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationScore } from '@cotiss/evaluation-event/hooks/use-evaluation-score.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { EvaluationEventEvaluationRequirements } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-requirements'

type Tab = 'evaluate' | 'scale-definitions' | 'compare'

type Props = {
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  parentEvaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment | null
}

export const EvaluationEventEvaluateDrawerPanel = memo(({ evaluationCriteriaItem, parentEvaluationCriteriaItem }: Props) => {
  const { openToast } = useToast()
  const [comment, setComment] = useState<string | undefined>(undefined)
  const [scoreValue, setScoreValue] = useState<number | undefined>(undefined)
  const { evaluationEvent } = useEvaluationEvent()
  const { step, transition, onTransition } = useTransition()
  const { evaluation, queryEvaluationOverviewView, mutateUpdateEvaluation } = useEvaluation()
  const { queryEvaluationScoreList, mutateUpsertEvaluationScore } = useEvaluationScore()
  const isDisabled = !evaluation || evaluation.status === 'complete' || evaluation.status === 'abstained'

  const { evaluationEnvelope } = useEvaluationEnvelope()
  const isWeightedEnvelope = evaluationEnvelope?.weight !== 0

  const nodeLabel = parentEvaluationCriteriaItem ? 'sub-criteria' : 'criteria'

  const { tabs, isRatingScaleVisible } = useMemo(() => {
    const isRatingScaleVisible = isWeightedEnvelope && includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, evaluationCriteriaItem.ratingScale)

    const tabs: TabModel<Tab & { isHidden?: boolean }>[] = [
      {
        id: 'evaluate',
        label: 'Evaluate',
      },
      {
        id: 'scale-definitions',
        label: 'Rating scale definitions',
        isHidden: !isRatingScaleVisible,
      },
      {
        id: 'compare',
        label: 'Compare',
      },
    ]

    return { tabs: filter(tabs, (tab) => !tab.isHidden), isRatingScaleVisible }
  }, [])

  const tab = useMemo(() => tabs[step - 1].id, [step, tabs])

  useAsyncEffect(async () => {
    if (!evaluationEvent || !evaluation) {
      return
    }
    const { evaluationScores } = await queryEvaluationScoreList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        evaluationId: evaluation.id,
        evaluationCriteriaId: evaluationCriteriaItem.id,
      },
    })
    setScoreValue(evaluationScores[0]?.value ?? undefined)
    setComment(evaluationScores[0]?.comment ?? undefined)
  }, [])

  const handleTabChange = (tab: Tab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const handleScoreSubmit = async (newValues: { value?: number; comment?: string }) => {
    try {
      if (!evaluation || !evaluationEvent) {
        return
      }

      const variables = { comment, value: scoreValue, ...newValues }

      await mutateUpsertEvaluationScore({
        evaluationCriteriaId: evaluationCriteriaItem.id,
        evaluationId: evaluation.id,
        ...variables,
      })

      await Promise.all([
        queryEvaluationOverviewView({ evaluationId: evaluation.id }),
        evaluation.status !== 'inProgress' && mutateUpdateEvaluation({ evaluationId: evaluation.id, status: 'inProgress' }),
      ])
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <>
      <div className="bg-primary-50 rounded-xl px-4 py-4 flex flex-col gap-4">
        <div>
          {parentEvaluationCriteriaItem && (
            <Text className="mt-1" variant="light" size="md">
              {parentEvaluationCriteriaItem.index}. {parentEvaluationCriteriaItem.content}
            </Text>
          )}
          <Text className="mr-4 font-medium" size="h4">
            {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}.` : ''}
            {evaluationCriteriaItem.index}. {evaluationCriteriaItem.content}
          </Text>
        </div>
        {isWeightedEnvelope ? (
          <>
            <Hr />
            <div>
              <Text className="font-medium">Rating scale</Text>
              <div className="flex items-center">
                <Text className="font-medium" variant="light">
                  {EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationCriteriaItem.ratingScale]}
                </Text>
                {isRatingScaleVisible && (
                  <Button className="ml-2" onClick={() => handleTabChange('scale-definitions')} state="text" variant="link">
                    View definitions
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : null}
        {evaluationCriteriaItem.supplementary && (
          <EvaluationEventEvaluationRequirements evaluationRequirements={evaluationCriteriaItem.supplementary} />
        )}
      </div>
      <Tabs<Tab>
        className="border-b border-gray-300 w-full mt-4"
        tab={tab}
        tabs={tabs}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={tab} transition={transition}>
          <div className="py-7">
            {tab === 'evaluate' && (
              <div className="grid grid-cols-3 gap-6 py-1">
                {isWeightedEnvelope ? (
                  <>
                    <div className="col-span-1">
                      <Label>Score</Label>
                      <Text size="sm" variant="light">
                        Provide a score to evaluate this {nodeLabel}
                      </Text>
                    </div>
                    <div className="col-span-2">
                      <EvaluationEventRatingScaleValueField
                        ratingScale={evaluationCriteriaItem.ratingScale}
                        value={scoreValue?.toString()}
                        onSubmit={(value) => {
                          setScoreValue(Number(value))
                          handleScoreSubmit({ value: Number(value) })
                        }}
                        isDisabled={isDisabled}
                      />
                    </div>
                  </>
                ) : null}
                <div className="col-span-1">
                  <Label>Comment</Label>
                  <Text size="sm" variant="light">
                    Provide commentary against this {nodeLabel}
                  </Text>
                </div>
                <div className="col-span-2">
                  <TextArea
                    className="w-full"
                    value={comment || ''}
                    onChange={({ target }) => setComment(target.value)}
                    onBlur={() => comment !== undefined && handleScoreSubmit({ comment })}
                    rows={6}
                    placeholder="Type comment"
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            )}
            {tab === 'scale-definitions' && <EvaluationEventRatingScaleDefinitionList ratingScale={evaluationCriteriaItem.ratingScale} />}
            {tab === 'compare' && evaluation && (
              <EvaluationEventCriteriaScoreComparisonList evaluation={evaluation} evaluationCriteriaItem={evaluationCriteriaItem} />
            )}
          </div>
        </TransitionContainer>
      </AnimatePresence>
    </>
  )
})
